import axios from 'axios';
import qs from 'qs';
import store from 'store';

const API_URL_AUTH = false ? 'http://localhost:5000/login' : 'https://auth.geowellex.com/auth/realms/Geowellex/protocol/openid-connect/token';

export const loginAuth = async (data) => {
  const options = {
    method: 'post',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify({
      ...data,
      grant_type: 'password',
      client_id: 'gold',
    }),
    url: API_URL_AUTH,
    withCredentials: false,
  };
  const response = await axios(options);
  axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
  window.token = response.data.token;

  return response;
};

export const getUserInfo = async () => {
  const options = {
    method: 'POST',
    headers: { 
      'Authorization': `Bearer ${store.get('token')}` 
    },
    url: 'https://auth.geowellex.com/auth/realms/Geowellex/protocol/openid-connect/userinfo',
    withCredentials: false,
  };
  delete axios.defaults.headers['access-token'];
  const response = await axios(options);
  return response;
};
