/**
 * @param {@number} lastDepth - the length of the wellsite
 * @param {@number} depthValues - array with all depth values
 * @param {@array}  mappedLithology - array of strings with all abbreviations
 * @param {*} csvData - complete names tha matches each abbreviation
 * @returns 
 */
export function generateLithologyFromTopAndBottom(
  lastDepth,
  depthValues,
  mappedLithology,
  csvData,
) {
  // generate empty values synced with depth
  const lithoData = mappedLithology.reduce((acc, lithoKey) => {
    if (lithoKey) {
      acc[lithoKey] = new Array(lastDepth).fill(0);
    }
    return acc;
  }, {});

  depthValues.reduce((acc, depth, index) => {
    const csvRow = csvData && csvData[index + 1] ? csvData[index + 1] : [0, 0, '']; // prevent to read header of csv
    if (csvRow) {
      const [bottom, top, rockAbbreviation] = csvRow;
      const bottomFloat = parseFloat(bottom);
      const topFloat = parseFloat(top);

      if (
        depth => bottomFloat
          && depth <= topFloat
          && Object.keys(lithoData).includes(rockAbbreviation)
          && !!rockAbbreviation
          && typeof rockAbbreviation !== 'undefined'
          && rockAbbreviation !== undefined
          && lithoData[rockAbbreviation]
          && lithoData[rockAbbreviation][index]
      ) {
        if (Array.isArray(rockAbbreviation)) {
          lithoData[rockAbbreviation[0]][index] = 100;
        } else if (lithoData && rockAbbreviation && lithoData[rockAbbreviation]) {
          lithoData[rockAbbreviation][index] = 100;
        }
      }
    }
    return acc;
  }, { depth: [] });

  console.log('lithoData ==>', lithoData);
  return lithoData;
}

export const getCuttingsLithology = (well) => {
  let lithoData = Object.keys(well)
    .filter((key) => key.includes('litho_'))
    .reduce((cur, key) => {
      const rockKeyValues = well[key].map(i => i < 0 ? 0 : i);
      return { ...cur, [key.replaceAll('litho_', 'rock_')]: rockKeyValues }
    }, {})

  return lithoData;
}