import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import {
    Button,
} from 'antd';
import classes from 'classnames';
import Modal from 'react-modal';

import removeIcon from '../../../assets/close.svg';
import { modalStyleEditLithology } from 'utils/index';
import { SelectStyled } from '../../../Models/Train/Experiment/styles';
import  useDisplayCurves  from '../hooks/useDisplayCurves';

import { useDragger } from '../hooks/useDragger';


const Title = ({ title, className, classTitle }) => (
    <h3 className={classes(
        'title-row',
        {
            [className]: !!className,
            [classTitle]: !!classTitle
        }
    )}
    >
        {title}
    </h3>
);

Title.defaultProps = {
    classTitle: 'modal-scale__title'
};

const CurveChangeModal = ({
    isOpen, onCloseModal, currentWell, loading, id, containerRef, filterBy, title
}) => {
    const { currentCurve, curves, selectCurve } = useDisplayCurves(filterBy);
    const [selectedCurve, setSelectedCurve] = useState(null);

    useEffect(() => {
        if(currentCurve) { 
            setSelectedCurve(currentCurve);
        };
    },[currentCurve, filterBy])
  
    useDragger('drag-modal');

    return (
        <>
            <div
                id={'drag-modal'}
                style={{
                    position: 'absolute',
                    padding: '0',
                    background: 'rgb(29, 28, 28)',
                    color: '#fff',
                    border: 'none',
                    width: '300px',
                    height: '200px',
                    borderRadius: 10,
                    top:0,
                    left: '50%',
                    zIndex: 100,
                    display: isOpen ? 'block' : 'none',
                    cursor:'move',
                    boxShadow: '0 0 10px rgba(0,0,0,0.5)',
                }}
                onRequestClose={() => {
                    onCloseModal();
                }}
            >
                <div style={{ padding: 20, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Title title={title} />
                    <img
                        onClick={onCloseModal}
                        src={removeIcon}
                        className="modal-scale__close"
                        data-cy="close-modal"
                        alt="close modal"
                    />
                </div>
                <div style={{ padding: 20 }}>
                    <SelectStyled
                        options={curves}
                        onChange={selectCurve}
                        value={selectedCurve}
                    >
                        {curves?.length > 0 && Array.isArray(curves) ? 
                            curves && curves?.map((item) => (<Option key={item.type} value={item.type}>{item.mnemonic}</Option>)) : 
                            curves && Object.keys(curves)?.map((item) => (<Option key={curves[item].type} value={curves[item].mnemonic}>{curves[item].mnemonic}</Option>))
                        }
                    </SelectStyled> 
                </div>
            </div>
        </>
    );
};

export default CurveChangeModal;
