import axios from 'axios';
import store from 'store';

const BASE_URL = 'https://mlaasv2-oofp55ubjq-uc.a.run.app'
const MLAAS_API_URL = `${BASE_URL}/mlaas/v1`;




export function filterMetadataByCurve( project_uid, log_set, mnemonic) {
    const token = store.get('token')
    return axios.get(
        `${MLAAS_API_URL}/data/${project_uid}/${log_set}/${mnemonic}`,
{
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    );
}

export const filterCommonMnemonics = async (data, token) => {
    const resource = `${MLAAS_API_URL}/data/common-mnemonics`;
    const response = await axios.post(
        resource,
        data,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-type': 'application/json'
            }
        }
    );
    return response;
}

export const sendBuildDataset = async (data, token) => {
    const resource = `${MLAAS_API_URL}/data/datasets`;
    const response = await axios.post(
        resource,
        data,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-type': 'application/json'
            }
        }
    );
    return response;
}

export const trainModel = async (data, token) => {
    const resource = `${MLAAS_API_URL}/models`;
    const response = await axios.post(
        resource,
        data,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-type': 'application/json'
            }
        }
    );
    return response;
};