import styled, { css } from 'styled-components'
import { Input } from 'antd';

const { TextArea } = Input;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .output_curve_input {
      .ant-input{
      background-color: #333;
      cursor: text;
      color: #fff
    }
  }
  
  div {
    display: flex;
    width: 100%;
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
`
export const StyledLabel = styled.label`
  margin-left: 5px;
  color: #B4B1C2;
`
export const StyledInput = styled(Input)`
    border: none;
    &.ant-input{
    background-color: #211F2B;
    cursor: text;
    color: #fff
  }
`

export const StyledTextArea = styled(TextArea)`
  border: none;
  &.ant-input{
  background-color: #211F2B;
  cursor: text;
  color: #fff
}
`

