import React, { useState } from 'react'
// import { Icon } from 'antd'
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Text, Collapse, Button } from "../../components"

import { ItemContainer, HeaderContainer } from './Styles'

import AddModelComponent from "./addModel"
import usePredictedExperiments from "../../hooks/usePredictedExperiments"
import { successNotification, errorNotification } from '../../utils'
import ExperimentCard from './ExperimentCard'
import { addModel } from '../../api/models'

import MachineLearningMetrics from "../../components/ModalMachineLearning/MachineLearningMetrics"
import { object } from 'yup'


const ExperimentItem = ({ name }) => {
  const [isOpenCollapse, setOpenCollapse] = useState(false)
  const [experimentData, setExperimentData] = useState([])
  const { experimentIsLoading, getExperiment } = usePredictedExperiments()
  const [currRunId, setCurrRunId] = useState('')
  const [data, setData] = useState()
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [visible, setIsVisible] = useState(false)

  const handleChange = (field, value) => {
    setData({ ...data, [field]: value })
  }

  const onAddModel = async (cb) => {
    const { modelDescription, modelType, title, id } = data

    try {
      const response = await addModel({
        run_id: currRunId,
        registry_name: title,
        path: 'model',
        description: modelDescription,
        curve_type: modelType
      })

      successNotification(response.data.message || 'Success')
    } catch (err) {
      if (err instanceof Error) {
        errorNotification(err.message)
        return
      }

      errorNotification('Error to send request')
    } finally {
      cb()
    }
  }

  const handleOpenCollapse = async () => {
    if (experimentIsLoading) return null

    setOpenCollapse(!isOpenCollapse)

    if (!experimentData.length) {
      const response = await getExperiment(name)
      setExperimentData(response)
    }
  }

  const handleAddModelClick = (runId) => {
    setModalIsOpen(true)
    setCurrRunId(runId)
  }

  const renderContent = () => {
    if (experimentIsLoading) return <p>Loading experiment data...</p>

    if (!experimentData || !experimentData.length) return <p>No data to show.</p>

    return (<>
      <Button mt="1rem" mb="0.2rem" onClick={() => setIsVisible(!visible)} >See Metrics Comparison Chart</Button>
      {visible ? <MachineLearningMetrics experimentData={experimentData}></MachineLearningMetrics> : null}
      {experimentData.map((props) => (
        <ExperimentCard {...props} onAddModelClick={handleAddModelClick} />
      ))}
    </>)
  }

  return (
    <ItemContainer py="1rem">
      <HeaderContainer display="flex" justifyContent="space-between" aria-busy={experimentIsLoading} onClick={handleOpenCollapse}>
        <Text variant="subtitle">{name}</Text>
        {isOpenCollapse
          ? <UpOutlined />
          : <DownOutlined /> 
        }
      </HeaderContainer>
      {modalIsOpen && (
        <AddModelComponent
          data={data}
          onClose={() => setModalIsOpen(false)}
          onSubmit={onAddModel}
          onChange={handleChange}
        />
      )}
      <Collapse isOpen={isOpenCollapse}>
        {renderContent()}
      </Collapse>
    </ItemContainer>
  )
}

export default ExperimentItem
