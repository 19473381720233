import store from 'store';
import { disableDots } from 'utils';
import { DEFAULT_SCALES } from 'utils/constants';


export const createDefaultConfig = (
  scales,
  displayZoomBtn,
  propagateZoom,
  getToolTipValues,
  getDefaultSeries,
  defaultCrossHair,
  aiGammaRay,
  gammaRay,
  depth
) => ({
  chart: {
    inverted: true,
    width: 160,
    marginTop: 0,
    margin: 0,
    zoomType: 'x',
    events: {
      selection: event => {
        displayZoomBtn();
        propagateZoom(event, depth);
      }
    },
    animation: false,
    shadow: false
  },
  credits: {
    enabled: false
  },
  title: {
    text: ''
  },
  subtitle: {
    text: ''
  },
  xAxis: {
    allowDecimals: false,
    labels: {
      enabled: false
    },
    tickPosition: 'inside',
    tickColor: '#fff',
    gridLineColor: '#a4a4a4',
    gridLineWidth: 1,
    minorTickInterval: 1,
    zIndex: 800
  },
  yAxis: [
    {
      title: {
        text: ''
      },
      padding: 0,
      margin: 0,
      minorGridLineWidth: 1,
      minorGridLineColor: '#E0E0E0',
      tickStart: 0,
      type: 'linear',
      minorTickInterval: 10,
      tickInterval: 10,
      min: scales && scales.gammaRay ? scales.gammaRay.min : 0.1,
      max: scales && scales.gammaRay ? scales.gammaRay.max : 100,
      color: 'rgba(0, 0, 0, 0)',
      index: 0,
      gridLineWidth: 1,
      zIndex: 1,
      step: true,
      allowDecimals: true,
      labels: {
        enabled: false
      }
    },
    {
      type: 'linear',
      minorTickInterval: 10,
      tickInterval: 10,
      title: {
        text: ''
      },
      min: scales && scales.gammaRay ? scales.gammaRay.min : 0.1,
      max: scales && scales.gammaRay ? scales.gammaRay.max : 100,
      color: scales && scales.gammaRay ? scales.gammaRay.color : DEFAULT_SCALES.gammaRay.color,
      gridLineColor: 'rgba(0, 0, 0, 0)',
      index: 1,
      allowDecimals: true,
      labels: {
        enabled: false
      },
      minorGridLineWidth: 1,
      minorGridLineColor: 'rgba(0, 0, 0, 0)',
      zIndex: 10,
      step: true
    },
    {
      title: {
        text: ''
      },
      type: 'linear',
      minorTickInterval: 10,
      tickInterval: 10,
      min: scales && scales?.aiGrPrediction ? scales.aiGrPrediction.min : 0.1,
      max: scales && scales?.aiGrPrediction ? scales.aiGrPrediction.max : 100,
      gridLineColor: 'rgba(0, 0, 0, 0)',
      color: scales && scales?.aiGrPrediction ? scales.aiGrPrediction.color : DEFAULT_SCALES?.aiGrPrediction?.color,
      index: 2,
      allowDecimals: true,
      labels: {
        enabled: false
      },
      gridLineWidth: 1,
      minorGridLineWidth: 1,
      minorGridLineColor: 'rgba(0, 0, 0, 0)',
      zIndex: 10,
      step: true
    }
  ],
  plotOptions: {
    series: {
      marker: {
        enabled: false
      },
      enableMouseTracking: false,
      showInLegend: false,
      hover: false
    }
  },
  exporting: { enabled: false },
  tooltip: {
    shared: true,
    ...defaultCrossHair,
    formatter: getToolTipValues('gammaRay')
  },
  series: getDefaultSeries(aiGammaRay, gammaRay)
});

export const filterData = (scales, data) => {
  return data.filter((value) => { return value !== null && (value < scales.max && value >= scales.min) })
}

export const getDefaultSeries = (grPredictionData, gammaRayData) => {
  const { aiGrPrediction, gammaRay } = store.get('configScales') ?? DEFAULT_SCALES;



  return [
    {
      type: 'spline',
      ...disableDots,
      name: 'grPlaceholder',
      data: gammaRayData,
      color: 'rgba(0, 0, 0, 0)',
      min: gammaRay.min,
      max: gammaRay.max,
      index: 0,
      yAxis: 0,
      allowDecimals: true
    },
    {
      type: 'spline',
      ...disableDots,
      name: 'gammaRay',
      data: gammaRayData,
      color: gammaRay?.color,
      lineColor: gammaRay?.color,
      lineWidth: 1.3,
      min: gammaRay?.min,
      max: gammaRay?.max,
      index: 1,
      yAxis: 1,
      allowDecimals: true,
      fillOpacity: 0,
    },
    {
      type: 'spline',
      ...disableDots,
      name: 'aiGrPrediction',
      data: grPredictionData,
      color: aiGrPrediction?.color,
      lineColor: aiGrPrediction?.color,
      lineWidth: 1.3,
      min: aiGrPrediction?.min,
      max: aiGrPrediction?.max,
      index: 2,
      yAxis: 2,
      allowDecimals: true
    },
  ];
};
