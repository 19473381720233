import { DEFAULT_SCALES } from "./constants";

export const getWellSiteScales = (currentWell) => {
    const rawKeys = (currentWell?.log_sets?.RAW?.data || []).reduce((acc, row) => {
        const type = row.type;
        if (!acc[type]) {
            acc[type] = {
                ...row.visualization_config,
                name: row.name,
                type: row.name,
                unit: row.unit,
                min: row.visualization_config?.left_scale,
                max: row.visualization_config?.right_scale,
            }
        };
        return acc;
    }, []);
    const calculatedKeys = (currentWell?.log_sets?.calculated?.data || []).reduce((acc, row) => {
        const type = row.type;
        if (!acc[type]) {
            acc[type] = {
                ...row.visualization_config,
                name: row.name,
                type: row.name,
                unit: row.unit,
                min: row.visualization_config?.left_scale,
                max: row.visualization_config?.right_scale,
            }
        };
        return acc;
    }, []);

    return { ...rawKeys, ...calculatedKeys };
};
