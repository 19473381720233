import axios from 'axios';
import map from 'lodash.map';
import { getGateway } from './gateway';

const GATEWAY = getGateway();
const LITHOLOGY_API = `${GATEWAY}/lithologies/`;
export const BASE_URL = GATEWAY;

export function postLithology(token, data) {
  const resource = `${LITHOLOGY_API}`;
  return axios.post(
    resource,
     data ,
    {
      headers: {
        'access-token': token,
        'Content-type': 'application/json'
      }
    }
  );
}

export function getLithologyByUid(token, uid) {
  const resource = `${LITHOLOGY_API}/${uid}`;
  return axios.get(
    resource,
      {
        headers: {
          'access-token': token,
          'Content-type': 'application/json'
        }
      }
  );
}

export function updateLithologyByUid(token, data, uid) {
  const resource = `${LITHOLOGY_API}/${uid}`;
  return axios.put(
    resource,
    { data },
    {
      headers: {
        'access-token': token,
        'Content-type': 'application/json'
      }
    }
  );
}

export function deleteLithologyByUid(token, uid) {
  const resource = `${LITHOLOGY_API}/${uid}`;
  return axios.delete(
    resource,
      {
        headers: {
          'access-token': token,
          'Content-type': 'application/json'
        }
      }
  );
}

export function getLithologyByWellUid(token, welluid) {
  const resource = `${LITHOLOGY_API}/well/${welluid}`;
  return axios.get(
    resource,
    {
      headers: {
        'access-token': token,
        'Content-type': 'application/json'
      }
    }
  );
}

export function updateLithologyByWellUid(token, data, welluid) {
  const resource = `${LITHOLOGY_API}/well/${welluid}`;
  return axios.put(
    resource,
    { data },
    {
      headers: {
        'access-token': token,
        'Content-type': 'application/json'
      }
    }
  );
}

export function deleteLithologyByWellUid(token, welluid) {
  const resource = `${LITHOLOGY_API}/well/${welluid}`;
  return axios.delete(
    resource,
      {
        headers: {
          'access-token': token,
          'Content-type': 'application/json'
        }
      }
  );
}