import React, { useEffect, useState, createContext, useContext } from 'react'
import store from 'store'
import axios from 'axios'

import useHydraulicsContext, { API_BASE_URL } from "../useHydraulics";

export const AggregatedDataContext = createContext()

export const AggregatedDataProvider = ({ children }) => {

    const accessToken = store.get('token');
    const { wellInfo, cuttings } = useHydraulicsContext();
    const [aggregatedSamples, setAggregatedSamples] = useState();


    const fetchHydraulicsCuttingsAggregatedData = async (payload) => {

        if (accessToken && accessToken != null) {
            const url = `${API_BASE_URL}/get?aggregate_into=10`


            const config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'access-token': accessToken,
                    'Access-Control-Allow-Origin': '*'
                },
                url: url,
                data: payload,

            };

            axios(config)
                .then(function (response) {
                    const data = response.data
                    //console.log("Agreggated Data =>", data)
                    setAggregatedSamples(data.aggregated_samples)

                })
                .catch(function (error) {
                    const errorMessage = error.response
                    console.error(errorMessage)
                })
        }


    };

    useEffect(() => {
        if (cuttings) {
            fetchHydraulicsCuttingsAggregatedData({
                uid: wellInfo.uid,
                uid_well: wellInfo.uidWell,
                uid_wellbore: wellInfo.uidWellbore
            })
        }
    }, [cuttings]);


    return (
        <AggregatedDataContext.Provider value={{
            aggregatedSamples,
            setAggregatedSamples,
            fetchHydraulicsCuttingsAggregatedData,

        }}>
            {children}
        </AggregatedDataContext.Provider>
    )
}

const useAggregatedDataContext = () => {
    const context = useContext(AggregatedDataContext)

    if (!context) {
        throw new Error('useAggregatedDataContext must be used within a AggregatedDataContextProvider')
    }

    return context
}

export default useAggregatedDataContext
