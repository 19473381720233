/* eslint-disable */
import React, { Component } from 'react';
import uniq from 'lodash.uniq';
import { RedoOutlined } from '@ant-design/icons';
import * as experimentActions from 'actions/experiments';
import * as modelsActions from 'actions/models';
import * as wellActions from 'actions/wells';
import * as zoneActions from 'actions/zone';
import {
  Button,
  Dropdown,
  Input,
  Menu,
  message,
  Icon,
  notification,
  Popconfirm,
  Select,
  Slider,
  Steps,
  Tabs
} from 'antd';
import { ThemeProvider, withTheme } from 'styled-components'
import { fetchInWellSite } from 'api';
import { postLithology } from '../api/lithology';
import {
  fetchInWell,
  getURLToDownloadLas,
  downloadLasFile
} from 'api/wells';
import folderIcon from 'assets/folder.svg';
import removeIcon from 'assets/trash-white.svg';
import axios from 'axios';
import classNames from 'classnames';
import BigCard from 'components/BigCard';
import Chromatography from 'components/Charts/Chromatography';
import Cuttings from 'components/Charts/Cuttings';
import GammaRay from 'components/Charts/GammaRay';
import MLPorosity from 'components/Charts/MLPorosity';;
import LithoScore from 'components/Charts/LithoScore';
import InterpretedLithology from 'components/Charts/InterpretedLithology';
import Mse from 'components/Charts/Mse';
import RealTimeLithology from 'components/Charts/RealTimeLithology';
import SelectedDepth from 'components/Charts/SelectedDepth';
import TotalDepth from 'components/Charts/TotalDepth';
import TotalGas from 'components/Charts/TotalGas';
import ConfigModal from 'components/ConfigModal';
import ConflictData from 'components/ConflictData';
import CreateWell from 'components/CreateWell';
import FullScreen from 'components/FullScreen';
import GeopressionsModal from 'components/GeopressionsModal';
import { DataSourceModal } from 'components/InWellModal/DataSourceModal';
import SelectModalInWell from 'components/InWellModal/SelectModalInWell';
import ExperimentsModal from 'components/ModalMachineLearning/Experiments';
import TrainModelModal from 'components/ModalMachineLearning/TrainModelModal';
import WellPhasesModal from 'components/WellPhasesModal';
import PlotBox from 'components/PlotBox';
import BitSizeClipboard from 'components/BitSizeClipboard';
import ProcessingSpinner from 'components/ProcessingSpinner';
import PlotBoxEthene from 'components/RelativeEthene/PlotBox';
import RopBigCard from 'components/RopBigCard';
import SelectMnemonic from 'components/SelectMnemonic';
import SelectReferenceCurve from 'components/SelectReferenceCurve';
import SidebarNavigation from 'components/SidebarNavigation';
import Spinner from 'components/Spinner';
import WobBigCard from 'components/WobBigCard';
import ZoomButton from 'components/ZoomButton';
import Highcharts from 'highcharts';
import { Parser } from 'json2csv';
import Overlay from 'components/Overlay';
import BitSizeForm from 'components/BitSize/BitSizeForm';
import calculateGeopressure from 'lib/geopressure';
import { generateLithologyFromTopAndBottom, getCuttingsLithology } from 'utils/lithology';
import {
  formatParsedArrayToWellObject,
  processingGeopressLas,
  processingInWell,
  processingLas,
} from 'lib/Well';
import clone from 'lodash.clone';
import filter from 'lodash.filter';
import get from 'lodash.get';
import keys from 'lodash.keys';
import map from 'lodash.map';
import Papa from 'papaparse';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import store from 'store';
import {
  applyZoom,
  arrayFlipToObject,
  avoidZero,
  calculateAPIC2C3,
  calculateAPIGravityC2C34,
  calculateGasComposition,
  calculateGORC2C3,
  calculateGORC2C34,
  calculateSteps,
  checkIfThereAreTopAndBottom,
  generateWellOptions,
  modalStyleCreateWell,
  polling,
  setOnIndexDB,
  successNotification,
  syncronizeDepthZoom,
  generateWellOptionsFromApi
} from 'utils';
import { getCurveData } from 'utils/getCurveData';
import {
  CREATE,
  defaultCurveKeys,
  defaultMnemonics,
  geopressureDefaultCurves,
  UPDATE,
  ABBREVS_ROCKS_NAMES,
  ABBREVS_ROCKS,
} from 'utils/constants';
import {
  aliasesToCsv,
  createRowsToCsv,
  crossPlotsKeysToSynchronizeWithDepth,
  csvToJSON,
  fieldsToCsv
} from 'utils/Csv';
import {
  calculateBalanceRatio,
  calculateC1Normalized,
  calculateCharacterRatio,
  calculateWetness,
  calculateBitSize
} from 'utils/GasesCalculations';
import GlobalCutOff from 'utils/GlobalCutOff';
import { v4 as uuid } from 'uuid';
import Hydraulics from '../new/Hydraulics';
import { generateBitSizeCurve } from 'utils/bitsize';

import { Importdata } from '@geowellex/shared-importdata';
import { parseLasFile, parseWellDataIntegration, parseAndSaveLithologyData, saveParsedData } from '../api/import-data';

import {
  DownloadOutlined,
  SettingOutlined
} from '@ant-design/icons';

import MachineLearningIcon from '../assets/machine-learning.png';
import HydraulicsIcon from '../assets/hydraulics_icon.png';
import { Box } from '../components';
import ListExperiments from '../components/ModalMachineLearning/ListExperiments';
import LithologyMatchCurves from '../components/LithologyMatchCurves';

import { fetchInWellWatcher } from '../api/wellwatcher';
import EditLithologyModal from '../components/EditLithologyModal';
import EditLithologyButton from '../components/EditLithologyModal/EditLithologyButton';
import { RealtimeSwitch } from '@geowellex/shared-hooks';
import { warningNotification } from 'utils';
import GeoCopilotDrawer from '../components/GeoCopilotDrawer';
import CurveChangeModal from '../components/Charts/CurveChangeModal';
import theme from '../theme'
import { rocks, GAS_COMP_C1, scales as DEFAULT_CONFIG_SCALES } from '../utils/constants';
import { receiveInWell } from '../actions/wells';
import LithologyErrorModal from '../components/ModalLithologyError';
import { errorNotification } from '../utils';
import GeneralSettings from '../components/GeneralSettings';


const { Option } = Select;
const { TabPane } = Tabs;

const initialData = {
  depth: [],
  BH: [],
  WH: [],
  totalGas: [],
  C1C2: [],
  c1: [],
  c2: [],
  c3: [],
  c4: [],
  ic4: [],
  nc4: [],
  ic5: [],
  nc5: [],
  c1Composition: [],
  c2Composition: [],
  c3Composition: [],
  nc4Composition: [],
  nc5Composition: [],
  isotopeData: [],
  wetnessRatioData: [],
  balanceRatioData: [],
  characterRatioData: [],
  c1Byc2: [],
  ic4BynC4: [],
  ic5BynC5: [],
  rop: [],
  slowFactor: [],
  wob: [],
  gammaRay: [],
  bitSize: [],
  geopressureBitSize: [],
};

const calculateTotalCarbon = (c1, c2, c3, c4, c5) => {
  return c1 + c2 + c3 + c4 + c5;
};

const checkIfExistsWell = (wells) => (wellName) => {
  return wells.find((well) => well.wellName === wellName)
};

const initialState = {
  selectedDepthMin: undefined,
  selectedDepthMax: undefined,
  // @TODO move it to the modal state
  rotating: null,
  options: null,
  wellKey: null,
  processing: false,
  modalIsOpen: false,
  etheneRadio: 0,
  isActivated: false,
  isActivaisActivatedPolling: false,
  isOpenTrainModelModal: false,
  isOpenExperiments: false,
  step: 1,
  errors: {
    top: null,
    bottom: null,
    wellName: null
  },
  defaultcurves: {
    depth: 'Depth',
    rop: 'ROP',
    gas_ratio: 'Gas Ratio',
    total_gas: 'Total Gas',
    c1: 'C1',
    c2: 'C2',
    c3: 'C3',
    c4: 'C4',
    c5: 'C5',
    character_ratio: 'Character Ratio',
    balance_ratio: 'Balance Ratio',
    wetness_ratio: 'Wetness Ratio',
    isotope: 'Isotope',
    chartType: '',
    flow: 'flow'
  },
  errorCurves: {
    depth: null,
    c1: null,
    c2: null,
    c3: null,
    c4: null,
    c5: null,
    ic4: null,
    ic5: null,
    c2h6: null,
    c2h4: null,
    c2s: null,
    flow: null
  },
  geopressureErrors: {
    ...geopressureDefaultCurves,
    density: null,
    waterDepth: null
  },
  top: 0,
  bottom: 0,
  // Plotbox
  chartType: null,
  // Upload well
  loadingWell: false,
  curves: [],
  current: 0,
  curvesKeys: defaultCurveKeys,
  geopressureCurveKeys: geopressureDefaultCurves,
  currentWell: {
    name: '',
    lasVersion: ''
  },
  activeOverburden: false,
  cuttingsLitho: null,
  lithologyType: '',
  // @TODO move it to the wells reducer
  data: initialData,
  max: 0,
  min: 0,
  selectedMax: 0,
  selectedMin: 0,
  unit: '',
  wellNames: [],
  selectedWell: -1,
  rangeTop: 0,
  rangeBottom: 0,
  visibleTooltip: false,
  showClass: 'fadeOut',
  visibleTooltip2: false,
  showClass2: 'fadeOut',
  hasConflictData: false,
  hadConflict: false,
  newWellName: '',
  hasNewWell: false,
  conflictingType: '',
  bitSize: [],
  lasFile: '',
  showImportLas: true,
  geopressIsOpen: false,
  openImportData: false,
  loadingListWell: true,
  withoutOverburden: false,
  modalInWellIsVisible: false,
  isInWellTableIsVisible: false,
  calculating: false,
  IsOpenModalMarchine: false,
  experimentName: null,
  loadingExperiment: true,
  metadata: null,
  isOpenConfigImport: false,
  hydraulics: false,
  geocopilot: false,
  isGeocopilotRunning: false,
  isModalVisibleWellPhases: false,
  IsOpenEditLithologyModal: false,
  IsOpenConfigCurveModal: false,
  wellDataIntegration: null,
  curveChangeFilterProp: 'lithology',
  curveChangeTitle: 'Change Lithology Prediction',
  isOpenLithologyError: false,
  isOpenCustomizeCharts: false,
};


class Home extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.standardDeviation = [];
    this.meanScoreData = [];
    this.dataWob = [];
    this.dataRop = [];
    this.mseData = [];
    this.depth = [];
    this.seriesData = [];
    this.lithoScoresToCsv = [];
    this.uploadRef = React.createRef();
    this.uploadLithoRef = React.createRef();
    this.uploadGeopressureRef = React.createRef();
    this.initLocalStorageData();
    this.sliderRef = React.createRef();
    this.chartContainerRef = React.createRef();
  }

  calculateDxc = ({
    depth,
    ecd,
    n,
    rop,
    wob,
    overburdenGradient,
    bs,
    density,
    option,
    waterDepth,
    normalPressureGradient,
    onSuccessDxc
  }) => {
    // TODO use it to integrate and calculate the gradient chart
    // const wasmShouldEvaluatePython = pythonCode => {
    //   languagePluginLoader.then(() => {
    //     pyodide
    //       .runPythonAsync(pythonCode)
    //       .then(() => {
    //         // console.log('pp Grad -->', pyodide.globals.ppGrad);
    //         // console.log('frac Grad -->', pyodide.globals.fracGrad);

    //         const dxcValues = pyodide.globals.dxc;
    //         onSuccessDxc(dxcValues);
    //       })
    //       .catch(error => console.log('DXC Error -->', error));
    //   });
    // };

    /*
       1 Inputs: bs, ecd, n, rop, wob, depth, gn, obg - (don't need waterDepth)
       2 Inputs: (bs, ecd, n, rop, wob, depth, gn, density) - needs constant (waterDepth)
       3 Inputs: bs, ecd, n, rop, wob, depth, gn - default LAPA data
    // */
    // const csvData = `{
    //   "depth": [${depth}],
    //   "ecd": [${ecd}],
    //   "n": [${n}],
    //   "rop": [${rop}],
    //   "wob": [${wob}],
    //   "obg": [${overburdenGradient}],
    //   "bs": [${bs}],
    // }`;

    // const defaultTypeToCalculateGeopressure = option;
    // const gn = normalPressureGradient;
    // const waterDepthValue = waterDepth || 0;
    // const pythonCodeToCompile = calculateGeopressure(
    //   csvData,
    //   gn,
    //   waterDepthValue,
    //   defaultTypeToCalculateGeopressure,
    //   0,
    //   0,
    //   0,
    //   0
    // );
    // wasmShouldEvaluatePython(pythonCodeToCompile);
  }

  afterSuccess = (wells) => {
    this.setState(() => ({
      ...generateWellOptionsFromApi(wells),
      wells,
      loadingListWell: false
    }));
  };

  async componentDidMount() {
    const token = store.get('token');
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    this.setState({
      loadingListWell: true,
    });

    const configScales = store.get('configScales');
    if (!configScales?.[GAS_COMP_C1]?.color) {
      store.set('configScales', DEFAULT_CONFIG_SCALES);
    }

    try {
      if (store.get('email') && store.get('token')) {
        await this.props.wellAction.listWells();
      }
    } catch (e) {
      console.log('error when fetch wells', e);
    } finally {
      this.setState({
        loadingListWell: false,
      });
    }
  }

  componentWillUnmount() {
    this.props.wellAction.receiveWells([]);
    this.props.wellAction.filterWell(-1);
    // this.props.history.go(-1);
    store.remove('prevConfig');
  }

  initLocalStorageData = () => {
    if (!store.get('querys')) {
      store.set('querys', []);
    }
    if (!store.get('crossPlots')) {
      store.set('crossPlots', false);
    }
  };

  onChangeUploadGeopressure = event => {
    const scope = this;
    const file = event.target.files[0];
    const reader = new FileReader();

    const wells = this.props.wells.items;
    const thereIsConflict = checkIfExistsWell(wells);

    const openGeopress = true;
    const { selectedMin, selectedMax } = this.state;

    this.setState({
      ...initialState,
      selectedMin,
      selectedMax
    });

    reader.onload = function () {
      const lines = this.result;

      scope.setState({
        geopressureCurveKeys: {
          ...geopressureDefaultCurves
        },
        lasFile: lines
      });
      processingGeopressLas(lines, file, scope, openGeopress);
    };

    if (file) {
      reader.readAsText(file);
    }
  };

  onChangeUploadInWell = event => {
    const file = event.target.files[0];
    const reader = new FileReader();
    // reader.onload = function () {
    // }
  }

  changeInWellTableModal = () => {
    this.setState((state) => ({
      isInWellTableIsVisible: !state.isInWellTableIsVisible,
      GWITSML: true,
    }));
  }

  onCloseLithologyMatchCurves = () => {
    this.setState((state) => ({
      ...state,
      isOpenLithologyMatchCurves: false,
    }));
  }

  openLithologyMatchCurves = () => {
    this.setState((state) => ({
      ...state,
      isOpenLithologyMatchCurves: true,
    }));
  }

  onChangeLithoUpload = (scope) => event => {
    const file = event.target.files[0];
    const reader = new FileReader();

    this.setState(initialState);

    // TODO refactor this function has much responsability
    reader.onload = function () {
      const lines = this.result;

      // Check if is a CSV
      if (file.type && file.type.includes('csv')) {
        // Enable to process with web workers
        const worker = !!Papa.WORKERS_SUPPORTED;
        csvToJSON(lines, { worker }).then(({ errors, data, valid }) => {
          if (!valid) {
            alert(
              `Error: ${errors[0].message}.\nInvalid .csv file.\nContact the support: suporte@geowellex.com`
            );
            return;
          }

          /*
            [TO DO]
            (Solution steps)
            DONE  - map all unique third column on csv with to get the lithology abreviation
            DONE  - Generate Array with theses names accessing all lithologies from csv file
            DONE  - Generate the labels and the options using ABBREVS_ROCKS_NAMES
            DONE  - Implement Open Match Lithology Modal Listing names of match lithology curves
            DONE  - Get selected curves from the match litology
            DONE  - converting interval of top and bottom in indexes based on depth index 
            DONE  - Format all to have the same format of lithologyScore response
            NEXT  - Add new Chart of Interpreted Lithology using the interpreted data
            NEXT  - Keep It stored accross sections
          */
          console.log('csv of litho  ==>', data);
          const litWellLength = data.length;
          const litWellDepth = Number(data[litWellLength - 1][1])

          const currWellLength = scope.props.wells.currentWell.RAW.depth.data.length;
          const currWellDepth = scope.props.wells.currentWell.RAW.depth.data[currWellLength - 1];
          const matchDepth = litWellDepth === currWellDepth;

          const rocksWithoutHeader = data.slice(1, data.length).map(r => {
            if (Array.isArray(r[2])) {
              return r[2][0];
            } else {
              return r[2];
            }
          });

          const { currentWell } = scope.props.wells;
          if (currentWell && matchDepth) {
            const intrepretedLithologyKeys = rocks;

            // lithology names to show as labels of AI Lithology Prediction column
            const lithoNames = rocks;

            const allUniqRocks = uniq(rocksWithoutHeader).filter(rockName =>
              Object.keys(ABBREVS_ROCKS_NAMES).includes(rockName)
            );

            const fileRockNames = allUniqRocks.map((rockAbreviation) => {
              return ABBREVS_ROCKS_NAMES[rockAbreviation];
            });

            const interpretedLithologyFromCSV = generateLithologyFromTopAndBottom(
              currentWell.RAW.depth.data.length,
              currentWell.RAW.depth.data,
              rocksWithoutHeader,
              data
            );

            scope.setState((state) => ({
              ...state,
              fileRocksNames: lithoNames,
              fielRocksKeys: fileRockNames,

              aiInterpretedLithologyNames: Object.keys(interpretedLithologyFromCSV),
              aiIntrepretedLithologyKeys: intrepretedLithologyKeys,

              isOpenLithologyMatchCurves: true,

              interpretedLithologyFromCSV,
            }));
          } else if (!currentWell) {
            notification.error({
              duration: 6,
              message: 'Please select a well first!',
              description: 'You should select a well to add lithology data',
              className: 'bit-size-notification'
            });
          } else if (!matchDepth) {
            scope.setState({
              isOpenLithologyError: true
            });
          }
        });
      }
    };
    if (file) {
      reader.readAsText(file);
    }
  };

  onChangeUpload = event => {
    const scope = this;
    const file = event.target.files[0];
    const reader = new FileReader();

    const wells = this.props.wells.items;
    const thereIsConflict = checkIfExistsWell(wells);

    this.setState(initialState);

    // TODO refactor this function has much responsability
    reader.onload = async function () {
      const lines = this.result;
      scope.setState({
        curvesKeys: {
          ...defaultCurveKeys,
          c2h4: null,
          c3h6: null,
          c2s: null
        },
        lasFile: lines,
        showOverlay: true
      });

      // Check if is a CSV
      // if (file.type && file.type.includes('csv')) {
      //   // Enable to process with web workers
      //   const worker = !!Papa.WORKERS_SUPPORTED;
      //   try {
      //     csvToJSON(lines, { worker }).then(({ errors, data, valid }) => {
      //       console.log("csv error ====>", errors);
      //       console.log("errors ====>", JSON.stringify(errors));
      //       console.log("csv error ====>", errors);
      //       if (!valid) {
      //         alert(
      //           `Error: ${errors[0].message}.\nInvalid .csv file.\nContact the support: suporte@geowellex.com`
      //         );
      //         return;
      //       }
      //       formatParsedArrayToWellObject(data, thereIsConflict, scope);
      //     });
      //   } catch(e) {
      //     console.log("catch csv error", e)
      //   }
      // } else {
      //   processingLas(lines, file, thereIsConflict, scope);
      // }

      var metadata;

      var formData = new FormData();
      formData.append("las_file", file);

      const token = store.get('token');

      try {
        const { data: { content: metadataResponse } } = await parseLasFile(formData, token)
        scope.props.wellAction.receiveMetadata(metadataResponse);
        metadata = metadataResponse;
        scope.setState({
          showOverlay: false,
          modalInWellIsVisible: false,
          isOpenConfigImport: true,
          metadata,
          alert: {
            isOpen: true,
            message: 'Parsed las file successfully',
            severity: 'success'
          }
        });
      } catch (e) {
        console.log('error: ', e)
        scope.setState({
          alert: {
            isOpen: true,
            message: 'Error when parsing las file',
            severity: 'error'
          }
        })
      }
    }
    if (file) {
      reader.readAsText(file);
    }
  }

  handleCloseAlert = () => {
    this.setState({
      alert: {
        isOpen: false,
        message: '',
        severity: ''
      }
    })
  }

  handleCloseDrawer = () => {
    this.setState({
      isOpenConfigImport: false
    })
  }

  handleOkLithologyError = () => {
    this.setState({
      isOpenLithologyError: false,
      modalInWellIsVisible: true,
    })
  }

  handleCloseLithologyError = () => {
    this.setState({
      isOpenLithologyError: false,
    })
  }

  uploadFile = () => {
    this.setState({ openImportData: true });
    this.uploadRef.current.value = null;
    this.uploadRef.current.click();
  }

  uploadLithoFile = () => {
    this.setState({ openImportLithoData: true });
    this.uploadLithoRef.current.value = null;
    this.uploadLithoRef.current.click();
  }

  uploadFileGeopressures = () => {
    const { selectedWell } = this.props.wells;
    const openGeopressureNotification = () => {
      notification.error({
        duration: 6,
        message: 'Please select a well first!',
        description: 'You should select a well to add geopressures data',
        className: 'bit-size-notification'
      });
    };
    if (selectedWell === -1) {
      openGeopressureNotification();
      return;
    }
    this.setState({ openImportData: false });
    this.uploadGeopressureRef.current.value = null;
    this.uploadGeopressureRef.current.click();
  };

  propagateTotalDetphZoom = (min, max) => {
    min = Math.round(min);
    max = Math.round(max);

    this.setState({
      selectedMin: min,
      selectedMax: max
    });
    if (this.props.wells.currentWell) {
      syncronizeDepthZoom(Highcharts, min, max, this.props.wells.currentWell.depth);
    }
  };

  refreshData = () => {
    const { currentWell } = this.props.wells;
    if (currentWell) {
      const { source, uid, uidWell, well_uid } = currentWell;
      const token = store.get('token');
      const afterSuccess = () => {
        this.setState({ loadingWell: false });
        this.selectWell(uid)
      };

      this.setState({ loadingWell: true });
      // check what kind of well inWell or a well from las file
      if (uid && uidWell) {
        this.props.wellAction.listInWell(source, uid, uidWell, token, afterSuccess);
      } else if (currentWell.well_uid) {
        this.props.wellAction.listWell(currentWell.well_uid, token, currentWell, afterSuccess);
      }
    } else {
      notification.error({
        duration: 6,
        message: 'Please select a well first!',
        description: 'You should select a well and then refresh the data',
        className: 'bit-size-notification'
      });
    }
  }

  openCustomizeCharts = () => {
    this.setState({
      isOpenCustomizeCharts: true
    });
  }

  closeCustomizeCharts = () => {
    this.setState({
      isOpenCustomizeCharts: false
    });
  }

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  openModalGeopress = () => {
    this.setState({ geopressIsOpen: true });
  };

  closeModalGeopress = () => {
    this.setState({
      geopressIsOpen: false,
      current: 0
    });
  };

  changeWellRanges = value => {
    const [rangeTop, rangeBottom] = value;
    this.setState(() => ({
      rangeTop,
      rangeBottom
    }));
  };

  selectMnemnonics = field => value => {
    const topAndBottom = {};
    const { JSONData } = this.state;

    const { data } = this.state;
    if (field === 'depth' || value === 'depth_(ft)' && JSONData && JSONData.data && JSONData.data[value]) {
      topAndBottom.top = JSONData[value][0];
      topAndBottom.bottom = JSONData[value].slice(-1)[0];

      const firstDepthValues = JSONData[value].slice(0, 100);
      const step = calculateSteps(firstDepthValues);

      const swappedKeyValues = arrayFlipToObject(JSONData[value]);

      // Get the step
      this.setState(state => ({
        step: step || state.step,
        swappedKeyValues
      }));
    }
    // else if (field === 'depth' && !JSONData[value]) {
    //   alert('Please select a valid Depth curve!');
    //   return;
    // }

    this.setState(state => ({
      curvesKeys: { ...state.curvesKeys, [field]: value },
      errorCurves: {
        ...state.errorCurves,
        [field]: null
      },
      ...topAndBottom
    }));
  };

  selectMnemnonicsGeopressure = field => value => {
    // this gonna reset errors in OBG when someone is selecting
    // waterDepth or density it is necessary because when there is
    // waterDepth or density OBG is not necessary
    const resetFields = {};
    if (field === 'waterDepth' || field === 'density') {
      resetFields.overburdenGradient = null;
    } else if (field === 'overburdenGradient') {
      resetFields.waterDepth = null;
      resetFields.density = null;
    }

    this.setState(state => ({
      ...state,
      geopressureCurveKeys: {
        ...state.geopressureCurveKeys,
        ...resetFields,
        [field]: value
      },
      geopressureErrors: {
        ...state.geopressureErrors,
        ...resetFields,
        [field]: null
      }
    }));
  };

  normalizeMnemonics = () => {
    this.closeModal();
  };

  nextGeopress = () => {
    const {
      top,
      bottom,
      current,
      wellNames,
      currentWell,
      hadConflict
    } = this.state;

    this.setState({
      current: current + 1
    });
  };

  next = () => {
    const {
      top,
      bottom,
      current,
      wellNames,
      currentWell,
      hadConflict
    } = this.state;

    const { wells } = this.props;

    // try to avoid if someone wrote already well and avoid duplicate
    // ensure that everytime to when to click in next check if there are conflict
    const thereIsConflict = checkIfExistsWell(wells.items)(currentWell.well);
    if (thereIsConflict && current === 0 && !hadConflict) {
      this.setState({ hasConflictData: true, hadConflict: true });
      return;
    }

    if (current === 0 && !currentWell.well) {
      this.setState({
        errors: {
          wellName: 'This field is required'
        }
      });
      return;
    }

    this.setState({
      hadConflict: true,
      hasConflictData: false,
      current: current + 1
    });
  };

  stepModal = (conflictingType, newWell) => {
    if (conflictingType === UPDATE) {
      this.setState(state => ({
        hasConflictData: false,
        hadConflict: true,
        conflictingType: UPDATE,
        current: state.current,
        currentWell: {
          ...state.currentWell,
          bitSize: [...get(this.props.wells, 'currentWell.bitSize', [])]
        }
      }));
    } else if (conflictingType === CREATE) {
      this.setState(state => ({
        hasConflictData: false,
        newWellName: newWell,
        conflictingType: CREATE,
        currentWell: {
          ...state.currentWell,
          well: newWell,
          bitSize: null,
          zones: {}
        }
      }));
    }
  };

  prev = () => {
    const current = this.state.current - 1;
    this.setState({ current });
    if (this.state.activeOverburden) {
      this.onChangeOverburden();
    }
  };

  goPrevBitSizes = bitSize => {
    const current = this.state.current - 1;
    this.setState(state => ({
      currentWell: {
        ...state.currentWell,
        bitSize
      },
      current
    }));
  };

  calculateBitSizes = geopressureBitSize => {
    const bitSizes = geopressureBitSize.reduce((acc, current) => {
      const { top, bottom, bitSize } = current;
      let values = [];
      for (let i = top; i <= bottom; i++) {
        const [head, tail] = bitSize.split(' ');
        const decimalBitSize = parseFloat(head) + parseFloat(eval(tail));
        const UNIT_CONVERTION_IN_TO_METERS = 0.0254;
        values.push(decimalBitSize * UNIT_CONVERTION_IN_TO_METERS);
      }
      acc = [...acc, ...values];
      return acc;
    }, []);
    return bitSizes;
  };

  startProcessGeopressure = () => {
    const { geopressureCurveKeys, withoutOverburden } = this.state;
    const {
      depth,
      rop,
      wob,
      ecd,
      waterDepth,
      density,
      rpm,
      overburdenGradient,
      normalPressureGradient
    } = geopressureCurveKeys;

    if (!depth || !rop || !wob || !ecd || !rpm || !normalPressureGradient) {
      this.setState({
        geopressureErrors: {
          depth: !depth ? 'This field is required!' : null,
          rop: !rop ? 'This field is required!' : null,
          wob: !wob ? 'This field is required!' : null,
          ecd: !ecd ? 'This field is required!' : null,
          rpm: !rpm ? 'This field is required!' : null,
          normalPressureGradient: !normalPressureGradient
            ? 'This field is required!'
            : null
        }
      });
      return;
    }

    if (withoutOverburden && !overburdenGradient) {
      if (!waterDepth || !density) {
        this.setState({
          geopressureErrors: {
            ...this.state.geopressureErrors,
            overburdenGradient: null,
            waterDepth: !waterDepth ? 'This field is required!' : null,
            density: !density ? 'This field is required!' : null
          }
        });
        return;
      }
    }

    if (!overburdenGradient || overburdenGradient === '') {
      this.setState({
        geopressureErrors: {
          ...this.state.geopressureErrors,
          overburdenGradient: !overburdenGradient
            ? 'This field is required!'
            : null
        }
      });
      return;
    }

    const {
      JSONData,
      curvesKeys,
      currentWell,
      bottom,
      top,
      rangeBottom,
      rangeTop
    } = this.state;
    const currentData = JSONData.data ? JSONData.data : JSONData;
    const depthKey = geopressureCurveKeys.depth;

    const mw = currentData[geopressureCurveKeys.mw]
      ? currentData[geopressureCurveKeys.mw]
      : [];
    const obg = currentData[geopressureCurveKeys.overburdenGradient];
    const ecdData = currentData[geopressureCurveKeys.ecd];

    const data = {
      depth: currentData[geopressureCurveKeys.depth],
      rop: currentData[geopressureCurveKeys.rop],
      wob: currentData[geopressureCurveKeys.wob],
      ecd: ecdData,
      n: currentData[geopressureCurveKeys.rpm],
      overburdenGradient: obg,
      normalPressureGradient: normalPressureGradient,
      mw
    };

    this.setState({
      processing: true,
      errorCurves: geopressureDefaultCurves,
      withoutOverburden: false
    });

    let option = undefined;
    const THERE_IS_NO_GRADIENT = 1;
    const THERE_IS_WATER_DEPTH_AND_DENSITY = 2;

    if (overburdenGradient) {
      option = 1;
    } else if (!overburdenGradient && waterDepth && depth) {
      option = 2;
    }

    const bs = this.calculateBitSizes(currentWell.geopressureBitSize);
    const onSuccessDxc = dxc => {
      const csvData = {
        depth: currentData[geopressureCurveKeys.depth],
        ecd: ecdData,
        n: currentData[geopressureCurveKeys.rpm],
        rop: currentData[geopressureCurveKeys.rop],
        wob: currentData[geopressureCurveKeys.wob],
        obg: obgWidthDepth,
        bs
      };

      // TODO create a unique reduce
      const mwWithDepth = currentData[geopressureCurveKeys.depth].map(
        (currentDepth, index) => {
          return [currentDepth, mw.length && mw[index] ? mw[index] : 0];
        }
      );

      const obgWidthDepth = currentData[
        geopressureCurveKeys.depth
      ].map((currentDepth, index) => [currentDepth, obg[index]]);

      const ecdWidthDepth = currentData[
        geopressureCurveKeys.depth
      ].map((currentDepth, index) => [currentDepth, ecdData[index]]);

      // syncrhonize with depth and transform in percentage
      const dxcWithDepth = currentData[
        geopressureCurveKeys.depth
      ].map((currentDepth, index) => [currentDepth, dxc[index]]);

      const newWell = this.props.wells.currentWell;

      this.props.wellAction.updateWell({
        ...newWell,
        geopressure: {
          dxc: dxcWithDepth,
          ...csvData,
          mw: mwWithDepth,
          obg: obgWidthDepth,
          ecd: ecdWidthDepth
        },
        geopressureToCalculate: {
          dxc: dxc,
          ...csvData,
          mw: mw,
          obg: currentData[geopressureCurveKeys.overburdenGradient],
          ecd: ecdData
        },
        geopressureParameters: {
          option,
          bs,
          gn: parseFloat(normalPressureGradient)
        }
      });

      setOnIndexDB('wells', this.props.wells.items);
      this.closeModalGeopress();
      message.success('Geopressure config applyed successfully', 5);
    };

    // this.calculateDxc({
    //   ...data,
    //   option,
    //   onSuccessDxc,
    //   bs
    // });
  };

  startProcess = () => {
    const {
      JSONData,
      curvesKeys,
      currentWell,
      bottom,
      top,
      rangeBottom,
      rangeTop,
      loading
    } = this.state;
    const { curve, well } = this.state;
    const oldData = JSONData.data ? JSONData.data : JSONData;
    const depthKey = curvesKeys.depth;



    // if (!depth || !c1 || !c2 || !c3 || !c4 || !c5) {
    //   this.setState({
    //     errorCurves: {
    //       depth: !depth ? 'Depth field is required!' : null,
    //       c1: !c1 ? 'C1 field is required!' : null,
    //       c2: !c2 ? 'C2 field is required!' : null,
    //       c3: !c3 ? 'C3 field is required!' : null,
    //       c4: !c4 ? 'nC4 field is required!' : null,
    //       c5: !c5 ? 'nC5 field is required!' : null
    //     }
    //   });
    //   return;
    // }

    this.setState(
      {
        processing: true,
        errorCurves: {
          depth: null,
          c1: null,
          c2: null,
          c3: null,
          c4: null,
          c5: null,
          c2h4: null,
          c2h6: null,
          c2s: null
        },
        loadingWell: true
      },
      () => {
        setTimeout(async () => {
          // Now I'm know the limits to top / bottom
          // I will find the indext and cut all
          // TODO create a function to validate
          // TODO add number validation or mask on these fields

          setTimeout(() => {
            this.setOverlay(true);
          }, 300);

          const defaultRangeValues = {
            top: { index: null, value: null },
            bottom: { index: null, value: null }
          };

          // // TODO Moove it out
          let topAndBottom = null;
          if (rangeTop && rangeBottom) {
            topAndBottom = this.state[depthKey].reduce((acc, item, index) => {
              if (
                Math.round(Number(item)) === Math.round(Number(rangeBottom))
              ) {
                acc.bottom.index = index;
                acc.bottom.value = item;
              }
              if (Number(item) === Number(top)) {
                acc.top.index = index;
                acc.top.value = item;
              }
              return acc;
            }, defaultRangeValues);
          }

          let cuttedData = {};

          // TODO leo extract it out a function and test
          // is there is bottom and top cut the array
          const curveData = this.state[depthKey];
          const validTopBottom = checkIfThereAreTopAndBottom(
            curveData,
            Number(rangeTop),
            Number(rangeBottom)
          );

          if (validTopBottom.bottom.index >= 0) {
            cuttedData = keys(oldData).reduce((acc, key) => {
              if (oldData[key] && oldData[key] && oldData[key].slice) {
                const currentColumnData = oldData[key].slice(
                  validTopBottom.top.index,
                  validTopBottom.bottom.index
                );
                acc[key] = currentColumnData || [];
              }
              return acc;
            }, initialData);
          }

          const data = oldData;
          // validTopBottom.top.isValid &&
          // validTopBottom.bottom.isValid &&
          // !!keys(cuttedData).length
          //   ? cuttedData
          //   : oldData;

          const c1Byc2 = [];
          const ic5BynC5 = [];
          const ic4BynC4 = [];
          const characterRatioData = [];
          const balanceRatioData = [];
          const wetnessRatioData = [];
          const isotopes = [];
          const rop = [];

          const c1Composition = [];
          const c2Composition = [];
          const c3Composition = [];
          const c4Composition = [];
          const c5Composition = [];

          const ic5Values = [];
          const ic4Values = [];
          const ic4Normal = [];
          const ic5Normal = [];

          const api = [];
          const gor = [];
          const slowFactor = [];

          const gammaRay = [];

          const c2c3Ratios = [];
          const c3c2Ratios = [];
          const flow = [];

          // Alias that we get from the select mnemonic
          const c1Key = curvesKeys.c1;
          const c2Key = curvesKeys.c2;
          const c3Key = curvesKeys.c3;
          const c4Key = curvesKeys.c4;
          const c5Key = curvesKeys.c5;

          const ropKey = curvesKeys.rop;

          const gammaRayKey = curvesKeys.gamma_ray;

          const flowInOutKey = curvesKeys.flow;

          const totalGas = [];
          const totalCarbon = [];
          const totalCarbonOnly = [];

          const c1Normalized = [];

          const { bitSize } = currentWell;

          const dataC1Key = data && data[c1Key] ? data[c1Key] : [];
          // TODO refactor it and move it out from this class
          for (let i = 0; i <= dataC1Key.length; i++) {
            const gammaRayValue = gammaRayKey && data[gammaRayKey] ? data[gammaRayKey][i] : 0;
            gammaRay.push(gammaRayValue);

            const flowInOutValue = flowInOutKey && data[flowInOutKey] ? data[flowInOutKey][i] : 0;
            flow.push(flowInOutValue);

            let c1 = c1Key ? dataC1Key[i] : 0;
            let c2 = c2Key ? data[c2Key][i] : 0;

            const ropValue = ropKey ? data[ropKey][i] : 0;
            rop.push(ropValue);

            let nc5 = c5Key && data[curvesKeys.c5] && data[curvesKeys.c5][i] ? data[curvesKeys.c5][i] : 0;
            const ic5Key = curvesKeys.ic5;
            let ic5 = ic5Key && data[ic5Key] && data[ic5Key][i] ? data[ic5Key][i] : 0;
            // Avoid to broken when there is no ic5
            ic5BynC5.push(isNaN(ic5 / nc5) ? 0 : ic5 / nc5);

            let nc4 = c4Key && data[c4Key] && data[c4Key][i] ? data[c4Key][i] : 0;
            const ic4Key = curvesKeys.ic4;
            let ic4 = ic4Key && data[ic4Key] && data[ic4Key][i] ? data[ic4Key][i] : 0;
            // Avoid to broken when there is no ic5
            ic4BynC4.push(isNaN(ic4 / nc4) ? 0 : ic4 / nc4);

            let c3 = c3Key && data[c3Key] && data[c3Key][i] ? data[c3Key][i] : 0;
            c1 = c1 || 0;
            c2 = c2 || 0;
            c3 = c3 || 0;
            ic4 = ic4 || 0;
            nc4 = nc4 || 0;
            ic5 = ic5 || 0;
            nc5 = nc5 || 0;

            const totalGasKey = curvesKeys.total_gas;
            const currentTotalGas = totalGasKey ? data[totalGasKey][i] : 0;
            totalGas.push(parseFloat(currentTotalGas));

            const currentTotalCarbon = calculateTotalCarbon(
              c1,
              c2,
              c3,
              nc4 + ic4,
              nc5 + ic5
            );
            const depth = data[curvesKeys.depth];
            const currentDepth = depth[i];

            totalCarbon.push([
              Math.abs(currentTotalCarbon),
              avoidZero(currentDepth)
            ]);
            totalCarbonOnly.push(Math.abs(currentTotalCarbon));

            ic4Normal.push(ic4);
            ic5Normal.push(ic5);

            // iC4
            ic4Values.push([
              [0, avoidZero(currentDepth)],
              [ic4, avoidZero(currentDepth)]
            ]);

            // iC5
            ic5Values.push([
              [0, avoidZero(currentDepth)],
              [ic5, avoidZero(currentDepth)]
            ]);

            const c2c3Ratio =
              c2 === 0 || c3 === 0 ? 'N/A' : (c2 / c3).toFixed(3);
            const c3c2Ratio =
              c3 === 0 || c2 === 0 ? 'N/A' : (c3 / c2).toFixed(3);

            c2c3Ratios.push(c2c3Ratio);
            c3c2Ratios.push(c3c2Ratio);

            // TODO leo need create a function to refactor it
            // All equations here was revised with Gabriel
            const getGasComposition = calculateGasComposition(
              c1,
              c2,
              c3,
              nc4,
              nc5,
              ic4,
              ic5
            );

            const gasComp1 = getGasComposition(c1);
            const gasComp2 = getGasComposition(c2);
            const gasComp3 = getGasComposition(c3);
            const gasComp4 = getGasComposition(nc4 + ic4);
            const gasComp5 = getGasComposition(nc5 + ic5);

            c1Composition.push(gasComp1);
            c2Composition.push(gasComp2);
            c3Composition.push(gasComp3);
            c4Composition.push(gasComp4);
            c5Composition.push(gasComp5);

            // to populate in reason chart
            if (gasComp1 && gasComp2) {
              c1Byc2.push(gasComp1 / gasComp2);
            } else {
              c1Byc2.push(0);
            }

            const wetnessValue = calculateWetness(
              gasComp1,
              gasComp2,
              gasComp3,
              gasComp4,
              gasComp5
            );
            wetnessRatioData.push(wetnessValue);

            const ballance = calculateBalanceRatio(
              gasComp1,
              gasComp2,
              gasComp3,
              gasComp4,
              gasComp5
            );
            balanceRatioData.push(ballance);

            const characterRatio = calculateCharacterRatio(
              gasComp3,
              gasComp4,
              gasComp5
            );
            characterRatioData.push(characterRatio);

            const c2ByC3SlowFactor =
              gasComp2 == 0 || gasComp3 == 0 ? 0 : gasComp2 / gasComp3;
            const c3ByC4SlowFactor =
              gasComp3 == 0 || gasComp4 == 0 ? 0 : gasComp3 / gasComp4;
            const c4ByC5SlowFactor =
              gasComp4 == 0 || gasComp5 == 0 ? 0 : gasComp4 / gasComp5;
            const currentSlowFactor =
              c2ByC3SlowFactor + c3ByC4SlowFactor + c4ByC5SlowFactor == 0
                ? 0
                : (c2ByC3SlowFactor + c3ByC4SlowFactor + c4ByC5SlowFactor) / 3;
            slowFactor.push(currentSlowFactor);

            const isotopeKey = curvesKeys.isotope;
            if (isotopeKey && data[isotopeKey]) {
              isotopes.push(data[isotopeKey][i]);
            }

            // ---------- API and GOR calculated ---------------
            const c4 = ic4 + nc4;
            const apiC2C3 = calculateAPIC2C3(c2, c3);
            const apiC2C3C4 = calculateAPIGravityC2C34(c2, c3, c4);
            const apiCalulated = {
              c2c3: apiC2C3,
              c2c34: apiC2C3C4
            };
            const gorC2C3 = calculateGORC2C3(c2, c3);
            const gorC2C34 = calculateGORC2C34(c2, c3, c4);
            const gorCalculated = {
              c2c3: gorC2C3,
              c2c34: gorC2C34
            };
            api.push(apiCalulated);
            gor.push(gorCalculated);

            // Calculate C1Normalized
            const c1NormalizedValue = (currentWell.bitSize || []).reduce(
              (acc, currentBitSize) => {
                if (
                  currentDepth <= currentBitSize.bottom &&
                  currentDepth >= currentBitSize.top
                ) {
                  acc = calculateC1Normalized(
                    currentBitSize.bitSize,
                    ropValue,
                    c1,
                    flowInOutValue
                  );
                }
                return acc;
              },
              null
            );
            c1Normalized.push(c1NormalizedValue);
          }
          data.c1Normalized = c1Normalized;
          data.ic4Normal = ic4Normal;
          data.ic5Normal = ic5Normal;
          data.totalCarbon = totalCarbon || [];
          data.c1Byc2 = c1Byc2 || [];
          data.ic4BynC4 = ic4BynC4 || [];
          data.ic5BynC5 = ic5BynC5 || [];
          data.wetnessRatioData = wetnessRatioData || [];
          data.balanceRatioData = balanceRatioData || [];
          data.characterRatioData = characterRatioData || [];
          data.c1Composition = c1Composition || [];
          data.c2Composition = c2Composition || [];
          data.c3Composition = c3Composition || [];
          data.nc4Composition = c4Composition || [];
          data.nc5Composition = c5Composition || [];
          data.gor = gor || [];
          data.api = api || [];
          data.slowFactor = slowFactor || [];
          data.gammaRay = gammaRay || [];
          data.ic4 = ic4Values || [];
          data.ic5 = ic5Values || [];
          data.c2c3Ratios = c2c3Ratios;
          data.c3c2Ratios = c3c2Ratios;
          data.rop = rop;
          data.isotope = isotopes;
          data.flow = flow || [];
          data.totalCarbonOnly = totalCarbonOnly;
          data.totalGas = totalGas;
          data.depth = data[depthKey];
          data.depth_ft = data && data['depth_(ft)'] || [];
          data.total_gas = totalGas;

          // normalizes gasCurves to crazy .las files
          data.c1 = data[c1Key];
          data.c2 = data[c2Key];
          data.c3 = data[c3Key];
          data.nc4 = data[c4Key];
          data.ic4 = data[curvesKeys.ic4];
          data.nc5 = data[c5Key];
          data.ic5 = data[curvesKeys.ic5];
          data.bitSize = bitSize;

          data.azimuth = curvesKeys.azimuth && data[curvesKeys.azimuth] ? data[curvesKeys.azimuth] : [];
          data.wob = curvesKeys.wob && data[curvesKeys.wob] ? data[curvesKeys.wob] : [];
          data.inclination = curvesKeys.inclination && data[curvesKeys.inclination] ? data[curvesKeys.inclination] : [];
          data.torque = curvesKeys.torque && data[curvesKeys.torque] ? data[curvesKeys.torque] : [];
          data.rpm = curvesKeys.rpm && data[curvesKeys.rpm] ? data[curvesKeys.rpm] : [];
          data.gamma_ray = curvesKeys.gamma_ray && data[curvesKeys.gamma_ray] ? data[curvesKeys.gamma_ray] : [];

          const wellKey = uuid();

          // This should be here because we have the new value of data when the top and bottom is applyed
          let end = 0;
          if (data && data[depthKey]) {
            end = data[depthKey].length;
          }
          const minusSelected = 300;
          const min = end - 300;

          const { well, version, curve } = this.state;

          const depthCurve = this.state.curve[depthKey];
          const unit =
            depthCurve && depthCurve.unit ? depthCurve.unit.toLowerCase() : '';
          store.set('unitOfMeansurement', unit);

          const wellName = this.state.currentWell.well;
          const oldNames = generateWellOptions(this.props.wells.items)
            .wellNames;

          const isUpdate = this.state.conflictingType === UPDATE;

          const { selectedWell } = this.props.wells;
          const { zoneAction, zone } = this.props;
          const newWell = {
            well_uid: isUpdate ? selectedWell : wellKey,
            ...data,
            data,
            wellInfo: well,
            version,
            curve,
            curvesNames: Object.keys(curve),
            wellName,
            min,
            max: end - minusSelected,
            maxDepth: end - minusSelected,
            selectedMin: min - minusSelected,
            selectedMax: end,
            sensorType: this.state.etheneRadio,
            unit,
            current: 0,
            selectedWell: wellKey,
            JSONData,
            wellNames: [...oldNames, wellName],
            zones: isUpdate ? zone.items : [],
            cutOffs: {},
            rangeTop: 0,
            rangeBottom: 0,
            curvesKeys,
            indexCurve: curve.indexCurve || '',
            indexUnit: curve.indexUnit || '',
            nameWell: curve.nameWell || '',
            mnemonicList: curve.mnemonicList || '',
            uid: curve.uid || '',
            uidWell: curve.uidWell || '',
            uidWellbore: curve.uidWellbore || '',
            unitList: curve.unitList || '',
          };

          /**
          * ----------- Should separate 
          *  from calculatedData ---------------
          */
          const rawCurves = this.state.curves ? this.state.curves : [];
          let rawData = rawCurves.reduce((acc, rawCurveName) => {
            if (JSONData && JSONData.calculatedData && JSONData.calculatedData[rawCurveName]) {
              acc[rawCurveName] = JSONData.data[rawCurveName];
            }
            if (JSONData && !JSONData.calculatedData && JSONData[rawCurveName]) {
              acc[rawCurveName] = JSONData[rawCurveName] || null;
            }
            return acc;
          }, {});

          rawData = {
            curvesKeys: this.state.curvesKeys,
            ...rawData,
          };

          /**
           * ---------- Should return all keys that are not raw key only calculated -----------
           */
          const dataKeys = Object.keys(data);
          const calculatedKeys = dataKeys.filter(dataKey => !rawCurves.includes(dataKey));

          /**
           * ---------- Should mount a object with calculated data -----------
           */
          const calculatedCurvesData = calculatedKeys.reduce((acc, calculatedCurve) => {
            if (data && data[calculatedCurve]) {
              acc[calculatedCurve] = data[calculatedCurve];
            }
            return acc;
          }, {});

          const calculatedData = { ...calculatedCurvesData, ...newWell };


          // currentAction(newWell);
          GlobalCutOff.registerDefaultData(newWell);

          const actionMessage = isUpdate ? 'updated' : 'created';
          // TODO move it out to utils/Zones.js
          this.setState(newWell, () => {
            // Starts the zoom from the zero value
            applyZoom(min, end);
            syncronizeDepthZoom(Highcharts, min, end);

            if (this.state.conflictingType !== CREATE) {
              // check if the zones are within the new range of the new updated well data
              const currentZones = get(newWell, 'zones', zone.items);
              const filteredZones = Object.keys(currentZones).reduce(
                (acc, zoneKey) => {
                  const currentZone = currentZones[zoneKey];

                  // check if the this zone is in a valid range of depth
                  const thereIsValidTop = newWell.depth.filter(currentDepth => {
                    const [roundNumber] = String(currentDepth).split('.');
                    return roundNumber === currentZone.top;
                  });
                  // check if the this zone is in a valid range of depth
                  const thereIsValidBottom = newWell.depth.filter(
                    currentDepth => {
                      const [roundNumber] = String(currentDepth).split('.');
                      return roundNumber === currentZone.bottom;
                    }
                  );

                  // if the zones are in a valid range of the new well data ok update then
                  if (thereIsValidBottom.length && thereIsValidTop.length) {
                    const depthData = get(this.props.wells, 'depthData', []);
                    // find by depth the index of bottom within highcharts instance
                    const bottomX = depthData.findIndex(value => {
                      const [currentDepth] = String(value.y).split('.');
                      if (currentDepth === currentZone.bottom) {
                        return value.y;
                      }
                    });
                    // find by depth the index of top within highcharts instance
                    const topX = depthData.findIndex(value => {
                      const [currentDepth] = String(value.y).split('.');
                      if (currentDepth === currentZone.top) {
                        return value.y;
                      }
                    });
                    const updatedZone = {
                      ...currentZone,
                      bottomX,
                      topX
                    };
                    acc[zoneKey] = updatedZone;
                  }

                  return acc;
                },
                {}
              );
            }

            const actionMessage = isUpdate ? 'updated' : 'created';

            const afterCreateOrUpdate = (currentwell_uid) => {
              // this.submitBitSize(false, isUpdate ? 'update' : 'create')({
              //   bitSize: this.state.bitSize,
              //   well_uidFromRequest: currentwell_uid
              // });
              this.setState({
                loadingWell: false,
                loadingListWell: false
              });
              this.setOverlay(false);
              // TODO store the well on localStorage
              successNotification(
                `${currentWell.well} ${actionMessage} successfully!`,
                <span style={{ color: '#fff' }}>'Now you are able to select this well to plot the curves.'</span>
              );
              this.setState({
                loadingListWell: false,
                processing: false,
                modalInWellIsVisible: false,
                isInWellTableIsVisible: false,
                loadingWell: false,
              });
            };

            if (isUpdate) {
              // find a well to update when this is not selected this will consider the wellName in options
              let currentWellToUpdate = this.props.wells.items.find(w => w.wellName === currentWell.well) || {};
              if (currentWellToUpdate && !currentWellToUpdate.well_uid) {
                const wellByName = this.props.wells.wellOptions.find(w => w.wellName === currentWell.well);
                currentWellToUpdate = {
                  ...currentWellToUpdate,
                  ...wellByName,
                };
              }

              if (
                !this.props.wells.currentWell || this.props.wells.currentWell &&
                !this.props.wells.currentWell.rawFileName
              ) {
                this.setState({
                  loadingWell: false,
                });
                this.setOverlay(true);

                const wellWithData = {
                  ...this.props.wells.currentWell,
                  ...currentWellToUpdate,
                  rawFileName: `data_${currentWellToUpdate.id}_raw`,
                  calculatedFileName: `data_${currentWellToUpdate.id}_calculated`,
                };
                const well_uidUpdated = currentWellToUpdate.well_uid;
                this.props.wellAction.updateWellStorage(
                  wellWithData,
                  well_uidUpdated,
                  rawData,
                  calculatedData,
                  store.get('token'),
                  afterCreateOrUpdate,
                );

                this.setState({
                  modalInWellIsVisible: false,
                  isInWellTableIsVisible: false
                });
                this.closeModal();
                return;
              }
              const wellWithData = {
                ...this.props.wells.currentWell,
                ...currentWellToUpdate,
                rawFileName: `data_${currentWellToUpdate.id}_raw`,
                calculatedFileName: `data_${currentWellToUpdate.id}_calculated`,
              };

              this.props.wellAction.updateWellStorage(
                wellWithData,
                currentWellToUpdate.well_uid,
                rawData,
                calculatedData,
                store.get('token'),
                afterCreateOrUpdate,
              );
              this.setState({
                loadingWell: false,
              });
              this.setOverlay(false);
            } else {
              this.props.wellAction.createBucket(
                newWell.wellName,
                rawData,
                calculatedData,
                store.get('token'),
                afterCreateOrUpdate,
              );
            }
            this.setState({
              modalInWellIsVisible: false,
              isInWellTableIsVisible: false
            });
            this.closeModal();
          });
        }, 200);
      }
    );
  };

  closeWell = () => {
    this.setState(
      {
        rotating: null,
        data: initialData,
        selectedMax: 0,
        selectedMin: 0,
        unit: '',
        processing: false,
        max: 0,
        min: 0,
        modalIsOpen: false,
        geopressIsOpen: false,
        curves: [],
        current: 0,
        rangeTop: 0,
        rangeBottom: 0,
        currentWell: {
          name: '',
          lasVersion: ''
        },
        errors: {
          top: null,
          bottom: null,
          wellName: null
        },
        curveKeys: '',
        bitSize: [],
        hasConflictData: false,
        hasNewWell: false
      },
      () => {
        this.uploadRef.current.value = null;
        this.closeModal();
      }
    );
  };


  listWellPhases = (currentWell) => {
    this.setState({ ...initialState, loading: true });
    this.props.wellPhasesAction.fetchWellPhases(
      store.get('token'),
      currentWell && currentWell.well_uid || currentWell && currentWell.wellInfo && currentWell.wellInfo.id,
      () => this.setState({ loading: false })
    );
  }

  setOverlay = (showOrHide = false) => {
    this.setState({
      showOverlay: showOrHide,
    });
  }

  loadWell = async (wellKey, well) => {
    this.setState({ loadingWell: true });

    // setTimeout(() => {
    //   this.setOverlay(true);
    // }, 300);

    const { wellAction } = this.props;
    const currentWell = this.props.wells.items.find((w) => w.uid === wellKey);
    // this.listWellPhases(currentWell);
    const authToken = store.get('token');

    if (!currentWell && wellKey === -1) {
      this.props.wellAction.filterWell(-1);
    } else {
      await wellAction.listWell(currentWell.uid, authToken, currentWell);
      this.setOverlay(false);
    }
    this.setState({
      loadingWell: false,
      wellKeySelected: currentWell.uid,
      showOverlay: false
    });
  };

  // fechtAllData = async (well) => {
  fechtAllData = (well) => {
    const token = store.get('token');

    this.setState({
      loadingWell: true,
      loadingListWell: true,
    });

    this.props.wellAction.listInWell(
      well.source,
      well.uid,
      well.uidWell,
      token,
      () => this.setState({ loadingWell: false, loadingListWell: false })
    );

    // try {
    // const responseListOneWell = await listOneWell(well.well_uid);
    // const responseListOneWell = await fetchInWell(store.get('automud-token'), uid, uidWell);

    /**
     * Getting the indexs of the last 200 meters
     * It is necessary get the index value because the zoom need it.
     */
    // const maxDepth = responseListOneWell.data.data.length-1;
    // const minDepth = 0;
    // this.setState({
    //   loadingWell: true,
    //   selectedMin: minDepth,
    //   selectedMax: maxDepth,
    //   wellKey: wellKey,
    //   options: options
    // });
    // let well = {
    //   depth: []
    // };
    // Object.values(responseListOneWell.data.data).map(item => {
    //   well = {
    //     depth: [...well.depth, item.depth]
    //   };
    // });
    // this.props.wellAction.updateWell({
    //   id: wellKey,
    //   lithology: [],
    //   wellData: well,
    //   name: options.props.children,
    //   ...responseListOneWell.data
    // });
    // const responseFetchLithology = await fetchLithology(wellKey);
    // this.props.wellAction.updateWell({
    //   ...this.props.wells.currentWell,
    //   id: wellKey,
    //   lithology: [...responseFetchLithology.data.data]
    // });

    // const responseFetchLithoAutomud = await fetchLithologyAutomud(wellKey);
    // this.props.wellAction.updateWell({
    //   ...this.props.wells.currentWell,
    //   id: wellKey,
    //   lithologyAutomud: [...responseFetchLithoAutomud.data.data]
    // });
    // this.setState({ loadingWell: false });

    // findOnIndexDB('wells').then(wells => {
    //   if (wells) {
    //     find(wells, value => {
    //       if (value.id === wellKey && value.geopressure != undefined) {
    //         const {
    //           bs,
    //           depth,
    //           dxc,
    //           ecd,
    //           mw,
    //           n,
    //           obg,
    //           rop,
    //           wob
    //         } = value.geopressure;
    //         const {
    //           bs: geoParamBs,
    //           gn: geoParamGn,
    //           option: geoParamOption
    //         } = value.geopressureParameters;
    //         const {
    //           bs: geoCalcBs,
    //           depth: geoCalcDepth,
    //           dxc: geoCalcDxc,
    //           ecd: geoCalcEcd,
    //           mw: geoCalcMw,
    //           n: geoCalcN,
    //           obg: geoCalcObg,
    //           rop: geoCalcRop,
    //           wob: geoCalcWob
    //         } = value.geopressureToCalculate;

    //         this.props.wellAction.updateWell({
    //           ...this.props.wells.currentWell,
    //           geopressure: {
    //             bs,
    //             depth,
    //             dxc,
    //             ecd,
    //             mw,
    //             n,
    //             obg,
    //             rop,
    //             wob
    //           },
    //           geopressureParameters: {
    //             bs: geoParamBs,
    //             gn: geoParamGn,
    //             option: geoParamOption
    //           },
    //           geopressureToCalculate: {
    //             bs: geoCalcBs,
    //             depth: geoCalcDepth,
    //             dxc: geoCalcDxc,
    //             ecd: geoCalcEcd,
    //             mw: geoCalcMw,
    //             n: geoCalcN,
    //             obg: geoCalcObg,
    //             rop: geoCalcRop,
    //             wob: geoCalcWob
    //           }
    //         });
    //         return true;
    //       }
    //     });
    //   }
    // });
    // } catch (error) {
    // alert('Please, Try log in to your account again or \n Contact the support: suporte@geowellex.com');
    // console.log('error', error);
    // }
  }

  selectWell = (wellKey) => {

    const { currentWell } = this.props.wells
    this.setState({ hydraulics: false }, () => {
      setTimeout(() => {
        this.loadWell(wellKey);
      }, 500);
    });

    if (currentWell) {
      console.log('currentWell=>', currentWell)
    };
    // TODO do a long polling with SWR
    // if (this.state.isActivaisActivatedPolling && this.pollingData === null) {
    //   polling((pollId) => {
    //     this.pollingData = pollId;
    //     (async () => {  
    //       try {
    //         await this.fechtAllData(well.uid, well.uidWell);
    //       } catch (err) {
    //         alert('Please, Try log in to your account again or \n Contact the support: suporte@geowellex.com');
    //         console.log(err);
    //       }
    //     })();
    //   }, 50000);
    // }
  }

  changeWellName = event => {
    event.persist();
    this.setState(state => ({
      currentWell: {
        ...state.currentWell,
        well: event.target.value
      },
      errors: {
        ...state.errors,
        wellName: null
      }
    }));
  };

  // TODO refactor it
  changeRanges = key => ({ target }) => {
    const value = target.value.replace(/,/g, '.');
    this.setState(state => ({
      [key]: value,
      errors: {
        ...state.errors,
        [key]: ''
      }
    }));
  };

  changeInWellModal = () => {
    this.setState((state) => ({
      modalInWellIsVisible: !state.modalInWellIsVisible,
    }));
  }

  // TODO implement aquisition step
  removeWell = () => {
    const well = this.props.wells.currentWell;
    this.props.wellAction.removeWell(
      store.get('token'),
      well.well_uid,
    );

    this.setState(
      {
        data: initialData,
        selectedWell: -1,
        JSONData: null
      },
      () => {
        store.set('selectedWell', '');
      }
    );
  };

  submitBitSize = (inWellFlow = false, action) => ({ bitSize, well_uidFromRequest }) => {
    if (!inWellFlow) {
      const well_uid = this.props.wells.currentWell && this.props.wells.currentWell.id
        ? this.props.wells.currentWell.id : well_uid;
      const bitSizeAndwell_uid = bitSize.map(phase => ({ ...phase, id: uuid() }));
      this.setState({ loadingWellPhases: true });

      if (this.props.wellphases.items && this.props.wellphases.items.length) {
        this.props.wellPhasesAction.updateBucketOfWellPhases(
          {
            data: bitSizeAndwell_uid,
            id: this.props.wellphases.items[0].id,
            well_uid: this.props.wells.currentWell.id || well_uidFromRequest,
          },
          this.props.wellphases.items[0].id,
          well_uid || well_uidFromRequest,
          () => {
            if (action && action === 'update') {
              message.success('Well phases updated successfully.');
            }
            this.setState({ loadingWellPhases: false, isModalVisibleWellPhases: false, });
          }
        );
      } else {
        this.props.wellPhasesAction.createBucketOfWellPhases(
          { data: bitSizeAndwell_uid, well_uid: well_uid || well_uidFromRequest },
          store.get('token'),
          well_uid || well_uidFromRequest,
          () => {
            if (action && action === 'create') {
              message.success('Well phases created successfully.');
            }
            this.setState({ loadingWellPhases: false, isModalVisibleWellPhases: false, });
          }
        );
      }
    } else if (action === 'train_model') {
      const depth = this.props.wells.currentWell && this.props.wells.currentWell.depth;
      const step = this.props.wells.currentWell && this.props.wells.currentWell.startIndex || 1;

      const bitSizeCurve = generateBitSizeCurve(depth.length, bitSize, step);

      this.setState(state => ({
        currentWell: {
          ...state.currentWell,
          bitSize,
          bit_size: bitSizeCurve,
        },
        bitSize,
      }));

      this.props.wellAction.updateWell({
        ...this.props.wells.currentWell,
        bit_size: bitSizeCurve,
        bitSize,
      });
      this.handleCancelModalWellPhases();
    } else {
      // in well next
      this.setState(state => ({
        currentWell: {
          ...state.currentWell,
          bitSize
        },
        bitSize,
      }));
      this.next();
    }
  }

  submitBitSizeGeopress = ({ bitSize }) => {
    this.setState(state => ({
      currentWell: {
        geopressureBitSize: bitSize
      }
    }));
    this.nextGeopress();
  }

  exportWell = (exportType) => {
    const { wells: { currentWell } } = this.props;
    if (!currentWell) {
      alert('Please, select a well');
      return;
    }
    this.downlodLasFile()
    // if (exportType.key === 'las') {
    //   this.downlodLasFile();
    // } else {
    //   this.convertJsonToCsv();
    // }
  }

  convertJsonToCsv = () => {
    const {
      wells: { currentWell }
    } = this.props;

    const selectedWellValidation = () => {
      notification.error({
        duration: 6,
        message: 'Please select a well first!',
        description: 'You should select a well before to export the csv file',
        className: 'bit-size-notification'
      });
    };
    if (!currentWell) {
      selectedWellValidation();
      return;
    }

    const currentWellValues = {
      // nameWell: currentWell.nameWell,
      // uid: currentWell.uid,
      // startIndex: currentWell.startIndex,
      // endIndex: currentWell.endIndex,
      // isActive: currentWell.isActive,
      // indexUnit: currentWell.indexUnit,
      depth: get(currentWell, 'depth', []),
      c1: get(currentWell, 'c1', []),
      c2: get(currentWell, 'c2', []),
      c3: get(currentWell, 'c3', []),
      c4: get(currentWell, 'c4', []),
      c5: get(currentWell, 'c5', []),
      nc4: get(currentWell, 'nc4', []),
      nc5: get(currentWell, 'nc5', []),
      ic4: get(currentWell, 'ic4', []),
      ic5: get(currentWell, 'ic5', []),
      flow: get(currentWell, 'flow', []),
      wob: get(currentWell, 'wob', []),
      gamma_ray: get(currentWell, 'gamma_ray', []),
      rpm: get(currentWell, 'rpm', []),
      rop: get(currentWell, 'rop', []),
      torque: get(currentWell, 'torque', []),
      total_gas: get(currentWell, 'total_gas', []),
      inclination: get(currentWell, 'inclination', []),
      aiGammaRay: get(currentWell, 'aiGammaRay', []),
      azimuth: get(currentWell, 'azimuth', []),
      realtime_litho_AGT: get(currentWell, 'realtimeLithology.AGT', []),
      realtime_litho_CARB_SHL: get(currentWell, 'realtimeLithology.CARB_SHL', []),
      realtime_litho_CHLK: get(currentWell, 'realtimeLithology.CHLK', []),
      realtime_litho_LIME: get(currentWell, 'realtimeLithology.LIME', []),
      realtime_litho_MRL: get(currentWell, 'realtimeLithology.MRL', []),
      realtime_litho_SDS: get(currentWell, 'realtimeLithology.SDS', []),
      realtime_litho_SHL: get(currentWell, 'realtimeLithology.SHL', []),
      realtime_litho_STS: get(currentWell, 'realtimeLithology.STS', []),
      realtime_litho_TUF: get(currentWell, 'realtimeLithology._TUF', []),

      litho_score_AGT: get(currentWell, 'lithologyScore.AGT', []),
      litho_score_CARB_SHL: get(currentWell, 'lithologyScore.CARB_SHL', []),
      litho_score_CHLK: get(currentWell, 'lithologyScore.CHLK', []),
      litho_score_LIME: get(currentWell, 'lithologyScore.LIME', []),
      litho_score_MRL: get(currentWell, 'lithologyScore.MRL', []),
      litho_score_SDS: get(currentWell, 'lithologyScore.SDS', []),
      litho_score_SHL: get(currentWell, 'lithologyScore.SHL', []),
      litho_score_STS: get(currentWell, 'lithologyScore.STS', []),
      litho_score_TUF: get(currentWell, 'lithologyScore._TUF', []),
    };

    const prepareToParseInCSV = createRowsToCsv(
      currentWellValues,
      fieldsToCsv,
      aliasesToCsv,
    );

    try {
      const json2csvParser = new Parser();
      const csv = json2csvParser.parse(prepareToParseInCSV);
      const wellName = currentWell.nameWell.replace(' ', '-');
      this.downloadFile(csv, `${wellName}.csv`);
    } catch (error) {
      console.log('csv error', error);
    }
  };

  downlodLasFile = async () => {
    const { wells: { selectedWell, currentWell } } = this.props;
    const token = store.get('token');
    try {
      const { data } = await getURLToDownloadLas(currentWell.well_uid, token);
      function download(fileName = 'well') {
        const element = document.createElement('a');
        element.setAttribute('href', data.path);
        element.setAttribute('download', fileName);
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
      download(`well-${currentWell.well_uid}`);
    } catch (e) {
      console.log('error', e);
    }
  }

  downloadFile = (csvFile, fileName) => {
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);
    a.href = window.URL.createObjectURL(
      new Blob([csvFile], { type: 'text/csv' })
    );
    a.setAttribute('download', fileName);
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  };

  splitLas = lasFile => {
    return lasFile.split(/\n/);
  };

  changeTab = () => {
    let showImportLas = false;
    if (!this.state.showImportLas) {
      showImportLas = true;
    }
    this.setState({
      showImportLas
    });
    if (this.sliderRef) {
      // TODO when refactor home remove it
      setTimeout(() => {
        if (this.sliderRef && this.sliderRef.forceUpdate) {
          this.sliderRef.forceUpdate();
        }
      }, 1000);
    }
  };

  onChangeOverburden = value => {
    const resetFields = {};
    resetFields.overburdenGradient = null;

    this.setState(state => ({
      geopressureCurveKeys: {
        ...state.geopressureCurveKeys,
        ...resetFields,
        ['waterDepth']: null,
        ['density']: null
      },
      geopressureErrors: {
        ...state.geopressureErrors,
        ...resetFields,
        ['waterDepth']: null,
        ['density']: null
      },
      activeOverburden: value,
      withoutOverburden: true
    }));
  };

  refactorConstants = value => {
    const changeValue = value.replace('_', ' ');
    if (value === 'overburdenGradient') {
      return 'OVER. GRADIENT';
    } else {
      return changeValue.toUpperCase();
    }
  };

  toggleActive = () => {
    if (this.props.wells && this.props.wells.currentWell && !this.props.wells.geopressureParameters) {
      alert('Please, You need to have Geopressure data to enable DXC');
      return;
    }

    const isActivated = !this.state.isActivated;
    this.setState({ isActivated });
  };


  toggleActivePolling = () => {
    this.setState({ isActivaisActivatedPolling: !this.state.isActivaisActivatedPolling }, () => {
      if (this.pollingData && !this.state.isActivaisActivatedPolling) {
        clearInterval(this.pollingData);
        this.pollingData = null;
      } else if (this.state.isActivaisActivatedPolling) {
        if (this.pollingData) {
          clearInterval(this.pollingData)
          this.pollingData = null;
        }
        this.pollingData = polling(() => {
          (async () => {
            await this.fechtAllData(this.state.wellKey, this.state.options);
          })();
        }, 1000);
      }
    });
  };

  unSelectWell = () => {
    let zones = clone(this.props.zone.items);
    if (!zones && this.props.wells.currentWell && this.props.wells.currentWell.zones) {
      zones = clone(this.props.wells.currentWell.zones);
    }

    map(zones, (zone) => {
      removeAllZones(zone.id);
    });

    setTimeout(() => {
      this.setState(() => ({
        selectedWell: -1,
        // @TODO move it to the modal state
        rotating: null,
        processing: false,
        modalIsOpen: false,
        etheneRadio: null,
        step: 1,
        errors: {
          top: null,
          bottom: null,
          wellName: null
        },
        defaultcurves: {
          depth: 'Depth',
          rop: 'ROP',
          gas_ratio: 'Gas Ratio',
          total_gas: 'Total Gas',
          c1: 'C1',
          c2: 'C2',
          c3: 'C3',
          c4: 'C4',
          nc4: 'nc4',
          nc5: 'nc5',
          c5: 'C5',
          character_ratio: 'Character Ratio',
          balance_ratio: 'Balance Ratio',
          wetness_ratio: 'Wetness Ratio',
          isotope: 'Isotope',
          chartType: '',
          flow: 'flow'
        },
        errorCurves: {
          depth: null,
          c1: null,
          c2: null,
          c3: null,
          c4: null,
          c5: null,
          ic4: null,
          ic5: null,
          c2h6: null,
          c2h4: null,
          c2s: null,
          flow: null
        },
        top: 0,
        bottom: 0,
        // Plotbox
        chartType: null,
        // Upload well
        loadingWell: false,
        curves: [],
        current: 0,
        curvesKeys: defaultCurveKeys,
        currentWell: {
          name: '',
          lasVersion: ''
        },
        checkedSensor: false,
        // @TODO move it to the wells reducer 
        data: initialData,
        max: 0,
        min: 0,
        selectedMax: 0,
        selectedMin: 0,
        unit: '',
        wellNames: [],
        selectedWell: -1,
        rangeTop: 0,
        rangeBottom: 0,
        visibleTooltip: false,
        showClass: 'fadeOut',
        visibleTooltip2: false,
        showClass2: 'fadeOut',
        hasConflictData: false,
        hadConflict: false,
        newWellName: '',
        hasNewWell: false,
        conflictingType: '',
        bitSize: [],
        lasFile: '',
        showImportLas: true,
        prepareToParseInCSV: [],
      }));
      this.props.zoneAction.deleteAll();
      this.props.wellAction.filterWell(-1);
    }, 500);
  }

  importNextInWell = async (well) => {
    this.setState({ calculating: true, showOverlay: true, });
    try {
      this.props.wellAction.receiveInWell(well);
      const response = well.source ? await fetchInWellWatcher(store.get('token'), well.source, well.uid, well.uidWell)
        : await fetchInWell(store.get('token'), well.uid, well.uidWell);
      this.props.wellAction.receiveInWellData(response.data);
      this.changeInWellTableModal()
      const file = {
        name: well.nameWell,
        size: 1000,
      };
      const wellData = { ...well, ...response.data };
      const isThereConflict = checkIfExistsWell(this.props.wells.items);
      const payload = { well_metadata: well, well_data: response.data };
      const { data: { content: parsedMetadata } } = await parseWellDataIntegration(payload, store.get('token'));
      this.props.wellAction.receiveMetadata(parsedMetadata)
      this.setState({
        showOverlay: false,
        modalInWellIsVisible: false,
        isOpenConfigImport: true,
        metadata: parsedMetadata,
        alert: {
          isOpen: true,
          message: 'Parsed Well successfully',
          severity: 'success'
        }
      });
      //processingInWell(wellData, file, isThereConflict, this, () => this.openModal());
      this.setState({ calculating: false });
    } catch (e) {
      this.setState({ calculating: false, showOverlay: false, });
      errorNotification('Error importing well! \n' + e.message,);
      console.log('error importNextInWell', e);
    }
  }

  // importNextInWell = async (well) => {
  //   try {
  //     this.setState({ calculating: true });
  //     const response = await fetchInWellSite(well.uid, well.uidWell, store.get('automud-token'),);
  //     this.changeInWellTableModal();
  //     this.props.wellAction.receiveWell({ ...well, ...response.data });
  //     this.closeWell();
  //     this.setState({ calculating: false });
  //   } catch (e) {
  //     this.setState({ calculating: false });
  //     console.log('error importNextInWell', e);
  //   }
  // }

  openEditLithologyModal = (min, max, type) => {
    const { selectedWell, currentWell } = this.props.wells;
    const selectedWellValidation = () => {
      notification.error({
        duration: 6,
        message: 'Please select a well first!',
        description: 'You should select a well before to use Machine Learning Module',
        className: 'bit-size-notification'
      });
    };
    if (selectedWell === -1) {
      selectedWellValidation();
      return;
    }
    this.setState({ selectedDepthMin: min, selectedDepthMax: max, IsOpenEditLithologyModal: true, lithologyType: type });
  }

  openConfigCurveModal = (filterProp, title) => {
    const { selectedWell, currentWell } = this.props.wells;
    const selectedWellValidation = ({ message, description }) => {
      notification.error({
        duration: 6,
        message,
        description,
        className: 'bit-size-notification'
      });
    };
    if (selectedWell === -1) {
      const note = {
        message: 'Please select a well first!',
        description: 'You should select a well before to use Machine Learning Module'
      }
      selectedWellValidation(note);
      return;
    }
    if (!currentWell.ml) {
      const note = {
        message: 'ML set not Found!',
        description: 'You should select a well that has a ML set to use this feature',
      }
      selectedWellValidation(note);
      return;
    }
    store.set('openConfigCurve', true);
    this.setState({ curveChangeFilterProp: filterProp, curveChangeTitle: title });
    this.setState({ IsOpenConfigCurveModal: true });
  }

  openModalMarchineLearning = (machineLearnType) => {
    const { selectedWell } = this.props.wells;
    const selectedWellValidation = () => {
      notification.error({
        duration: 6,
        message: 'Please select a well first!',
        description: 'You should select a well before to use Machine Learning Module',
        className: 'bit-size-notification'
      });
    };
    if (selectedWell === -1) {
      selectedWellValidation();
      return;
    }

    if (machineLearnType.key === 'predict') {
      this.setState({ IsOpenModalMarchine: true });
    }
    if (machineLearnType.key === 'experiments') {
      this.setState({ isOpenExperiments: true });
    }
    if (machineLearnType.key === 'train-modal') {
      this.setState({ isOpenTrainModelModal: true });
    }

  }

  onBackHome = () => {
    this.setState({
      isOpenExperiments: false,
      hydraulics: false
    });
  }

  openHydraulicsModule = () => {
    const { selectedWell, currentWell } = this.props.wells;
    const selectedWellValidation = () => {
      notification.error({
        duration: 6,
        message: 'Please select a well first!',
        description: 'You should select a well before to use the Hydraulics Module',
        className: 'bit-size-notification'
      });
    };

    const selectedWellValidationLas = () => {
      notification.error({
        duration: 6,
        message: 'Please select a Realtime Connected Well!',
        description: 'You should select a Realtime Updated Well to use the Hydraulics Module',
        className: 'bit-size-notification'
      });
    };
    if (selectedWell === -1) {
      selectedWellValidation();
      return;
    }
    if (!currentWell.isActive || currentWell.isActive != "TRUE") {
      selectedWellValidationLas();
      return;
    }
    this.props.wellAction.resetBeforeWell();
    this.setState({ hydraulics: true });
  }

  EnableGeoCopilot = (min, max) => {
    this.setState({ selectedDepthMin: min, selectedDepthMax: max, geocopilot: true });
  }

  handleCancelMarchineLearning = () => {
    this.setState({ IsOpenModalMarchine: false });
  }

  handleCancelTrainModel = () => {
    this.setState({ isOpenTrainModelModal: false });
  }
  handleCancelEditLithology = () => {
    this.setState({ IsOpenEditLithologyModal: false });
  }
  handleCancelConfigCurve = () => {
    this.setState({ IsOpenConfigCurveModal: false });
  }

  handleDisableGeocopilot = () => {
    this.setState({ geocopilot: false });
  }

  handleEnablingGeocopilot = () => {
    const { selectedWell, currentWell } = this.props.wells;
    const selectedWellValidation = () => {
      notification.error({
        duration: 6,
        message: 'Please select a well first!',
        description: 'You should select a well before to use GeoCopilot!',
        className: 'bit-size-notification'
      });
    };

    if (selectedWell === -1) {
      selectedWellValidation();
      return;
    }
    if (!this.state.isGeocopilotRunning) {
      this.setState({ isGeocopilotRunning: true })
      const selecteIntervalNotification = () => {
        notification.info({
          duration: 6,
          message: 'Please select an interval!',
          description: 'You should select a interval on the selected Depth before ask GeoCopilot for help!',
          className: 'bit-size-notification',
        });
      };
      selecteIntervalNotification();
      store.set('isGeocopilotRunning', true);
      return;
    }
    this.setState({ isGeocopilotRunning: false });
    store.set('isGeocopilotRunning', false);

  }

  formatCoordinates = (values) => {
    const { degrees_lat, degrees_long, minutes_lat, minutes_long, seconds_lat, seconds_long, x_value, y_value, zone, reference, reference_zone  } = values;

    if (degrees_lat === '') {
      return {
        lat_long_coordinates: null,
        utm_coordinates: null,
      }
    }

    return {
      lat_long_coordinates: {
        latitude: {
          degrees: degrees_lat,
          minutes: minutes_lat,
          seconds: seconds_lat,
        },
        longitude: {
          degrees: degrees_long,
          minutes: minutes_long,
          seconds: seconds_long,
        },
        geodatum: reference
      },
      utm_coordinates: {
        X: x_value,
        Y: y_value,
        geodatum: reference_zone,
        zone,
      },
    }
  }

  handleSubmitImportData = async (data) => {
    this.setState({
      loadingWell: true,
      loading: true,
      showOverlay: true,
    });
    const token = store.get('token');
    const {
      name, range, unit, sensor_type, degrees_lat, minutes_lat, seconds_lat,
      degrees_long, minutes_long, seconds_long, reference, x_value, y_value,
      reference_zone, zone, name1, unit1, type1, mnemonicRow1, bs, curves
    } = data;
    const { start_depth, stop_depth } = range;

    const transformFloatValueBitSize = bs?.[0]?.diameter ? calculateBitSize(bs?.[0]?.diameter) : 0;

    const normalizedBitsSize = [{ ...bs[0], diameter: transformFloatValueBitSize }];
    const indexCurveMnemonic = this?.props.wells?.metadata?.log_sets?.[0]?.index_curve?.mnemonic;
    const indexCurveWithName = curves.find(c => c.mnemonic === indexCurveMnemonic);

    const coordinates = this.formatCoordinates(data);
    const existingLogSets = this.state?.metadata?.log_sets || [];

    const logSetRaw = existingLogSets.find(logSet => logSet.name === 'RAW');
    const newLogSet = {
      ...logSetRaw,
      curves,
      index_curve: indexCurveWithName || logSetRaw?.index_curve,
    };

    const filteredLogSets = existingLogSets.filter(logSet => logSet.name !== 'RAW');
    const updatedLogSets = [...filteredLogSets, newLogSet];

    const newMetadata = {
      ...this.state.metadata,
      ...coordinates,
      name,
      start_depth,
      stop_depth,
      ethene_sensor: {
        fid: sensor_type === 'FID',
        has_sensor: !!sensor_type,
        ms: sensor_type === 'MS'
      },
      well_phases: normalizedBitsSize,
      log_sets: updatedLogSets 
    };

    try {
      const saveParsedDataResponse = await saveParsedData(newMetadata, token);
      console.log('saveParsedDataResponse?.data?.content?.well_metadata leo 2', saveParsedDataResponse?.data?.content?.well_metadata);
      this.props.wellAction.receiveWell(saveParsedDataResponse?.data?.content?.well_metadata);
      this.setState({
        alert: {
          isOpen: true,
          message: 'Well created successfully',
          severity: 'success'
        },
      });
      const afterList = () => {
        this.setState({
          loadingWell: false,
          loading: false,
          showOverlay: false
        });
      };
      this.handleCloseDrawer();
      await this.props.wellAction.listWell(
        saveParsedDataResponse?.data?.content?.well_metadata?.uid,
        store.get('token'),
        saveParsedDataResponse?.data?.content?.well_metadata,
        afterList,
      );
    } catch (e) {
      this.setState({
        alert: {
          isOpen: true,
          message: 'Error when creating the well',
          severity: 'error'
        },
        loadingWell: false,
        showOverlay: false
      })
    }
  }

  openModalWellPhases = () => {
    this.setState((state) => ({
      ...state,
      isModalVisibleWellPhases: true,
    }));
  }

  handleCancelModalWellPhases = () => {
    this.setState((state) => ({
      ...state,
      isModalVisibleWellPhases: false,
    }));
  };

  submitInterpretedLithology = (interpreted) => {
    const matchedLithologyData = Object.keys(interpreted).reduce((acc, rockName) => {
      const abbreviationByName = interpreted[rockName];
      const abbreviationKey = rockName// alias from match curves
      if (interpreted[rockName]) {
        // access the match data and create a new set of data with Automud lithology
        acc[abbreviationByName] = this.state.interpretedLithologyFromCSV[abbreviationKey];
      }
      return acc;
    }, {});

    /* TO DO
    * access the object of interpretedLithologyFromCSV
    * convert all litho data
    *
    * {
    *   rock_carbonate_shale: "CGL"
    *   rock_chalk: "AND"
    *   rock_claystone: "AGT"
    *   rock_limestone: "CRE"
    *   rock_marl: "MSD"
    *   rock_sandstone: "HAL"
    * }
    */

    // { 
    //   rock_sandstone: [1, 25, 85]
    // }
    // Update redux



    function convertToInterpretedLithoPayloadStructure(currentWell, data) {
      const interpretedLithologySchema = {
        well_uid: currentWell.well_uid ?? currentWell.uid,
        data,
      }

      return interpretedLithologySchema;
    }

    const lithologiesPayload = convertToInterpretedLithoPayloadStructure(this.props.wells.currentWell, matchedLithologyData)
    const token = store.get('token')
    const openMatchLithologySuccessNotification = (message) => {
      notification.success({
        duration: 6,
        message,
        description: 'this lithology was succesfully saved!',
        className: 'bit-size-notification'
      });
    };

    const openMatchLithologyErrorNotification = () => {
      notification.error({
        duration: 6,
        message: 'Failed Try to Save Lithology!',
        description: 'this lithology was not succesfully saved! contact support!',
        className: 'bit-size-notification'
      });
    };

    if (lithologiesPayload) {
      parseAndSaveLithologyData(lithologiesPayload, token)
        .then(function (response) {
          const renewMetadata = response.data.content.well_metadata;
          const responseMessage = response.data.message;
          openMatchLithologySuccessNotification(responseMessage);
          this.props.wellAction.receiveMetadata(renewMetadata);
        })
        .catch(function (error) {
          console.log(error);
          openMatchLithologyErrorNotification()
        });
    }
    this.props.wellAction.updateWell({
      ...this.props.wells.currentWell,
      interpretedLithology: matchedLithologyData,
    });
    this.setState((state) => ({
      ...state,
      interpretedLithology: matchedLithologyData,
    }));

  }

  changedUnit = (unit) => {
    const metadata = this?.props?.wells?.metadata;
    const unitLasFile = metadata?.index_unit;
    const start = metadata?.start_depth;
    const stop = metadata?.stop_depth;
    const step = metadata?.step;
    this.setState({ currentUnit: unit });
    if (unitLasFile !== unit) {
      if (unitLasFile === 'm' && unit === 'ft') {
        this.props.wellAction.updateMetadata({
          ...metadata,
          index_unit: unit,
          start_depth: start * 3.28084,
          stop_depth: stop * 3.28084,
        });
      } else if (unitLasFile === 'ft' && unit === 'm') {
        this.props.wellAction.updateMetadata({
          ...metadata,
          index_unit: unit,
          start_depth: Math.round(start * 0.3048),
          stop_depth: Math.round(stop * 0.3048),
        });
      }
    }
  }

  render() {
    const {
      min,
      maxDepth,
      selectedMin,
      selectedMax,
      unit,
      processing,
      data,
      modalIsOpen,
      curves,
      current,
      loadingWell,
      step,
      curvesKeysGeopress,
      curvesKeys,
      errors,
      top,
      bottom,
      geopressIsOpen,
      rangeTop,
      rangeBottom,
      hasConflictData,
      hasNewWell,
      lasFile,
      showImportLas,
      openImportData,
      loadingListWell,
      metadata,
      wellDataIntegration,
      isOpenConfigImport,
      alert,
      isOpenLithologyError,
      isOpenCustomizeCharts
    } = this.state;

    const depthKey =
      this.props.wells &&
      this.props.wells.currentWell &&
      this.props.wells.currentWell.depth;

    const { wellOptions, selectedWell, currentWell } = this.props.wells;
    const curvesOptions = curves.map(opt => ({
      value: opt,
      label: opt.toUpperCase()
    }));

    //const loadingListWells = !wellOptions.length > 0 && this.setState(false);
    const { Step } = Steps;
    const thereIsASelectedWell = selectedWell && selectedWell !== -1;

    const wellName =
      this.state.newWellName ||
      get(currentWell, 'well', get(this.state, 'currentWell.well', ''));

    const sensorOptions = [{ value: '', label: '-' }, ...curvesOptions];
    const geopressureSensorOptions = [
      { value: '', label: '-' },
      ...curvesOptions
    ];

    this.steps = [
      {
        title: (
          <div data-cy="create-well-step-title">
            {this.state.conflictingType === UPDATE
              ? 'Update well'
              : 'Create a well'}
          </div>
        ),
        content: () => {
          return (
            <div className="home__modal-container">
              <div className="home__modal-form-group">
                <label htmlFor="wellName">Well</label>
                <Input
                  value={
                    this.state.newWellName ? this.state.newWellName : wellName
                  }
                  className="well-name-input"
                  type="text"
                  id="wellName"
                  placeholder="Well name"
                  defaultValue={wellName}
                  onChange={this.changeWellName}
                  data-cy="well-name-input"
                />
                <div className="well-name-error">
                  {errors.wellName && errors.wellName}
                </div>
              </div>
              <SelectReferenceCurve
                className="label-reference-depth"
                value={curvesKeys.depth}
                classContainer="relative-curve"
                classLabel="label-curve"
                onChange={this.selectMnemnonics('depth')}
                label="Reference Depth"
                id="depth"
                options={sensorOptions}
              />
              <div
                style={{
                  display: 'flex',
                  marginTop: '20px'
                }}
                className="home__modal-form-group bit-size__form-group"
                data-cy="top-bottom-slider"
              >
                <label htmlFor="top" className="label-top-bottom">
                  Top - Bottom
                </label>
                <Slider
                  ref={ref => {
                    this.sliderRef = ref;
                  }}
                  defaultValue={
                    rangeTop ? [rangeTop, rangeBottom] : [top, bottom]
                  }
                  tooltipVisible={showImportLas}
                  range
                  tipFormatter={value => value.toFixed(2)}
                  step={1}
                  min={top}
                  max={bottom}
                  onAfterChange={this.changeWellRanges}
                />
              </div>
              <div className="home__modal-form-group">
                <label htmlFor="version">Version</label>
                <Input
                  id="version"
                  className="input-version"
                  defaultValue={get(this.state, 'currentWell.version')}
                  readOnly
                />
              </div>
            </div>
          );
        }
      },
      {
        title: 'Well Phases',
        content: (
          _,
          isProcessing,
          current,
          __,
          goPrev,
          closeWell,
          steps,
          initialBitSize
        ) => {
          return (
            <div className="home__modal-container">
              <div className="wrapper-header-modal">
                <h1 className="well-phase__title" data-cy="well-phases-title">
                  Well Phases
                </h1>
                <div className="bit-size__description">
                  <span className="bit-size__title">
                    Copy the values of bit size and then enter in the Top and
                    Bottom inputs:
                  </span>
                  <BitSizeClipboard
                    rangeTop={rangeTop}
                    rangeBottom={rangeBottom}
                    bottom={bottom}
                    top={top}
                  />
                </div>
              </div>
              <BitSizeForm
                onSubmit={this.submitBitSize(true)}
                current={current}
                processing={isProcessing}
                goPrev={goPrev}
                closeWell={closeWell}
                steps={steps}
                initialBitSize={[]}
              />
            </div>
          );
        }
      },
      {
        title: 'Match Curves',
        content: (curvesKeys, isProcessing) => {
          return (
            <div>
              <div className="home__modal-content">
                {Object.keys(defaultMnemonics).map((field, index) => {
                  return (
                    <>
                      <SelectMnemonic
                        key={index}
                        value={curvesKeys[field]}
                        label="Select a mnemonic"
                        className={field}
                        onChange={this.selectMnemnonics(field)}
                        label={field.replace('_', ' ').toUpperCase()}
                        id={field}
                        options={sensorOptions}
                      />
                      {this.state.errorCurves[field] && (
                        <span
                          className="error-curves"
                          data-cy={`${field}-error`}
                        >
                          {this.state.errorCurves[field]}
                        </span>
                      )}
                    </>
                  );
                })}
              </div>
              {isProcessing && (
                <p>Processing, creating and saving the well...</p>
              )}
              {isProcessing && (
                <div className="charts-container__spinner">
                  <Spinner />
                </div>
              )}
            </div>
          );
        }
      }
    ];

    // Current well on Redux
    const { wells } = this.props;

    const defaultWell = { lithology: [], depth: [] };

    const depth = get(wells, 'currentWell.index_curve.data', []);
    const totalGas = get(wells, 'currentWell.total_gas', []);
    const c1 = get(getCurveData('c1', currentWell, 'RAW'), 'data', []);
    const c2 = get(getCurveData('c2', currentWell, 'RAW'), 'data', []);
    const c3 = get(getCurveData('c3', currentWell, 'RAW'), 'data', []);
    const ic4Normal = get(getCurveData('ic4', currentWell, 'RAW'), 'data', []);
    const ic5Normal = get(getCurveData('ic5', currentWell, 'RAW'), 'data', []);
    const nc4 = get(getCurveData('nc4', currentWell, 'RAW'), 'data', []);
    const nc5 = get(getCurveData('nc5', currentWell, 'RAW'), 'data', []);

    const ic4 = get(wells, 'currentWell.ic4', []);
    const ic5 = get(wells, 'currentWell.ic5', []);
    const gammaRay = get(getCurveData('gamma_ray', currentWell, 'RAW'), 'data', []);
    const aiGammaRayData = get(getCurveData('gamma_ray', currentWell, 'ml'), 'data', []);
    const cutOffs = {};
    const wobData = get(getCurveData('wob', currentWell, 'RAW'), 'data', []);
    const ropData = get(getCurveData('rop', currentWell, 'RAW'), 'data', []);



    const realtimeLithologyAutomudData = get(wells, 'currentWell.realtimeLithology', []);
    const totalCarbon = get(wells, 'currentWell.totalCarbon', []);
    const c1Normalized = get(wells, 'currentWell.c1Normalized', []);

    const ExportFileOptions = ({ handleMenuClick }) => (
      <Menu onClick={handleMenuClick} className="menu-export-file">
        <Menu.Item key="csv" className="menu-item-export-file">
          CSV File
        </Menu.Item>
        <Menu.Item key="las" className="menu-item-export-file">
          LAS File
        </Menu.Item>
      </Menu>
    );

    const MachineLearningOptions = ({ handleMenuClick }) => (
      <Menu onClick={handleMenuClick} className="menu-export-file">
        <Menu.Item key="predict" className="menu-item-export-file">
          Predict
        </Menu.Item>
        {/* <Menu.Item key="experiments" className="menu-item-export-file">
          Experiments
        </Menu.Item> */}
        <Menu.Item key="train-modal" className="menu-item-export-file">
          Train Model
        </Menu.Item>
      </Menu>
    );

    const maxDepthHoleDepthCard =
      depth && depth.length > 0 ? Number(depth.slice(-1)).toFixed(2) : 'N/A';

    const createWellLabel = this.state.conflictingType !== UPDATE
      && this.state.GWITSML ? 'Import Data' : 'Create a well';

    const currentCreateWell =
      this.state.conflictingType === CREATE && !wells.currentWell
        ? { ...this.state.currentWell, bitSize: null }
        : {
          ...wells.currentWell,
          bitSize: get(this.state, 'currentWell.bitSize', null)
        };

    const mwData = get(wells, 'currentWell.geopressure.mw', []);
    const geopressureDepth = get(wells, 'currentWell.geopressure.depth', []);
    const obg = get(wells, 'currentWell.geopressure.obg', []);
    const ecd = get(wells, 'currentWell.geopressure.ecd', []);
    const dxc = get(wells, 'currentWell.geopressure.dxc', []);
    const ppGradient = get(wells, 'currentWell.geopressure.porePressure', []);
    const fgGradient = get(
      wells,
      'currentWell.geopressure.fractureGradient',
      []
    );
    const dxcNormal = get(wells, 'currentWell.geopressure.dxcNormal', []);
    const trendLines = get(wells, 'trendLines', null);

    const currentWellCard =
      this.props.wells &&
      this.props.wells.currentWell &&
      this.props.wells.currentWell.data &&
      this.props.wells.currentWell.data.slice && this.props.wells.currentWell.slice(-1)[0] || {
        lag_time: 0,
        wob: 0,
        rpm: 0,
        ecd: 0,
        ecdmud_density_in: 0,
        tvd: 0,
        lag_depth: 0,
        rop_min_m: 0,
        depth: 0,
        rop: undefined,
      };
    const lagTimeValue = ((currentWellCard && currentWellCard.lag_time) / 60).toFixed(2);
    const lagTime = lagTimeValue && lagTimeValue > 0 ? lagTimeValue : 'N/A';
    const wob = (currentWellCard && currentWellCard.wob && currentWellCard.wob.toFixed(2)) || 'N/A';
    const rop = (currentWellCard && currentWellCard.rop && currentWellCard.rop.toFixed(2)) || 'N/A';
    const ecdCard = (currentWellCard && currentWellCard.ecd && currentWellCard.ecd.toFixed(2)) || 'N/A';
    const mudDensityIn =
      (currentWellCard && currentWellCard.ecdmud_density_in && currentWellCard.ecdmud_density_in.toFixed(2)) || 'N/A';
    const tvd = (currentWellCard && currentWellCard.tvd && currentWellCard.tvd.toFixed(2)) || 'N/A';
    const lagDepth = (currentWellCard && currentWellCard.lag_depth && currentWellCard.lag_depth.toFixed(2)) || 'N/A';

    const dataDepth = [];
    const depthApi = [];
    // wells.currentWell &&
    // wells.currentWell.data &&
    // wells.currentWell.data.map && wells.currentWell.data.map(data => dataDepth.push(data.depth));

    const dataWob = wobData;
    const wobApi = [];
    // wells.currentWell &&
    // wells.currentWell.data &&
    // wells.currentWell.data.map(data => dataWob.push(data.wob));

    const dataRop = ropData;
    const ropApi = [];
    // wells.currentWell &&
    // wells.currentWell.data &&
    // wells.currentWell.data.map(data => dataRop.push(data.rop_min_m));

    const mseData = get(wells, 'currentWell.mse', []);

    const wellData = {
      depth: get(wells, 'currentWell.depth', []),
      wob: wobData,
      rop: ropData,
      mse: mseData
    };

    this.depth = get(wells, 'currentWell.depth', []);
    this.dataWob = wobData;
    this.dataRop = ropData;
    this.mseData = mseData;

    const rpmData = get(wells, 'currentWell.rpm', []);
    const maxRpm = rpmData && rpmData.length > 0
      ? rpmData.slice(-1) : 'N/A';
    const maxWob = wobData && wobData.length > 0
      ? wobData.slice(-1) : 'N/A';

    const currentWellAutomud = wells.currentWell;
    const lithologyAutomud =
      currentWellAutomud && currentWellAutomud.lithologyAutomud
        ? currentWellAutomud.lithologyAutomud
        : [];

    /**
     * The data are necessary for Uncertainty Estimation chart.
     * The structure of the data need to be:
     * meanScoreData = [index, value]
     * standardDeviation = [index, low, high]
     */
    const { meanScoreData, standardDeviation, deviation } =
      lithologyAutomud &&
      lithologyAutomud.reduce(
        (acc, currentDepth, index) => {
          const electedLithology = currentDepth.lithology;
          const deviation = currentDepth.stats[electedLithology].deviation;
          const meanScore =
            [index, Number(currentDepth.stats[electedLithology].score) * 100];
          const standardDevInf = Number(meanScore[1]) - (Number(deviation) * 100);
          const standardDevSup = Number(meanScore[1]) + (Number(deviation) * 100);
          const standardDeviation = [index, standardDevInf, standardDevSup];

          acc.meanScoreData.push(meanScore);
          acc.standardDeviation.push(standardDeviation);
          acc.deviation.push(deviation);

          return acc;
        },
        {
          meanScoreData: [],
          standardDeviation: [],
          deviation: []
        }
      );

    this.meanScoreData = meanScoreData;
    this.standardDeviation = standardDeviation;
    this.deviation = deviation;

    const seriesData = {
      SHALE: [],
      GYPSUM: [],
      HALITE: [],
      DIABASE: [],
      SILEXITE: [],
      ANHYDRITE: [],
      SANDSTONE: [],
      SILTSTONE: [],
      CALCARENITE: [],
      CALCILUTITE: [],
      METASEDIMENT: []
    };
    const lithoScoresToCsv = {
      SHALE: [],
      GYPSUM: [],
      HALITE: [],
      DIABASE: [],
      SILEXITE: [],
      ANHYDRITE: [],
      SANDSTONE: [],
      SILTSTONE: [],
      CALCARENITE: [],
      CALCILUTITE: [],
      METASEDIMENT: []
    };

    lithologyAutomud.map(item => {
      return Object.keys(item.stats).map(name => {
        Object.keys(seriesData).includes(name) &&
          seriesData[name].push(item.stats[name].score * 100);
        Object.keys(seriesData).includes(name) &&
          lithoScoresToCsv[name].push(Number(item.stats[name].score));
      });
    });

    this.seriesData = seriesData;
    this.lithoScoresToCsv = lithoScoresToCsv;

    return (
      <div className="home">
        <SidebarNavigation openCustomizeCharts={this.openCustomizeCharts} />
        <Overlay show={this.state.showOverlay} />
        <ConfigModal />
        <WellPhasesModal
          isOpen={this.state.isModalVisibleWellPhases}
          visible={this.state.isModalVisibleWellPhases}
          onCloseModal={this.handleCancelModalWellPhases}
          rangeTop={rangeTop}
          rangeBottom={rangeBottom}
          bottom={bottom}
          top={top}
          submitBitSize={this.submitBitSize(true, 'train_model')}
          current={current}
          loading={this.state.loading}
          loadingWellPhases={this.state.loadingWellPhases}
          goPrev={this.goPrev}
          closeWell={this.closeWell}
          steps={this.steps}
          initialBitSize={[]}
          currentWell={currentCreateWell}
          removeWellPhase={this.removeWellPhases}
          wellphases={this.props.wellphases}
        />
        <LithologyMatchCurves
          onAfterSubmit={this.submitInterpretedLithology}
          onCloseModal={this.onCloseLithologyMatchCurves}
          isOpen={this.state.isOpenLithologyMatchCurves}

          fileRocksNames={this.state.fileRocksNames}
          fielRocksKeys={this.state.fielRocksKeys}

          aiInterpretedLithologyNames={this.state.aiInterpretedLithologyNames}
          aiIntrepretedLithologyKeys={this.state.aiIntrepretedLithologyKeys}
        />
        <ExperimentsModal
          openModalWellPhases={this.openModalWellPhases}
          closeModalWellPhases={this.handleCancelModalWellPhases}
          isOpen={this.state.IsOpenModalMarchine}
          onClose={this.handleCancelMarchineLearning}
          openTrainModel={() => {
            this.setState({ isOpenTrainModelModal: true })
          }}
        />
        <TrainModelModal
          openModalWellPhases={this.openModalWellPhases}
          closeModalWellPhases={this.handleCancelModalWellPhases}
          isOpen={this.state.isOpenTrainModelModal}
          onClose={this.handleCancelTrainModel}
        />
        <EditLithologyModal
          minDepth={this.state.selectedDepthMin}
          maxDepth={this.state.selectedDepthMax}
          isOpen={this.state.IsOpenEditLithologyModal}
          onCloseModal={this.handleCancelEditLithology}
          lithologyType={this.state.lithologyType}
          currentWell={currentWell}
          filterProp={this.state.curveChangeFilterProp}
        />
        {/* <ModalModels 
          isOpen={this.state.IsOpenModalMarchine} 
          onClose={this.handleCancelMarchineLearning}
        /> */}
        <GeopressionsModal
          isOpen={geopressIsOpen}
          closeModal={this.closeModalGeopress}
          current={current}
          closeWell={this.closeModalGeopress}
          wellName={wellName}
          curvesKeys={this.state.geopressureCurveKeys}
          processing={processing}
          submitBitSize={this.submitBitSizeGeopress}
          goPrevBitSizes={this.goPrevBitSizes}
          currentWell={currentCreateWell}
          prev={this.prev}
          next={this.nextGeopress}
          startProcess={this.startProcessGeopressure}
          rangeTop={rangeTop}
          rangeBottom={rangeBottom}
          top={top}
          bottom={bottom}
          selectMnemnonics={this.selectMnemnonicsGeopressure}
          errorCurves={this.state.geopressureErrors}
          onChangeOverburden={this.onChangeOverburden}
          activeOverburden={this.state.activeOverburden}
          refactorConstants={this.refactorConstants}
          sensorOptions={geopressureSensorOptions}
        />
        <Modal isOpen={modalIsOpen} style={modalStyleCreateWell}>
          <div className="home__modal">
            {hasConflictData ? (
              <ConflictData
                wellName={wellName}
                closeWell={this.closeWell}
                stepModal={this.stepModal}
              />
            ) : (
              <Tabs
                defaultActiveKey="1"
                className={classNames('tabs-create-well', {
                  'tabs-create-well--import': !!showImportLas,
                  'tabs-create-well--preview': !showImportLas
                })}
                onChange={this.changeTab}
              >
                <TabPane tab="Import LAS" key="1">
                  <CreateWell
                    current={current}
                    steps={this.steps}
                    closeWell={this.closeWell}
                    curvesKeys={curvesKeys}
                    processing={processing}
                    submitBitSize={this.submitBitSize(true)}
                    goPrevBitSizes={this.goPrevBitSizes}
                    wellphases={this.props.wellphases}
                    currentWell={currentCreateWell}
                    prev={this.prev}
                    next={this.next}
                    startProcess={this.startProcess}
                  />
                </TabPane>
                {/* <TabPane tab="LAS Preview" key="2">
                  <LasPreview
                    splitLas={this.splitLas}
                    lasFile={lasFile}
                    closeWell={this.closeWell}
                  />
                </TabPane> */}
              </Tabs>
            )}
          </div>
        </Modal>
        <GeoCopilotDrawer
          visible={this.state.geocopilot}
          minDepth={this.state.selectedDepthMin}
          maxDepth={this.state.selectedDepthMax}
          OnClose={this.handleDisableGeocopilot}
          currentWell={currentWell}
        />
        <Importdata
          // TODO listing wells
          wellOptions={[
            { text: '3SPS-lab1', label: '3SPS-lab1' }
          ]}
          onSubmit={this.handleSubmitImportData}
          isOpen={isOpenConfigImport}
          lasInfo={lasFile}
          defaultData={this.props.wells.metadata}
          isLoading={this.state.loadingWell}
          onClose={this.handleCloseDrawer}
          changedUnit={this.changedUnit}
        />
        <LithologyErrorModal
          isOpen={isOpenLithologyError}
          handleOk={this.handleOkLithologyError}
          handleCancel={this.handleCloseLithologyError}
        />
        <GeneralSettings
          isOpen={isOpenCustomizeCharts}
          handleCancel={this.closeCustomizeCharts}
        />
        {wells.calculatingGeopressure && (
          <ProcessingSpinner spinnerText="Calculating Dxc…" />
        )}
        <div className="home__content">
          <header className="home__header">
            <div className="home__header-name" data-cy="home__header-name" style={{ cursor: "pointer" }} onClick={this.onBackHome}>
              Wellex Automud
            </div>
            <Box display="flex" justifyContent="space-around" ml="1rem" alignItems="center">
              {/* <div style={{ display: 'flex', marginRight: 20 }}> */}
              {/*
                <span className="wrapper-live-update" style={{ color: '#fff' }}>Live Update</span>
                  <ToggleButton
                    colors={{
                      activeThumb: {
                        base: 'rgb(255,255,255)'
                      },
                      inactiveThumb: {
                        base: 'rgb(255,255,255)'
                      },
                      active: {
                        base: '#65c970',
                        hover: '#65c970'
                      },
                      inactive: {
                        base: 'rgb(65,66,68)',
                        hover: 'rgb(95,96,98)'
                      }
                    }}
                    value={this.state.isActivaisActivatedPolling}
                    onToggle={this.toggleActivePolling}
                  />*/}
              {/* </div> */}
              {this.state.loadingWell ? (
                <div
                  className="loading__spinner"
                  style={{ marginRight: '4px', marginTop: '4px' }}
                >
                  <Spinner classes="spinner--small" />
                </div>
              ) : null}
              {loadingListWell && (
                <div
                  className="loading__spinner"
                  style={{ marginRight: '4px', marginTop: '4px' }}
                >
                  <Spinner classes="spinner--small" />
                </div>
              )}
              <div className="title-well-select-xs" style={{ display: 'flex' }}>
                {wellOptions.length && !loadingListWell ? (
                  <span className="home__header-select-label title-select-well" style={{ color: '#fff' }}>WELL</span>
                ) : (
                  ''
                )}
                <Select
                  id="home__header-select-well"
                  className="home__header-select-well wrapper-select-well"
                  style={{
                    width: 260,
                    border: 'none',
                    color: '#fff',
                    marginRight: selectedWell !== -1 ? '0px' : '8px'
                  }}
                  onChange={this.selectWell}
                  value={selectedWell}
                  data-cy="select-well"
                  dropdownClassName="home__header-select-well-dropdown"
                  disabled={wellOptions <= 0 && true}
                >
                  <Select.Option className="options-well" value={-1}>
                    Select a well
                  </Select.Option>
                  {wellOptions &&
                    wellOptions.map(option => (
                      <Select.Option
                        className="options-well"
                        value={option.value}
                        data-cy="option-select-well"
                      >
                        {option.label}
                      </Select.Option>
                    ))}
                </Select>
                {selectedWell !== -1 && (
                  <Popconfirm
                    placement="bottom"
                    title="Are you sure you want to delete this well?"
                    onConfirm={() => {
                      this.removeWell();
                      message.success('Deleted successfully!', 5);
                    }}
                    okText="Yes"
                    cancelText="No"
                    overlayClassName="pop-remove-well"
                  >
                    <Button
                      className="btn-remove-well"
                      title="Remove Well"
                      data-cy="remove-well"
                    >
                      <div
                        style={{
                          width: 30,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: 30,
                        }}
                      >
                        <img
                          src={removeIcon}
                          alt="remove"
                          style={{ width: '16px', height: '16px' }}
                        />
                      </div>
                    </Button>
                  </Popconfirm>
                )}
              </div>
              <input
                id="lasFile"
                type="file"
                data-cy="upload-las"
                accept=".las, .txt, .csv"
                onChange={this.onChangeUpload}
                style={{ display: 'none' }}
                ref={this.uploadRef}
              />
              <input
                id="lithoFile"
                type="file"
                data-cy="upload-litho"
                accept=".las, .txt, .csv"
                onChange={this.onChangeLithoUpload(this)}
                style={{ display: 'none' }}
                ref={this.uploadLithoRef}
              />
              <button
                type="button"
                className="btn-upload"
                style={{ backgroundColor: '#303030' }}
                onClick={this.changeInWellModal}
                title="Import Data"
              >
                <img src={folderIcon} style={{ marginRight: 8 }} />
                Import Data
              </button>
              <DataSourceModal
                isOpen={this.state.modalInWellIsVisible}
                changeInWellModal={this.onChangeUploadInWell}
                uploadLithoFile={this.uploadLithoFile}
                uploadFile={this.uploadFile}
                changeInWellTableModal={() => {
                  this.closeWell();
                  this.changeInWellModal();
                  this.changeInWellTableModal();
                }}
                onCloseModal={() => {
                  this.closeWell();
                  this.setState({
                    modalInWellIsVisible: false,
                    isInWellTableIsVisible: false,
                  })
                }}
              />
              {/* <SelectModalInWell
                isOpen={this.state.isInWellTableIsVisible}
                changeInWellModal={this.changeInWellModal}
                onCloseModal={() => {
                  this.closeWell();
                  this.setState({
                    isInWellTableIsVisible: false,
                  });
                }}
                onCloseSteps={() => {
                  this.closeWell();
                  this.changeInWellModal();
                  this.changeInWellTableModal();
                }}
                nextAction={this.importNextInWell}
                calculating={this.state.calculating}
              />
               */}
              <SelectModalInWell
                isOpen={this.state.isInWellTableIsVisible}
                changeInWellModal={this.changeInWellModal}
                onCloseModal={() => {
                  this.closeWell();
                  this.setState({
                    isInWellTableIsVisible: false,
                  })
                }}
                onCloseSteps={() => {
                  this.closeWell();
                  this.changeInWellModal();
                  this.changeInWellTableModal();
                }}
                nextAction={this.importNextInWell}
                loading={this.state.calculating}
                calculating={this.state.calculating}
              />
              {/* <button
                type="button"
                className="btn-upload"
                disabled={loadingWell}
                style={{ backgroundColor: '#303030', opacity: 1 }}
                onClick={this.openHydraulicsModule}
                title="Hydraulics"
              >
                <img width={22} src={HydraulicsIcon} />
                <span style={{ marginLeft: 5, marginBottom: 2.5 }}>Hydraulics</span>
              </button> */}
              <Dropdown
                name="field1"
                overlay={<MachineLearningOptions handleMenuClick={this.openModalMarchineLearning} />}
                className="btn-machine-learning"
              >
                <Button>
                  <img width={16} style={{ marginRight: 6, top: 6, position: 'absolute', left: 10 }} src={MachineLearningIcon} />
                  <span style={{ marginLeft: 15 }}>Machine Learning</span>
                </Button>
              </Dropdown>
              <button onClick={this.exportWell} key="las" className="btn-upload" style={{ backgroundColor: '#303030' }}>
                <DownloadOutlined />
                <span style={{ marginLeft: 10 }}>Export LAS</span>
              </button>
              {/* <Button
                className="open-geopress-config"
                onClick={this.uploadFileGeopressures}
              >
                <SettingOutlined /> Geopressures
              </Button> */}
              <input
                id="lasFile"
                type="file"
                data-cy="upload-las"
                accept=".las, .txt, .csv"
                onChange={this.onChangeUploadGeopressure}
                style={{ display: 'none' }}
                ref={this.uploadGeopressureRef}
              />
              <button
                type="button"
                className="btn-upload"
                disabled={loadingWell}
                style={{ backgroundColor: '#303030', opacity: loadingWell ? 0.5 : 1 }}
                onClick={this.refreshData}
                title="Refresh Data"
              >
                <RedoOutlined style={{ color: '#fff', marginRight: 8, }} />
                Refresh Data
              </button>
              {/* <button
                  type="button"
                  className="btn-upload"
                  disabled={loadingWell}
                  style={this.state.isGeocopilotRunning ?
                    { backgroundColor: '#26BDF2', border: '1px solid transparent', color: '#333', opacity: 1 } :
                    { backgroundColor: '#303030', opacity: 1 }}
                  onClick={this.handleEnablingGeocopilot}
                  title="GeoCopilot"
                >
                  <span>GeoCopilot</span>
                </button> */}
                <RealtimeSwitch notificationFunction={warningNotification}/>
              <EditLithologyButton />
              <div>
                {/* <div className="cross-plot-toggle">
                  <span>Enable DXC Trend</span>
                  <ToggleButton
                    colors={{
                      activeThumb: {
                        base: 'rgb(255,255,255)'
                      },
                      inactiveThumb: {
                        base: 'rgb(255,255,255)'
                      },
                      active: {
                        base: '#65c970',
                        hover: '#65c970'
                      },
                      inactive: {
                        base: 'rgb(65,66,68)',
                        hover: 'rgb(95,96,98)'
                      }
                    }}
                    value={this.state.isActivated}
                    onToggle={this.toggleActive}
                  />
                </div> */}
              </div>
            </Box>
          </header>
          <FullScreen>
            <div className="home__main-content">
              {this.state.hydraulics ?
                <Hydraulics onBackHome={() => {
                  this.setState({
                    hydraulics: false,
                  });
                }}
                  afterSelect={this.propagateTotalDetphZoom}
                  unit={unit}
                  min={min}
                  max={maxDepth}
                  depth={depth}
                  selectedWell={selectedWell}
                  onAfter={this.props.wellAction.registerDepthData}
                  lithoAutomudData={realtimeLithologyAutomudData}
                /> : (
                  this.state.isOpenExperiments ?
                    <>
                      <ListExperiments onBackHome={() => {
                        this.setState({
                          isOpenExperiments: false,
                        });
                      }} />
                    </>
                    : (<>

                      <div className="home__big-cards">
                        <BigCard
                          description="Hole Depth"
                          unit=""
                          value={maxDepthHoleDepthCard}
                          lastCard="big-card__last-card-left"
                        />
                        <BigCard description="Bit Depth" unit="" value={undefined} />
                        <BigCard description="TVD" unit="" value={tvd} />
                        <RopBigCard description="ROP" unit="" selectedWell={selectedWell} />
                        <WobBigCard description="WOB" unit="" selectedWell={selectedWell} />
                        <BigCard description="Lag Time" unit="min" value={lagTime} />
                        <BigCard description="Lag Strokes" unit="stk" value={undefined} />
                        <BigCard
                          description="Lag Depth"
                          unit="m"
                          value={lagDepth}
                          lastCard="big-card__last-card-right"
                        />
                      </div>
                      {/* <div className="home__cards">
                <SmallIndicatorsCards
                  wob={maxWob}
                  rpm={maxRpm}
                  ecd={ecdCard}
                  mudDensityIn={mudDensityIn}
                />
              </div> */}
                      <div className="home__charts">
                        <ZoomButton
                          min={selectedMin}
                          max={selectedMax}
                          depth={depth}
                          geopressureDepth={geopressureDepth}
                        />
                        <div className="charts-wrapper">
                          <div ref={this.chartContainerRef} className="charts-container">
                            <CurveChangeModal
                              isOpen={this.state.IsOpenConfigCurveModal}
                              onCloseModal={this.handleCancelConfigCurve}
                              currentWell={currentWell}
                              containerRef={this.chartContainerRef}
                              filterBy={this.state.curveChangeFilterProp}
                              title={this.state.curveChangeTitle}
                            />
                            {
                              <>
                                <TotalDepth
                                  data={depth ?? []}
                                  afterSelect={this.propagateTotalDetphZoom}
                                  unit={unit}
                                  min={min}
                                  max={maxDepth}
                                  selectedWell={selectedWell}
                                  onAfter={this.props.wellAction.registerDepthData}
                                />
                                <SelectedDepth
                                  min={min}
                                  max={maxDepth}
                                  data={depth}
                                  depth={depth}
                                  unit={unit}
                                  selectedWell={selectedWell}
                                  beforeWell={wells.beforeWell || defaultWell}
                                  currentWell={wells.currentWell || defaultWell}
                                  enableGeoCopilot={this.EnableGeoCopilot}
                                />
                                {this.props.wells.chartsView.mse && <Mse
                                  depth={depth ? depth : []}
                                  min={selectedMin}
                                  max={selectedMax}
                                  mse={mseData}
                                  wob={wobData}
                                  rop={ropData}
                                  selectedWell={selectedWell}
                                  cutOffs={cutOffs}
                                  beforeWell={wells.beforeWell || defaultWell}
                                  currentWell={wells.currentWell || defaultWell}
                                  wells={wells}
                                  wellData={wellData}
                                />}
                                {this.props.wells.chartsView.gr && <GammaRay
                                  depth={depth ? depth : []}
                                  min={selectedMin}
                                  max={selectedMax}
                                  gammaRay={gammaRay}
                                  aiGammaRay={aiGammaRayData}
                                  selectedWell={selectedWell}
                                  cutOffs={cutOffs}
                                  wells={wells}
                                  wellData={wellData}
                                  beforeWell={wells.beforeWell || defaultWell}
                                  openConfigCurveModal={this.openConfigCurveModal}
                                  filterBy={'gamma_ray'}

                                />}
                                {this.props.wells.chartsView.porosity && <MLPorosity
                                  selectedWell={selectedWell}
                                />}
                                {this.props.wells.chartsView.cuttings && <Cuttings
                                  beforeWell={wells.beforeWell || defaultWell}
                                  currentWell={wells.currentWell || defaultWell}
                                  lithoAutomudData={wells.currentWell ? wells.currentWell : []}
                                  selectedWell={selectedWell}
                                  depth={depth}
                                />}
                                {this.props.wells.chartsView.interpreted && <InterpretedLithology
                                  openEditLithologyModal={this.openEditLithologyModal}
                                  beforeWell={wells.beforeWell || defaultWell}
                                  currentWell={wells.currentWell || defaultWell}
                                  lithoAutomudData={wells.currentWell && wells.currentWell?.lithology?.lithology.data}
                                  matchedLithologyData={wells?.currentWell && wells.currentWell?.interpretedLithology}
                                  selectedWell={selectedWell}
                                  depth={depth}
                                />}
                                {this.props.wells.chartsView.realtime && <RealTimeLithology
                                  openEditLithologyModal={this.openEditLithologyModal}
                                  beforeWell={wells.beforeWell || defaultWell}
                                  currentWell={wells.currentWell || defaultWell}
                                  //matchedLithologyData={this.state.currentWell && this.state.currentWell.matchedLithologyData}
                                  lithoAutomudData={realtimeLithologyAutomudData}
                                  selectedWell={selectedWell}
                                  depth={depth}
                                  filterBy={'lithology'}
                                  isGeocopilotOn={this.state.isGeocopilotRunning}
                                  openConfigCurveModal={this.openConfigCurveModal}
                                />}
                                {this.props.wells.chartsView.scores && <LithoScore
                                  beforeWell={wells.beforeWell || defaultWell}
                                  currentWell={wells.currentWell || defaultWell}
                                  lithoAutomudData={wells.currentWell && wells.currentWell.lithologyScore}
                                  selectedWell={selectedWell}
                                  depth={depth}
                                  openEditLithologyModal={this.openEditLithologyModal}
                                  openConfigCurveModal={this.openConfigCurveModal}
                                  filterBy={'lithology_prob'}
                                />}
                                {/* <TotalGas
                                    min={selectedMin}
                                    max={selectedMax}
                                    totalGasData={totalGas}
                                    totalCarbon={totalCarbon}
                                    c1Normalized={c1Normalized}
                                    selectedWell={selectedWell}
                                    cutOffs={cutOffs}
                                    beforeWell={wells.beforeWell || defaultWell}
                                    currentWell={wells.currentWell || defaultWell}
                                  /> */}
                                {this.props.wells.chartsView.chromatography && <Chromatography
                                  min={selectedMin}
                                  max={selectedMax}
                                  depth={depth ? depth : []}
                                  c1={c1}
                                  c2={c2}
                                  c3={c3}
                                  ic4={ic4Normal}
                                  nc4={nc4}
                                  ic5={ic5Normal}
                                  nc5={nc5}
                                  selectedWell={selectedWell}
                                  cutOffs={cutOffs}
                                  beforeWell={wells.beforeWell || defaultWell}
                                  currentWell={wells.currentWell || defaultWell}
                                />}
                                {/* <LithologyScores
                            min={selectedMin}
                            max={selectedMax}
                            selectedWell={selectedWell}
                            cutOffs={cutOffs}
                            beforeWell={wells.beforeWell || defaultWell}
                            currentWell={wells.currentWell || defaultWell}
                            lithologyScore={wells.currentWell && wells.currentWell.lithologyScore}
                            depth={depth}
                          /> */}
                                {/* 
                          <Lithology
                            beforeWell={wells.beforeWell || defaultWell}
                            currentWell={wells.currentWell || defaultWell}
                            lithologyData={lithologyData}
                            selectedWell={selectedWell}
                            depth={depth}
                          />
                          <LithologyScores
                            min={selectedMin}
                            max={selectedMax}
                            data={{
                              rock_shale: rockBrecciaData,
                              rock_marl: rockMarlData
                            }}
                            selectedWell={selectedWell}
                            cutOffs={cutOffs}
                            beforeWell={wells.beforeWell || defaultWell}
                            currentWell={wells.currentWell || defaultWell}
                            shale={seriesData['SHALE'] || []}
                            lithologyScoresData={seriesData || {}}
                            gypsum={seriesData['GYPSUM'] || []}
                            halite={seriesData['HALITE'] || []}
                            diabase={seriesData['DIABASE'] || []}
                            silexite={seriesData['SILEXITE'] || []}
                            anhydrite={seriesData['ANHYDRITE'] || []}
                            sandstone={seriesData['SANDSTONE'] || []}
                            siltstone={seriesData['SILTSTONE'] || []}
                            calcarenite={seriesData['CALCARENITE'] || []}
                            calcilutite={seriesData['CALCILUTITE'] || []}
                            metasediment={seriesData['METASEDIMENT'] || []}
                            lithologyScoresData={seriesData || {}}
                            lithologyAutomud={lithologyAutomud || []}
                            depth={depth}
                          />
                          <UncertaintyEstimation
                            selectedWell={selectedWell}
                            standardData={wetnessRatioData || []}
                            meanScoreData={meanScoreData || []}
                            standardDeviation={standardDeviation || []}
                            deviation={deviation || []}
                            beforeWell={wells.beforeWell || defaultWell}
                            currentWell={wells.currentWell || defaultWell}
                            cutOffs={cutOffs}
                            depth={depth}
                          /> */}
                                {/* <Dxc
                            selectedWell={selectedWell}
                            depth={geopressureDepth}
                            dxc={dxc}
                            dxcNormal={dxcNormal}
                            min={selectedMin}
                            max={selectedMax}
                            beforeWell={wells.beforeWell || defaultWell}
                            currentWell={wells.currentWell || defaultWell}
                            cutOffs={cutOffs}
                            isActivated={this.state.isActivated}
                            actionTrendLines={
                              this.props.wellAction.receiveTrendLinesValue
                            }
                            stopCalculateGeopressure={
                              this.props.wellAction.stopCalculateGeopressure
                            }
                            startCalculateGeopressure={
                              this.props.wellAction.startCalculateGeopressure
                            }
                            calculatingGeopressure={wells.calculatingGeopressure}
                            data={{
                              obg,
                              mw: mwData,
                              ppGradient,
                              ecd,
                              fgGradient,
                              rft1: [],
                              rft2: []
                            }}
                            trendLines={trendLines}
                            currentWell={wells.currentWell || defaultWell}
                            updateWell={this.props.wellAction.updateWell}
                            well={wells}
                          />
                          <GeopressureChart
                            min={selectedMin}
                            max={selectedMax}
                            depth={geopressureDepth}
                            stopCalculateGeopressure={
                              this.props.wellAction.stopCalculateGeopressure
                            }
                            startCalculateGeopressure={
                              this.props.wellAction.startCalculateGeopressure
                            }
                            calculatingGeopressure={wells.calculatingGeopressure}
                            data={{
                              obg,
                              mw: mwData,
                              ppGradient,
                              ecd,
                              fgGradient,
                              rft1: [],
                              rft2: []
                            }}
                            selectedWell={selectedWell}
                            cutOffs={cutOffs}
                            trendLines={trendLines}
                            beforeWell={wells.beforeWell || defaultWell}
                            currentWell={wells.currentWell || defaultWell}
                            updateWell={this.props.wellAction.updateWell}
                            well={wells}
                          /> */}
                              </>
                            }
                          </div>
                        </div>
                      </div>
                    </>
                    ))}
            </div>
            {/* <PlotBox
              selectedWell={data || null}
              well={wells.selectedWell}
              curvesKeys={curvesKeys}
              data={data}
              sensorType={wells.currentWell ? wells.currentWell.sensorType : 0}
            />
            <PlotBoxEthene
              selectedWell={data || null}
              well={wells.selectedWell}
              curvesKeys={curvesKeys}
              data={data}
            /> */}
          </FullScreen>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ zone, wells, experiments, models }, state) => ({
  zone,
  wells,
  state,
  experiments,
  models
});

const mapDispatchToProps = dispatch => ({
  zoneAction: bindActionCreators(zoneActions, dispatch),
  wellAction: bindActionCreators(wellActions, dispatch),
  experimentAction: bindActionCreators(experimentActions, dispatch),
  modelsAction: bindActionCreators(modelsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Home))
