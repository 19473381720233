import React, { Component } from 'react';

import ChartContainer from 'components/Shared/ChartContainer';
import Highcharts from 'highcharts';
import {
  maxCurveLithology,
  realTimeLithoDataFormatter,
  syncronizeTooltip,
  handleSelectArea,
  displayZoomBtn,
  propagateZoom
} from 'utils';

import store from 'store';
import { getCuttingsLithology } from 'utils/lithology';
import ChartHeader from './ChartHeader';
import {
  createDefaultConfig,
  defaultSeries,
  generateSerie
} from './config';
import { lithologyReference } from '../../../utils/constants';



class Cuttings extends Component {
  constructor(props) {
    super(props);
    this.chart = null;

    this.state = {
      data: [],
      cuttings: undefined,

    };
  }

  componentDidMount() {
    this.createRealTimeLithoChart([], []);
  }

  shouldComponentUpdate(nextProps) {
    const { selectedWell, currentWell } = nextProps;
    if (
      selectedWell !== this.props.selectedWell ||
      currentWell !== this.props.currentWell ||
      nextProps.currentWell.lithology && !this.state.lithology
    ) {
      this.setState({ cuttings: currentWell.cuttings, lithology: currentWell.lithology });

      const currentDepth =
        nextProps.currentWell && nextProps.currentWell.index_curve?.data
          ? nextProps.currentWell.index_curve?.data : [];
      const data = nextProps.currentWell?.cuttings?.cuttings?.data || {};
      function getOrderByRockName(rock_name) {
        const rock = lithologyReference.find(r => r.rock_name === rock_name);
        return rock ? (lithologyReference.length - rock.order) : null; // Return the order or null if not found
      }
      const series = Object.keys(data).map((key, index) => (
        generateSerie(key, data[key], getOrderByRockName(key))));

      const { depth } = this.props;
      let min = depth && depth.slice(0) ? depth.slice(0)[0] : 0;
      let max = depth && depth.slice(-1) ? depth.slice(-1)[0] : 0;

      this.chart.destroy();
      this.createRealTimeLithoChart(data, series);
      this.zoomId = setTimeout(() => {
        this.chart.xAxis[0].setExtremes(min, max);
      }, 300);
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    this.zoomId && clearTimeout(this.zoomId);
  }

  selectAreas = event => {
    event.preventDefault();
    const axis = event.xAxis[0];


    const { customEventType, currentWell } = this.props;
    // When option to edit lithology is selected
    // we need to select only the area get the depth and show editLithologyModal
    displayZoomBtn();
    propagateZoom(event);
  };

  createRealTimeLithoChart = (data, series) => {
    const { id } = this.props;

    let defaultPlaceholderData = [];

    if (Object.keys(data).length) {
      defaultPlaceholderData = maxCurveLithology(data);
    }

    // This map will generate each serie that we are passing data via props
    // NOTE: This will generate data just relyng from the serie that we are receiveing
    // const series = Object.keys(data).map(
    //   (key, index) => generateSerie(key, data[key], index + 1)
    // );
    series.push(defaultSeries(defaultPlaceholderData));
    const config = createDefaultConfig(this.props, series);
    this.chart = Highcharts.chart(id, {
      ...config,
      chart: {
        ...config.chart,
        events: {
          selection: this.selectAreas
        },
      }
    });

    document.addEventListener('sincronize-selected', event => {
      const {
        detail: { min, max }
      } = event;

      const { depth } = this.props;
      let minValue = depth[min];
      let maxValue = depth[max];

      this.chart.xAxis[0].setExtremes(minValue, maxValue, true, true);
      this.chart.redraw();
    });

    syncronizeTooltip(`#${this.props.id}`);
  }

  render() {
    const { id, currentWell, title, depth } = this.props;
    return (
      <ChartContainer>
        <ChartHeader lithoData={currentWell.cuttings || []} title={title} depth={depth} />
        <div
          id={id}
          style={{
            marginLeft: '10px',
            height: 'calc(100% - 128px)'
          }}
          data-cy="chart-cuttings"
        />
      </ChartContainer>
    );
  }
}

Cuttings.defaultProps = {
  width: 150,
  type: 'area',
  bgColor: '#fff',
  inverted: true,
  animation: false,
  customProps: {},
  id: 'cuttings',
  title: <span>Cuttings (%)</span>
};

export default Cuttings;
