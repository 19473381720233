import '../../style.scss';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    successNotification,
    errorNotification,
    realTimeLithoDataFormatter
} from 'utils';

import classNames from 'classnames';

import get from 'lodash.get';
import { Button, Switch, Slider, Popconfirm } from 'antd';
import SelectCorrelation from './SelectCorrelation';
import Fieldset from '../../../Hydraulics/Fieldset';
import Input from "../../../Hydraulics/Input";


import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';

import { formattedDataToHaveSameLength, validateFormSubmit, validateIsString, validateLength, validateSchema, addMatchingSchema } from "./schema";
import useModelsContext from "../../../../hooks/useModels";
import { SendPredictPayload, deleteCurrentModel } from './resources';
import { predict } from '../../../../api/models';
import models from '../../../../reducers/models';

function SettingsForm({ onCancel, openModalWellPhases, modelName, clear, tags, loading, setLoading, inputCurves, modelUid, status, ...props }) {
    const [settingsDetails, setSettingsDetails] = useState();
    const [mnemonics, setMnemonics] = useState({});
    const [selectedLogSet, setSelectedLogSet] = useState({});
    const {curveMapping, curvesNames, isValidCurve, outputCurve } = useModelsContext();
    const [outputCurveField, setOutputCurveField] = useState(outputCurve);
    const wells = useSelector(state => state.wells) || {};
    const defaultCurrentWell = get(wells, 'currentWell');
    const dispatch = useDispatch();
    const inputSchema = Object.keys(curveMapping)
    const inputCurvesNames = Object.keys(inputCurves);
    const inputLogSets = Object.keys(defaultCurrentWell.log_sets);

    const extractMnemonics = (logSets) => {
        return Object.keys(logSets).reduce((acc, key) => {
            const curves = logSets[key].curves || [];
            acc[key] = curves.map(curve => curve.mnemonic);
            return acc;
        }, {});
    };

    useEffect(() => {
        if (wells && wells.currentWell && wells.currentWell.log_sets) {
            const mnemonics = extractMnemonics(wells.currentWell.log_sets);
            setMnemonics(mnemonics);
        }
    }, [wells]);

    useEffect(() => {
        reset();
    }, [clear])

    const handleLogSetChange = (logSet, schemaKey, index) => {
        setSelectedLogSet(prevState => ({
            ...prevState,
            [`${schemaKey}_${index}`]: logSet
        }));
    };

    const InitialSettingsParameters = curveMapping

    const methods = useForm({
        defaultValues: {
            ...InitialSettingsParameters,
        },
    });

    const initialData = [];
    inputCurvesNames && inputCurvesNames.forEach((schemaKey) => {
        if (inputCurves[schemaKey].length > 0) {
            return inputCurves[schemaKey].map((item) => {
                initialData.push({
                    log_set_name_input_schema: schemaKey,
                    curve_mnemonic_input_schema: item.mnemonic
                })
            });
        }
    });

    const { register, handleSubmit, watch, setValue, getValues, control, reset, formState: { errors, isSubmitSuccessful } } = methods;

    async function updateParameters(data) {
        setLoading(true);
        const updatedData = addMatchingSchema(initialData, data);
        const schema = {
            well_uid: defaultCurrentWell.well_uid,
            input_schema_matching: {
                match_curves: updatedData,
            },
            output_curve_mnemonic: data.output_curve_mnemonic,
        }
        await predict(modelUid, schema).
            then(response => {
                ;
                props.displayDriftMessage(response.data.message);
                props.displaySuccessModel();
                setLoading(false);
            })

        /*setSettingsDetails(data)
        const inputSchema = Object.keys(data);
        const payload = validateFormSubmit(wells, data)
        const formatPayload = formattedDataToHaveSameLength(payload,data, isValidCurve);
        const isValid = validateSchema(formatPayload, inputSchema);
        const isValidLength = validateLength(formatPayload, inputSchema);
        

        if (isValid && isValidLength) {
            setLoading(true);
            await SendPredictPayload(
            tags,
            modelName,
            formatPayload, 
            props.displayDriftMessage, 
            props.displaySuccessModel,
            dispatch,
            setLoading)
        } else {
            if (!isValid) {
                errorNotification('Please fill all the required curves for this model.');
            }
            else if (!isValidLength) {
                errorNotification('All curves must have the same length. Please check your data.');
            }
        }*/
    }

    const onChangeOutputCurve = (e) => {
        setOutputCurveField(e.target.value);
        setValue('output_curve_mnemonic', e.target.value);
    }

    useEffect(() => {
        outputCurve !== undefined && setOutputCurveField(outputCurve);
    }, [outputCurve])

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(updateParameters)}>
                    <Fieldset>
                        {inputCurvesNames && inputCurvesNames.map((schemaKey) => {
                            const labelText = schemaKey.replaceAll('_', ' ').toUpperCase();
                            const handleChange = (mnemonic) => { console.log(mnemonic); };           
                            return inputCurves[schemaKey].map((item, index) => {
                              const defaultLogSet = inputLogSets.find(logSet => logSet === labelText);
                              return (
                                  <div key={`${schemaKey}_${index}_${item.mnemonic}`} style={{ marginBottom: 10 }}>
                                    <label style={{ color: '#fff' }}>{labelText}: {item.mnemonic}</label><br />
                                      <div key={`${schemaKey}_${index}_${item.mnemonic}`} style={{ display: 'flex', marginTop: 10, gap: 16 }}>
                                        <SelectCorrelation
                                            name={`logset_${index}_${labelText}`}
                                            key={`logset_${index}_${labelText}`}
                                            control={control}
                                            errors={errors}
                                            curvesNames={inputLogSets}
                                            handleChange={(logSet) => handleLogSetChange(logSet, schemaKey, index)}
                                            defaultValue={defaultLogSet || null}
                                        /> 
                                        <SelectCorrelation
                                            name={`mnemonic_${index}_${labelText}`}
                                            key={`mnemonic_${index}_${labelText}`}
                                            control={control}
                                            errors={errors}
                                            curvesNames={mnemonics[getValues(`logset_${index}`)] ?? mnemonics[defaultLogSet]}
                                            handleChange={handleChange}
                                            defaultValue={mnemonics[defaultLogSet].find(mnemonic => mnemonic.includes(item.mnemonic)) ?? null}
                                        />
                                        </div>
                                    </div>
                                );
                            });
                        })}
                    </Fieldset>
                    {outputCurve && (
                        <Fieldset>
                        <div className="output_curve_input" >
                            <Input
                                label={'OUTPUT CURVE MNEMONIC'}
                                name={`output_curve_mnemonic`}
                                value={outputCurveField}  
                                control={control}
                                onChange={onChangeOutputCurve}
                            />
                        </div>
                    </Fieldset>)}
                    <div style={{ display: 'flex', width: '100%', gap: '16px' }}>
                        {status == 'Failed' ? null :
                            (<>
                                <Button
                                    type="primary"
                                    style={{ background: '#1E97C2', color: '#1D1C22' }}
                                    htmlType={'submit'}
                                >
                                    <span>Run</span>
                                </Button>
                                <Button
                                    type="outline"
                                    className="btn-upload"
                                    htmlType={'button'}
                                    style={{ backgroundColor: 'rgb(48, 48, 48)', opacity: 1, color: '#fff' }}
                                    onClick={onCancel}
                                >
                                    Cancel
                                </Button></>)}
                        <Popconfirm
                            title="Are you sure you want to proceed?"
                            onConfirm={() => deleteCurrentModel(modelUid)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="danger">Delete</Button>
                        </Popconfirm>
                    </div>
                </form>
            </FormProvider>
        </>
    )
}
export default SettingsForm;
