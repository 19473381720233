import React, { useEffect, useState, createContext, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import store from 'store';

import { listExperiments } from 'actions/experiments';

import { filterMetadataByCurve, filterCommonMnemonics } from '../api/mlaas';
import { successNotification } from '../utils';
import { message } from 'antd';


const initialState = {
  isLoading: false,
  setIsLoading: () => undefined,
}

export const initialAlternativeTrainingData = {
  "name": "",
  "description": "",
  "dataset_uid": "",
  "task_type": "Regressor",
  "pipeline_config": {
    "scaling": {
      "scaling_type": "Standard"
    },
    "imputation": {
      "imputation_type": "Constant",
      "imputation_value": 0
    }
  },
  "estimator_config": {
    "framework": "xgboost",
    "task_type": "Regressor",
    "hyp_tuning_mode": 'basic'
  },
  "submit_job": true
}
export const initialTrainingData = {
  "tuning_method": "Basic",
  "experiment_name": "",
  "experiment_description": "",
  "target_column": "LOG #1: gamma_ray",
  "curve_type": "gamma_ray",
  "model_type": "Regressor",
  "task": "regression",
  "data": {},
  "data_scaler": null,
  "data_provider": "Inwell",
  "train_columns": {},

}

export const ExperimentContext = createContext(initialState)

export const ExperimentProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingWells, setLoadingWells] = useState(false);
  const [trainingData, setTrainingData] = useState(initialAlternativeTrainingData)
  const [description, setDescription] = useState('')
  const [wells, setWells] = useState([])
  const [curve, setCurve] = useState(null)
  const [schema, setSchema] = useState(null)
  const [mountSchema, setMountSchema] = useState(null);
  const [dataset, setDataset] = useState(null)
  const [selectedCurves, setSelectedCurves] = useState([])
  const [availableLogs, setAvailableLogs] = useState({
    "logs": [],
    "currentLog": "",
    "currentLogCurves": []
  });
  const [trainColumns, setTrainColumns] = useState()
  const [selectedWells, setSelectedWells] = useState([])
  const [isValid, setIsValid] = useState(false)
  const [hyperparameterTuning, setHyperparameterTuning] = useState('basic')
  const [estimatorConfigData, setEstimatorConfigData] = useState(initialAlternativeTrainingData.estimator_config)
  const [dataImputation, setDataImputation] = useState({ 'imputation_type': 'Constant', 'imputation_value': 0 })
  const [scalingType, setScalingType] = useState('Standard')
  const [mnemonicsOptions, setMnemonicOptions] = useState([])
  const [outputLog, setOutputLog] = useState('')
  const [outputCurve, setOutputCurve] = useState('')
  const experiments = useSelector((state) => state.experiments)
  const well = useSelector((state) => state.wells)
  const [wellsInfoList, setWellsInfoList] = useState([])

  const token = store.get('token')

  const dispatch = useDispatch()

  useEffect(() => {
    console.log('Training Data:', trainingData)
  }, [trainingData])


  const handleModelOutput = () => {
    setLoadingWells(true);
    try {
      const project_uid = well.currentWell.project_uid
      filterMetadataByCurve(project_uid, outputLog, outputCurve)
        .then((response) => {
          const { well_metadata: wells } = response.data || []
          setLoadingWells(false);
          setWells(wells)
        })
    }
    catch (error) {
      console.log('error', error)
    }
  }

  const handleTrainingModel = (field, value) => {
    if (field === 'target_column') {
      const newTrainingData = {
        ['task_type']: value.label === 'Interpreted Lithology' ? 'Classifier' : 'Regressor',
      }

      setTrainingData({ ...trainingData, ...newTrainingData })
      setEstimatorConfigData({ ...estimatorConfigData, ...newTrainingData })
    } else {
      setTrainingData({ ...trainingData, [field]: value.label })
    }

    if (field === 'target_column') {
      handleModelOutput(value)
    }
  }

  const handleChange = (field, e) => {

    if (field === 'target_column') {
      const newTrainingData = {
        ['target_column']: value.label === 'Gamma Ray' ? 'LOG #1: gamma_ray' : 'interpreted_lithology',
        ['curve_type']: value.label.toLowerCase().replace(/\s/g, '_'),
        ['task_type']: value.label === 'Interpreted Lithology' ? 'Classifier' : 'Regressor',
        ['task']: value.label === 'Interpreted Lithology' ? 'classification' : 'regression'
      }
      setTrainingData({ ...trainingData, ...newTrainingData })
    } else {
      setTrainingData({ ...trainingData, [field]: e })
    }

    if (field === 'target_column') {
      handleModelOutput(value)
    }
  }

  useEffect(() => {
    setTrainColumns(selectedCurves)
  }, [selectedCurves])

  const handleDescription = (item) => {
    setDescription(item)
    setTrainingData({ ...trainingData, ['description']: item.target.value })
  }

  const handleSelectedWells = (selectedWell) => {
    const hasSelectedWell = selectedWells.find((selected) => selected.uid === selectedWell.id)
    const selectedWellParsed = wells.find((well) => well.uid === selectedWell.id)

    if (hasSelectedWell) {
      setSelectedWells(prevSelectedWells => prevSelectedWells.filter((selected) => selected.uid !== selectedWell.id))
    } else {
      setSelectedWells(prevSelectedWells => [...prevSelectedWells, selectedWellParsed])
    }
  };

  const getAvailableLogs = (schema) => {
    const availableLogs = [];
    Object.keys(schema?.input_curves || {}).forEach(key => {
      console.log('key', key);
      const LogSet = { id: key, label: key };
      availableLogs.push(LogSet)
    });
    return availableLogs;
  }

  const getCurvesFromLogSet = (log_set) => {
    const availableCurves = [];
    log_set.map(curve => {
      const curveItem = { id: curve.mnemonic, label: curve.mnemonic };
      availableCurves.push(curveItem)
    });
    return availableCurves;
  }

  const handleConfirm = async () => {
    const payload = {
      "well_metadata_list": selectedWells,
      "output_curve_mnemonic": outputCurve,
      "output_curve_log_set_name": outputLog
    }
    const { data } = await filterCommonMnemonics(payload, token)

    const schema = data.schema;
    setSchema(schema);
    const availableLogSets = getAvailableLogs(schema);

    const firstLog = availableLogSets[0].label
    setAvailableLogs({
      "logs": availableLogSets,
      "currentLog": firstLog,
      "currentLogCurves": getCurvesFromLogSet(schema.input_curves[firstLog])
    })

    setTrainColumns(availableLogSets);
  }

  const handleChangeLogName = (logName) => {
    setAvailableLogs({
      ...availableLogs,
      "currentLog": logName.label,
      "currentLogCurves": getCurvesFromLogSet(schema.input_curves[logName.id])
    })
  }

  const addSelectedCurve = (field, value) => {
    setSelectedCurves((prevSelectedCurves) => {
      const updatedCurves = { ...prevSelectedCurves };

      if (updatedCurves.hasOwnProperty(field) && Array.isArray(updatedCurves[field])) {
        updatedCurves[field] = [...updatedCurves[field], value];
      } else {

        updatedCurves[field] = [value];
      }
      return updatedCurves;
    });
  };

  const removeSelectedCurve = ({ id }) => {
    const splittedSelectedCurve = id.split(': ')
    const field = splittedSelectedCurve[0]
    const value = splittedSelectedCurve[1]

    setSelectedCurves({ ...selectedCurves, [field]: selectedCurves[field].filter(curve => curve !== value) })
  }

  const buildDataset = (inputSchema) => {
    if (schema) {
      const schema_definition = { output_curve: schema.output_curve, input_curves: {} };
      Object.keys(schema.input_curves).forEach((set) => {
        schema_definition.input_curves[set] = schema.input_curves[set].reduce((acc, curve) => {
          const trainColumn = inputSchema[set]
          if (trainColumn?.includes(curve.mnemonic)) {
            acc.push(curve);
          }
          return acc;
        }, [])
      })
      const payload = {
        "well_info_list": wellsInfoList,
        schema_definition,
      }
      setMountSchema(payload)

    }
  }

  useEffect(() => {
    if (dataset) {
      setTrainingData({ ...trainingData, ['dataset_uid']: dataset.uid })
    }
  }, [dataset])

  const parseShowCurves = () => {
    const availableCurves = Object.keys(selectedCurves).filter((logName) => selectedCurves[logName].length)
    const parsedData = availableCurves
      .map((logName) => {
        return selectedCurves[logName]
          .map((curve) => ({
            id: `${logName}: ${curve}`,
            field: `${logName}: ${curve}`
          }))
      }).flat()

    return parsedData
  }

  useEffect(() => {
    setTrainingData({ ...trainingData, pipeline_config: { ['scaling']: { 'scaling_type': scalingType } } })
  }, [scalingType])

  const handleScalingType = (item) => {
    console.log('handle scalying type', scalingType)
    setScalingType(item)
  }

  useEffect(() => {
    setTrainingData({ ...trainingData, ['imputation']: dataImputation })
  }, [dataImputation])



  const handleImputationType = (item) => {
    setDataImputation({ ...dataImputation, ['imputation_type']: item })
  }

  const handleImputationValue = (item) => {
    setDataImputation({ ...dataImputation, ['imputation_value']: item })
  }

  useEffect(() => {
    if (hyperparameterTuning === '') {
      setEstimatorConfigData({ ...estimatorConfigData, ['hyp_tuning_mode']: 'basic' })
      setTrainingData({ ...trainingData, estimator_config: { ...estimatorConfigData } })
    }
    else {
      setEstimatorConfigData({ ...estimatorConfigData, ['hyp_tuning_mode']: hyperparameterTuning.toLowerCase() });
      setTrainingData({ ...trainingData, estimator_config: { ...estimatorConfigData } })
    }
  }, [hyperparameterTuning])

  const handleHyperparameterTuning = (item) => {
    console.log('handle Hyperparameter Tuning', hyperparameterTuning)
    setHyperparameterTuning(item.toLowerCase())
    setEstimatorConfigData({ ...estimatorConfigData, ['hyp_tuning_mode']: hyperparameterTuning })
    setTrainingData({ ...trainingData, estimator_config: { ...estimatorConfigData } })
  }

  const handleLogSetSelection = (item) => {
    setOutputLog(item)
    const mnemonics = well.currentWell.log_sets[item].curves.map((curve, index) => ({ id: index, label: curve.mnemonic }))
    setMnemonicOptions(mnemonics)
  }

  const handleOutputCurveSelection = (value) => {
    if (typeof value === 'object') {
      setOutputCurve(value.label)
    } else {
      setOutputCurve(value)
    }
  }

  useEffect(() => {
    if (outputLog && outputCurve) {
      handleModelOutput()
    }
  }, [outputCurve])

  useEffect(() => {
    setWellsInfoList(() => {
      const selectedUids = new Set(selectedWells.map(well => well.uid));
  
      const updatedWellsInfoList = selectedWells.map(well => ({
        uid: well.uid,
        name: well.name,
        start_depth: well.start_depth,
        end_depth: well.stop_depth,
      }));
  
      return updatedWellsInfoList;
    });
  }, [selectedWells]);
  
  return (
    <ExperimentContext.Provider value={{
      isLoading,
      isValid,
      experiments: experiments.items,
      well,
      wells,
      curve,
      curves: parseShowCurves(),
      trainingData,
      description,
      hyperparameterTuning,
      availableLogs,
      selectedWells,
      loadingWells,
      mnemonicsOptions,
      outputLog,
      outputCurve,
      handleChangeLogName,
      selectedCurves,
      setSelectedCurves,
      parseShowCurves,
      setIsValid,
      setCurve,
      setDataset,
      dataset,
      mountSchema,
      trainColumns,
      setAvailableLogs,
      buildDataset,
      setScalingType,
      handleTrainingModel,
      handleChange,
      handleDescription,
      handleConfirm,
      handleSelectedWells,
      addSelectedCurve,
      removeSelectedCurve,
      handleHyperparameterTuning,
      handleScalingType,
      handleImputationType,
      handleImputationValue,
      handleLogSetSelection,
      handleOutputCurveSelection
    }}>
      {children}
    </ExperimentContext.Provider>
  )
}

const useExperimentsContext = () => {
  const context = useContext(ExperimentContext)

  if (context === undefined) {
    throw new Error('useExperiment must be used within a ExperimentProvider')
  }

  return context
}

export default useExperimentsContext