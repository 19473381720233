import React, {
  memo,
  useEffect,
  useState
} from 'react';

import { useSelector } from 'react-redux';
import store from 'store';
import { filterData } from '../config';
import {
  nFormatter,
  toggleAiGrCurve,
  toggleGrCurve
} from 'utils';
import { getCurveData } from '../../../../utils/getCurveData';
import { DEFAULT_SCALES } from 'utils/constants';

import ConfigTriggerButton from '../../CurveChangeModal/ConfigTriggerButton';

const updatedHeader = {
  scales: store.get('configScales') || { ...DEFAULT_SCALES },
  aiGrPrediction: 0,
  gammaRay: 0
};

const initialHeader = {
  aiGrPrediction: 0,
  gammaRay: 0
};

const AI_GR_PREDICTION = 'aiGrPrediction';
const GAMMA_RAY = 'gammaRay';

const ChartHeader = ({ openCurveChangeModal, filterBy , ...props}) => {
  const wells = useSelector(state => state.wells);

  // --- Intital State
  const [headerState, setHeaderState] = useState({
    ...initialHeader,
    scales: store.get('configScales') || { ...DEFAULT_SCALES }
  });

  // --- Toggle Tracks
  const [tracks, setToggle] = useState({
    isOpen: false,
    aiGrPrediction: true,
    gammaRay: true
  });

  const tooggleCallbacks = {
    aiGrPrediction: toggleAiGrCurve,
    gammaRay: toggleGrCurve
  };

  const toggleTrackCurrentTrack = event => {
    const {
      dataset: { name, index }
    } = event.target;
    const trackName = tracks[name];
    const callback = tooggleCallbacks[name];
    if (callback) {
      callback(parseInt(index))(event);
      setToggle({
        ...tracks,
        isOpen: !tracks.isOpen,
        [name]: !trackName
      });
    }
  };

  const dispatchModal = typeOfTitle => event => {
    event.persist();
    event.stopPropagation();
    if (event.target && event.target.dataset.cmd === 'toggle') {
      toggleTrackCurrentTrack(event);
      return;
    } else if (event.target && event.target.dataset.cmd === 'openConfig') {
      const openModal = new CustomEvent('openConfigModal', {
        detail: {
          type: typeOfTitle
        }
      });
      document.dispatchEvent(openModal);
      return;
    }
  };

  useEffect(() => {
    if (wells.currentWell && wells.selectedDepthIndex >= 0) {
      const index = wells.selectedDepthIndex || 0;

      const aiGrValue = wells.currentWell
        && wells.currentWell.aiGammaRay
        ? wells.currentWell.aiGammaRay[index] : 0;

      const gammaRayData = wells.currentWell && getCurveData('gr', wells.currentWell, 'RAW')
      const gammaRayValue = 0;

      //const gammaRayValue = gammaRayData ? gammaRayData?.data[index] : 0;

      setHeaderState({
        ...headerState,
        aiGrPrediction: aiGrValue > 0 ? aiGrValue.toFixed(2) : aiGrValue,
        gammaRay: gammaRayValue > 0 ? gammaRayValue.toFixed(2) : gammaRayValue,
      });
    }
  }, [wells]);

  useEffect(() => {
    // Scales listener
    const gammaRayConfigListener = ({ detail }) => {
      updatedHeader.scales.gammaRay = detail;
      headerState.scales.gammaRay = detail;

      setHeaderState({
        ...headerState,
        ...updatedHeader
      });
    };
    document.addEventListener(GAMMA_RAY, gammaRayConfigListener);

    const aiGrConfigListener = ({ detail }) => {
      updatedHeader.scales.aiGrPrediction = detail;
      headerState.scales.aiGrPrediction = detail;
      setHeaderState({
        ...headerState,
        ...updatedHeader
      });
    };
    document.addEventListener(AI_GR_PREDICTION, aiGrConfigListener);

    // ---- Remove listeners
    return () => {
      document.removeEventListener(GAMMA_RAY, gammaRayConfigListener);
      document.removeEventListener(AI_GR_PREDICTION, aiGrConfigListener);
    };
  }, []);

  const { aiGr, gammaRay, scales } = headerState;

  // useEffect(() => {
  //   if (wells.currentWell && wells.selectedDepthIndex >= 0) {
  //     const index = wells.selectedDepthIndex || 0;

  //     const aiGRScale = scales && scales.aiGrPrediction ? scales.aiGrPrediction : DEFAULT_SCALES.aiGrPrediction;
  //     const aiGammaRayScale = scales && scales.gammaRay ? scales.gammaRay : DEFAULT_SCALES.gammaRay;
  //     const filteredAiGammaRayData = wells.currentWell
  //       && wells.currentWell.aiGammaRay ? filterData(aiGRScale, wells.currentWell.aiGammaRay) : null;

  //     const filteredGammaRayData = wells.currentWell
  //       && wells.currentWell.RAW ? filterData(aiGammaRayScale, getCurveData('gr', wells.currentWell, 'RAW').data) : null;

  //     const aiGrValue = filteredAiGammaRayData ? filteredAiGammaRayData[index] : 0;

  //     const gammaRayValue = filteredGammaRayData ? filteredGammaRayData[index] : 0;
  //     console.log('gammaRayValue =>', gammaRayValue)
  //     setHeaderState({
  //       ...headerState,
  //       aiGrPrediction: aiGrValue > 0 ? aiGrValue.toFixed(2) : aiGrValue,
  //       gammaRay: gammaRayValue > 0 ? gammaRayValue.toFixed(2) : gammaRayValue,
  //     });
  //   }
  // }, [wells]);

  const gammaRayColor = scales && scales.gammaRay ? scales.gammaRay.color : DEFAULT_SCALES.gammaRay.color;
  const aiGrPredictionColor = scales && scales.aiGrPrediction ? scales.aiGrPrediction.color : DEFAULT_SCALES.aiGrPrediction.color;
  const aiGRPredictionMin = scales && scales.aiGrPrediction ? scales && scales.aiGrPrediction.min : DEFAULT_SCALES.aiGrPrediction.min;
  const aiGRPredictionMax = scales && scales.aiGrPrediction ? scales && scales.aiGrPrediction.max : DEFAULT_SCALES.aiGrPrediction.max;

  console.log('log ==>', { DEFAULT_SCALES, gammaRay: scales && scales.gammaRay, scales});
  return (
    <div
      style={{
        display: 'flex',
        height: '132px',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        position: 'relative'
      }}
    >
      <ConfigTriggerButton 
        openModal={openCurveChangeModal} 
        filterBy={filterBy} 
        title="Change AI GR Predict"
        style={{ position: 'absolute', zIndex:70, bottom:70, right: 0 }}
      />
      <div
        className="total-gas-header"
        data-name="title"
        data-cmd="openConfig"
        style={{
          height: 40,
          justifyContent: 'flex-end',
          color: aiGrPredictionColor
        }}
        onClick={dispatchModal(AI_GR_PREDICTION)}
        data-cy="open-config-total-carbon"
      >
        <div
          className="total-gas-header__title"
          data-name="aiGr"
          data-cmd="openConfig"
          style={{
            color: aiGrPredictionColor
          }}
        >
          {aiGr ? `AI GR Prediction ${aiGr}` : 'AI GR Prediction'}
        </div>
        <label className="total-gas-header__checkbox container-checkbox">
          <input
            data-cmd="toggle"
            type="checkbox"
            checked={tracks.aiGr}
            data-name="aiGr"
            data-index="1"
            data-cy="checkbox-total-carbon"
          />
          <span
            className="checkmark"
            style={{ backgroundColor: aiGrPredictionColor }}
          />
        </label>
        <div
          className="total-gas-header__values"
          style={{
            borderColor: aiGrPredictionColor
          }}
        >
          <span data-cy="scales-total-carbon-min">
            {nFormatter(aiGRPredictionMin)}
          </span>
          <span data-cy="scales-total-carbon-max">
            {nFormatter(aiGRPredictionMax)}
          </span>
        </div>
      </div>
      <div
        className="total-gas-header"
        data-name="gammaRay"
        data-cmd="openConfig"
        style={{
          height: 40,
          justifyContent: 'flex-end',
          color: gammaRayColor
        }}
        onClick={dispatchModal(GAMMA_RAY)}
        data-cy="open-config-c1-normalized"
      >
        <div
          className="total-gas-header__title"
          data-name="title"
          data-cmd="openConfig"
        >
          {gammaRay ? `GR ${gammaRay}` : 'GR'}
        </div>
        <label className="total-gas-header__checkbox container-checkbox">
          <input
            data-cmd="toggle"
            type="checkbox"
            checked={tracks.gammaRay}
            data-name="gammaRay"
            data-index="2"
            data-cy="checkbox-c1-normalized"
          />
          <span
            className="checkmark"
            style={{ backgroundColor: gammaRayColor }}
          />
        </label>
        <div
          className="total-gas-header__values"
          style={{
            borderColor: gammaRayColor,
            color: gammaRayColor
          }}
        >
          <span data-cy="scales-c1-normalized-min">
            {nFormatter(scales.gammaRay.min)}
          </span>
          <span data-cy="scales-c1-normalized-max">
            {nFormatter(scales.gammaRay.max)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default memo(ChartHeader);