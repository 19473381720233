import { v4 as uuid } from 'uuid';
import map from 'lodash.map';
import * as ACTIONS from '../constants/models';
import { createReducer } from './createReducer';

const initialState = {
  items: {},
  item: null,
  metrics: {},
};

function listModels(state, action) {
  const { models } = action;

  // generate default id
  const updatedModels = Object.keys(models || {}).reduce((acc, modelKey) => {
    const currentModel = models[modelKey];
    const newKey = currentModel.name;
    acc[newKey] = {
      ...currentModel,
      id: uuid(), 
    };
    return acc;
  }, {});

  return {
    ...state,
    items: updatedModels,
  };
}

function listOneModel(state, action) {
  const { model, id } = action;  
  const updatedModels = Object.keys(state.items || {}).reduce((acc, modelKey) => {
    const currentModel = state.items[modelKey];
    if (currentModel.id === id) {
      acc[modelKey] = {
        ...currentModel,
        ...model,
      };
    } else {
      acc[modelKey] = {
        ...currentModel,
      };
    }
    return acc;
  }, {});

  return {
    ...state,
    items: updatedModels,
  };
}

function listOneMetadata(state, action) {
  const { metrics, runId } = action;
  const normalizedMetrics = map(metrics, (item => item));

  const updatedMetrics = Object.keys(state.items || {}).reduce((acc, modelKey) => {
    const currentMetadata = state.items[modelKey];
      if (currentMetadata.id === runId.id) {
        acc[modelKey] = {
          ...currentMetadata,
          ...normalizedMetrics,
        }
      } else {
        acc[modelKey] = {
          ...normalizedMetrics,
        };
      }
      return acc;
    }, {});

  return {
    ...state,
    metrics: updatedMetrics,
  };
}


export default createReducer(initialState, {
  [ACTIONS.LIST_MODELS]: listModels,
  [ACTIONS.LIST_ONE_MODEL]: listOneModel,
  [ACTIONS.LIST_ONE_METADATA]: listOneMetadata,
});
