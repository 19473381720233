import React from 'react';

import { Select } from 'antd';
import classNames from 'classnames';

import { filterOption } from '../../utils';

const { Option } = Select;

const mnemonicfilterOption = (input, option) =>
  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0

const SelectWrapper = ({ children, id, className, classLabel, label }) => (
  <div
    className={
      classNames(
        'home__modal-group',
        className && className === 'ethene' ? className='relative_ethene' : className,
        { [className]: className }
      )}
  >
    {label ? (
      <label
        htmlFor={id}
        className={
          classNames(
            'match-relative-label',
            { 'match-relative-label': classLabel }
          )
        }
      >
        {label}
      </label>
    ) : ''}
    {children}
  </div>
);

function SelectMnemonic({
  onChange, options, label, id, value, className, classLabel,
  selectWidth, disableLabel, imgsIcon
}) {
  return (
    <>
      <SelectWrapper id={id} className={className} classLabel={classLabel} label={disableLabel ? '' : label}>
        <Select
          showSearch
          id={id}
          defaultValue={value || -1}
          value={value}
          style={{ width: selectWidth || 200 }}
          placeholder=""
          optionFilterProp="children"
          className={`cy-${className}`}
          dropdownClassName="select-dropdown-style"
          onChange={onChange}
          onFocus={() => ({})}
          onBlur={() => ({})}
          onSearch={() => ({})}
          //filterOption={(input, option) => filterOption(input, option.value)}
        >
          <Option value="-1" disabled style={{ color: '#ccc' }}>
            Select a mnemonic
          </Option>
          {options.map((option) => {
            return imgsIcon ?
              <Option key={option.label} value={option.value}>
                <span role="img" aria-label={imgsIcon[option.label]}>
                  <img src={imgsIcon[option.label]}
                    key={option.label}
                    alt={option.label}
                    style={{ width: '20px', height: '20px', margin: '4px' }}
                  />
                </span>
                {option.label.replaceAll('_', ' ').toUpperCase()}
              </Option>
              : <Option key={option.label} value={option.value}>
                {option.label}
              </Option>
          })}
        </Select>
      </SelectWrapper>
    </>
  )
};

export default SelectMnemonic;
