import styled from 'styled-components'
import { Select } from 'antd'

export const Wrapper = styled.div`
display: flex;
flex-direction: column;
position: relative;
width: 100%;
  margin-top: 10px;
  transition: all ease-in-out .25s;
  div {
    display: flex;
    width: 100%;
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}
`

export const StyledLabel = styled.label`
  margin-left: 5px;
  color: #B4B1C2;
`

export const StyledSelect = styled(Select)`
  border: none;
  & .ant-select-selection{
    border: none;
    background-color: #211F2B;
    color: #fff;
    .ant-select-arrow{
      i svg{
        color: #B4B1C2;
      }
    }
    .ant-select-selection__choice{
      border: none;
      border-radius: 5px;
      background-color: rgb(29, 28, 28);
      color: white;
      .ant-select-selection__choice__remove{
        color: white;
      }
    }
  }
` 