import * as yup from "yup";

export const InitialGeoCopilotParameters = {
  task: '',
  interpretation_rules: '',
  top: 0,
  bottom: 0,
  use_cuttings_description: false,
  selected_parameters: ['depth'],
}

export function convertToPayloadStructure(currentWell, data) {
  const payloadStructure = {
    "well_name": currentWell.wellName,
    "top": data.top,
    "base": data.bottom,
    "parameters": data.selected_parameters.map((curve) => {
      return {
          name: curve,
          unit: '',
          data: currentWell[curve].reduce((acc, value, index) => {
              if (index > data.top && index < data.bottom) {
                  acc.push(value)
              }
              return acc;
          }, [])
      }
    }),
    "use_cuttings_description": data.use_cuttings_description,
    "reasoning": data.interpretation_rules
  }
  return payloadStructure;
}

export const geoCopilotParametersSchema = yup.object({
  task: yup.string().optional(),
  interpretation_rules: yup.string().optional(),
  top: yup.number()
      .required({ message: 'this field is required!' }),
  bottom: yup.number()
      .positive({ message: 'must be greater than 0!' })
      .when('top', (top, schema) => {
          return schema.test({
              test: bottom => !!top && bottom > top,
              message: `the value must be higher than TOP!`
          })
      })
      .required({ message: 'this field is required!' }),
    'selected_parameters': yup.array(yup.string().nullable()).max(3)
}).required()
