import React, { useContext, useEffect, useState } from "react";
import { Select, Button, Switch, Slider } from 'antd';
import Fieldset from "../../Hydraulics/Fieldset";
import List from "../../Hydraulics/List";
import InputNumber from "../../Hydraulics/InputNumber";
import Input from "../../Hydraulics/Input";
import SendIcon from '../../../assets/send.png';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider, useController } from 'react-hook-form';

import { InitialGeoCopilotParameters, geoCopilotParametersSchema, convertToPayloadStructure } from "./schema";
import { StyledCheckbox } from "./styles";
import useGeoCopilotContext from "../../../hooks/useGeocopilot";

const { Option } = Select

const CuttingsDescriptionSwitch = ({ name, control }) => {
  const { field } = useController({ name, control })

  return (
    <Switch defaultChecked
      onChange={field.onChange}
      onBlur={field.onBlur}
    />
  )
}

function GeoCopilotForm({ onCancel, currentWell, ...props }) {
  const [geoCopilotDetails, setGeoCopilotDetails] = useState()
  const { isLoading, setIsLoading, curvesParameters, setPayload, wellInfo, sendGeocopilotParams, interpretations, response } = useGeoCopilotContext()

  const methods = useForm({
    defaultValues: {
      ...InitialGeoCopilotParameters,
      top: props.minDepth,
      bottom: props.maxDepth,
    },
    resolver: yupResolver(geoCopilotParametersSchema),
  });

    const { handleSubmit, watch, setValue, getValues, control, formState: { errors, isSubmitSuccessful } } = methods;

    function updateParameters(data) {
      setGeoCopilotDetails(data)
      setIsLoading(true)
    }

    function handleGeoCopilotResponse() {
      return new Promise(resolve => {
        const payloadStructure = convertToPayloadStructure(currentWell, geoCopilotDetails);
        console.log(`GeoCopilot Payload Structure`, payloadStructure);
        sendGeocopilotParams(payloadStructure);
        setPayload(payloadStructure);
        props.setSeeResults(true)
      });
    }

    const onSliderChange = (values) => {
      let top = getValues('top')
      let bottom = getValues('bottom')

      if (top > 0 && top != values[0]) {
        setValue('top', values[0])
      }
      if (bottom <= currentWell.max && bottom != values[1]) {
        setValue('bottom', values[1])
      }
    }

    useEffect(() => {
      const fetchData = async () => {
        if (geoCopilotDetails) {
          await handleGeoCopilotResponse()
        }
      };

      fetchData();
    }, [geoCopilotDetails]);

    useEffect(() => {
      if (interpretations && interpretations.length) {
        setIsLoading(false);
      }
    }, [interpretations])

    return (
        <>
          {isLoading ?
            <p style={{ marginTop: '20px' }}>Loading ...</p>
              : <FormProvider {...methods}>
                  <form onSubmit={handleSubmit(updateParameters)}>
                    <Fieldset>
                      <div style={{ display: 'flex', alignItems: 'flex-end', width: '100%', gap: '8px' }}>
                          <List
                              label={'Which task do you need assistance?'}
                              name={'task'}
                              control={control}
                              errors={errors.task} >
                              <Option key={'interpreted_lithology'} value={'interpreted_lithology'}>
                                  Interpreted Lithology
                              </Option>
                          </List>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '16px', width: '100%', gap: '8px' }}>
                          <div>
                              <CuttingsDescriptionSwitch 
                                  name={"use_cuttings_description"} 
                                  control={control}
                              />
                              <span style={{ marginLeft: '5px' }}>Use a similar cuttings description from a correlation interval</span>
                          </div>
                          <Input
                              label={'Interpretation Rules'}
                              name={'interpretation_rules'}
                              style={{ width: '100%' }}
                              textarea={true}
                              rows={4}
                              control={control}
                              errors={errors.interpretation_rules}
                          />
                      </div>
                    </Fieldset>
                    <Fieldset label={'Select Interval'} style={{ marginBottom: '30px' }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', gap: '8px' }}>
                        <div style={{ display: 'flex' }}>
                            <Slider vertical reverse range
                                min={1} max={currentWell.max}
                                onChange={onSliderChange}
                                defaultValue={[props.minDepth, props.maxDepth]}
                                value={watch(['top', 'bottom'])}
                            />
                              <div style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  width: '50%',
                                  gap: '8px'
                              }}>
                                <InputNumber
                                    label={'TOP'}
                                    name={'top'}
                                    unit={'m'}
                                    style={{ width: '100%' }}
                                    control={control}
                                    errors={errors.top}
                                />
                                <InputNumber
                                    label={'BOTTOM'}
                                    name={'bottom'}
                                    unit={'m'}
                                    style={{ width: '100%' }}
                                    control={control}
                                    errors={errors.bottom}
                                />
                              </div>
                            </div>
                            <div style={{ display: 'flex', width: '50%', gap: '8px' }}>
                              <List
                                  mode={'tags'}
                                  defaultValue={'depth'}
                                  label={'Select Parameters'}
                                  name={'selected_parameters'}
                                  control={control}
                                  options={curvesParameters}
                                  errors={errors.selected_parameters}>
                                  {curvesParameters && curvesParameters.map((value) => <Option key={value} value={value}>{value}</Option>)}
                              </List>
                            </div>
                          </div>
                        </Fieldset>
                        <div style={{ display: 'flex', width: '100%', gap: '16px' }}>
                            <Button
                              type="primary"
                              style={{ background: '#1E97C2', color: '#1D1C22' }}
                              htmlType={'submit'}
                            >
                                <img width={16} style={{ marginRight: 6, top: 6, position: 'absolute', left: 10 }} src={SendIcon} />
                                <span style={{ marginLeft: 15 }}>Ask GeoCopilot</span>
                            </Button>
                            <Button
                              type="outline"
                              className="btn-upload"
                              htmlType={'button'}
                              style={{ backgroundColor: 'rgb(48, 48, 48)', opacity: 1, color: '#fff' }}
                              onClick={onCancel}
                            >
                              Cancel
                            </Button>
                      </div>
                  </form>
            </FormProvider>}
        </>
    )
}
export default GeoCopilotForm;
