import React from 'react';
import {
    RouterProvider
} from "react-router-dom";
import { createRoot } from 'react-dom/client';
import 'antd/dist/antd.css';
import './styles/app.scss';
import 'lib/pattern-fill-v2';
// window.languagePluginUrl = 'https://cdn.jsdelivr.net/pyodide/v0.16.1/full/';

import Routes from './Routes';
import * as serviceWorker from './serviceWorker';

const root = createRoot(document.getElementById('root'));

root.render(
  <React.Fragment>
    <RouterProvider router={Routes} />
  </React.Fragment>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
