import React from 'react';
import store from 'store';

import { DEFAULT_SCALES } from 'utils/constants';
import { Login as LoginModule } from '@geowellex/shared-login';

import LogoGeowellex from 'images/logo-geowellex.svg';
import LogoWellexGold from 'images/logo-wellex-automud.svg';

import image1 from '../../images/carousel-start/img-01.svg';
import image2 from '../../images/carousel-start/img-02.svg';
import image3 from '../../images/carousel-start/img-03.svg';

const Login = () => {
  const carouselData = [
    { title: "AUTOMUD: Advanced analysis of Mud Logging in real time", image: image3 },
    { title: "Advanced models for calculating and analyzing drilling data", image: image1 },
    {
      title: "Obtain precise data about rheology and hydraulics, geopressure and interpreted lithology for your wells", 
      image: image2
    }
  ];

  const configScales = () => {
    const configScales = store.get('configScales');
    if (!configScales || configScales && !configScales.aiGrPrediction) {
      store.set('configScales', DEFAULT_SCALES);
    }
  };

  return (
    <LoginModule
      title="Welcome"
      subtitle="Log in to your account"
      logoOnCopyright={LogoGeowellex}
      logoOnForm={LogoWellexGold}
      carousel={carouselData}
      configScales={configScales}
    />
  );
};

export default Login;
