import React, { PureComponent, useState, useContext } from "react";
import Highcharts from 'highcharts'
// import HighchartsReact from 'highcharts-react-official'

export default class PiePressureChart extends PureComponent {
    constructor(props) {
        super(props);
    }

    handleChartData = (data) => {

        const keys = Object.keys(data).slice(0, -1)
        const newData = []

        keys.map((name) => {
            if (!Array.isArray(data[name])) {
                newData.push({ name, y: data[name] })
            }
        })

        return newData

    }

    options = {
        colors: ['#351E66', '#5427AD', '#7D4ED9', '#B499E8', '#8C4E0C', '#B36106', '#E0B219', '#E9E56D'],
        chart: {
            type: 'pie',
            backgroundColor: 'transparent',
            style: {
                height: '350px',
                width: '100%'
            },
        },
        credits: {
            enabled: false
        },
        legend: {
            itemStyle: {
                color: '#fff',
                "fontSize": '14px',
            },
            itemHoverStyle: {
                color: '#fff'
            }
        },
        title: {
            verticalAlign: 'middle',
            floating: true,
            style: {
                color: '#fff'
            },
            text: ''
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.0f}%</b>'
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                borderColor: null,
                dataLabels: {
                    enabled: true,
                    format: '{point.name}: {point.percentage:.1f} %',
                    color: '#fff',
                    style: {
                        fontSize: '18px',
                    }
                },
                showInLegend: false,
            }
        },
        series: [{
            name: 'Registrations',
            colorByPoint: true,
            innerSize: '75%',
            data: this.handleChartData(this.props.data)
        }]
    };
    render() {
        return (
            <div style={{ height: '100%', width: '100%' }}>
                {/* <HighchartsReact
                    containerProps={{ style: { height: "100%" } }}
                    highcharts={Highcharts}
                    options={this.options}
                /> */}
            </div>
        )
    }
}