import { Drawer } from 'antd';
import styled from 'styled-components'

export const StyledDrawer = styled(Drawer)`
  border: none;
  min-width: 300px;
  & .ant-drawer-content {        
    background-color: #1D1C22;
    color: #e8e8e8;
    & .ant-drawer-header  {   
      background-color: #1D1C22;
      border-bottom: 1px solid #313131;
      .ant-drawer-title{
          color: #e8e8e8;
      }
      .ant-drawer-close{
        color: #e8e8e8;
      }
    } 
    & .ant-drawer-body{
      padding-top: 0;
    }
  }
`;

