import React from "react";
import { Select } from 'antd';
import { useController } from "react-hook-form";

const SelectCorrelation = ({ curvesNames, handleChange, defaultValue, errors, control, name, placeholder, ...props }) => {
    const { field } = useController({ name, control, defaultValue });

    const handleEvent = (event) => {
        field.onChange(event);
        handleChange(event);
      };

    return (
        <>
            <Select
                className="select-settings"
                key={name}
                style={errors && errors[name] ? { border: '1px solid red', width: '50%' } : { width: '50%'}}
                dropdownClassName="select-dropdown-settings-models"
                value={field.value}
                onChange={handleEvent}
                onBlur={field.onBlur}
            >
                {curvesNames.map(item => (
                    <Select.Option key={item} style={{ background: '#1e1e1e' }} value={item} name={item}>
                        {item}
                    </Select.Option>
                ))}

            </Select>
        </>
    )
};

export default SelectCorrelation;