import '../style.scss';
import React, {
    useEffect,
    useState
} from 'react';
import {
    listModels,
    listOneModel,
    listRunOneMetadata
} from 'actions/models';
import { successNotification, errorNotification } from '../../../utils';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import { useFormik } from 'formik';
import get from 'lodash.get';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import { ModelsProvider, useModelsContext } from '../../../hooks/useModels';
import Text from '../../Text';
import ListModels from './ListModels';
import Loading from '../Loading';

const { Panel } = Collapse;

const RUN_ALL_EVENT = 'RUN_ALL_EVENT';

const ModelsModal = ({ onClose, openModalWellPhases, closeModalWellPhases }) => {
    const models = useSelector(state => state.models) || {};
    const wells = useSelector(state => state.wells) || {};
    const dispatch = useDispatch();

    const currentWell = get(wells, 'currentWell', null);
    const [curvesNames, setCurvesNames] = useState(null);

    const [tabActive, setActive] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingOneModel, setLoadingOneModel] = useState(false);
    const [loadingListModels, setLoadingListModels] = useState(false);
    const [modelName, setModelName] = useState('');

    useEffect(() => {
        if (!curvesNames) {
            const removedCurves = [
                'endIndex',
                'isActive',
                'isVisible',
                'logName',
                'nameWell',
                'indexUnit',
                'startIndex',
                'uid',
                'uidWell',
                'uidWellbore',
                'well_name'
            ];
            if (currentWell && currentWell.curves) {
                const well = Object.keys(currentWell.curves);
                const curvesNames = well.reduce((acc, curveKey) => {
                    if (!removedCurves.includes(curveKey)) {
                        // if (!removedCurves.includes(curveKey) && currentWell.curves[curveKey]) {
                        acc.push(curveKey);
                    }
                    return acc;
                }, []);
                setCurvesNames(curvesNames);
            }
        }
    }, [currentWell]);

    const afterSuccess = () => {
        setLoadingOneModel(false);
        setLoading(false);
        setLoadingListModels(false)
    };

    useEffect(() => {
        setLoadingListModels(true);
        dispatch(listModels(afterSuccess));
    }, []);

    const onClickPanel = (item) => {
        setActive(item)
    };

    const getOneModel = (name) => {
        if (name) {
            setModelName(name);
            dispatch(listOneModel(name, afterSuccess));
        }
    };

    useEffect(() => {
        getOneModel(tabActive);
    }, [tabActive]);

    const runId = models && models.items && models.items[modelName] && models.items[modelName].run_id;
    useEffect(() => {
        if (tabActive && runId !== undefined) {
            dispatch(listRunOneMetadata(runId, tabActive));
        }
    }, [tabActive, modelName, runId]);


    const parsedModels = Object.values(models.items).map(model => model.name);


    return (
        <ModelsProvider modelName={modelName} >
            <Text variant="title" style={{marginBottom: '20px'}}>Registered Models</Text>
            {!loadingListModels && parsedModels.length ?
                <ListModels
                    parsedModels={parsedModels}
                    openModalWellPhases={openModalWellPhases}
                    closeModalWellPhases={closeModalWellPhases}
                    onChange={onClickPanel}
                    currentPanel={tabActive}
                    loadingOneModel={loadingOneModel}
                    data={models}
                    loading={loading}
                    setLoading={setLoading}
                    modelName={modelName}
                    onClose={onClose}
                />
                : <>
                    {!loadingListModels && parsedModels.length == 0 ? 
                        (<div style={{display:'flex',justifyContent:'center', alignItems:'center',flexDirection:'column'}}>
                            <ExclamationCircleOutlined style={{fontSize: '48px'}} /> 
                            <p style={{marginTop: '8px'}}>No Models Available!</p>
                        </div>)
                        : <Loading />}
                    </>
                }
        </ModelsProvider>
    );
};

export default ModelsModal;
