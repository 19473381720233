import React, { Component, useEffect, useState } from 'react';

import ChartContainer from 'components/Shared/ChartContainer';
import Highcharts from 'highcharts';
import {
  maxCurveLithology,
  realTimeLithoDataFormatter,
  GeoCopilotLithoDataFormatter,
  syncronizeTooltip,
  handleSelectArea,
  displayZoomBtn,
  propagateZoom
} from 'utils';

import ChartHeader from './ChartHeader';
import {
  createDefaultConfig,
  defaultSeries,
  generateSerie
} from './config';
import store from 'store';
import { useSelector } from 'react-redux';
import { SelectStyled } from '../../../Models/Train/Experiment/styles';
import { ChartWithHooks } from '../ChartWithHooks';

const { Option } = SelectStyled;


class RealTimeLithology extends Component {
  constructor(props) {
    super(props);
    this.chart = null;

    this.state = {
      data: [],
      realtimeLithology: undefined,
    };

  }

  componentDidMount() {
    this.createRealTimeLithoChart([], []);
  }

  shouldComponentUpdate(nextProps) {
    const { selectedWell, currentWell, curves } = nextProps;
    if (
      currentWell &&  currentWell?.index_curve?.data?.length &&
      selectedWell !== this.props.selectedWell ||
      nextProps.currentWell.realtimeLithology && !this.state.realtimeLithology ||
      nextProps.currentWell?.ml !== this.props.currentWell?.ml ||
      nextProps.curves !== this.props.curves || nextProps.selectedCurve !== this.props.selectedCurve

    ) {
      this.setState({ realtimeLithology: this.props.selectedCurve?.data });

      const currentDepth =
        nextProps.currentWell && nextProps.currentWell && nextProps.currentWell?.index_curve
          ? nextProps.currentWell.index_curve?.data : [];
      const data = currentWell.ml?.realtimeLithology ? currentWell.ml?.realtimeLithology : (nextProps.selectedCurve?.data || {});
      const series = Object.keys(data).map((key, index) => (
        generateSerie(key, data[key], index + 1)));

      const { depth } = this.props;

      this.chart.destroy();
      this.createRealTimeLithoChart(data, series);

      this.zoomId = setTimeout(() => {
        this.chart.xAxis[0].setExtremes(this.props.min, this.props.max);
      }, 200);
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    this.zoomId && clearTimeout(this.zoomId);
  }

  selectAreas = event => {
    event.preventDefault();
    const axis = event.xAxis[0];

    const afterSelect = () => {
      const minValue = Math.floor(axis.min.toFixed());
      const maxValue = Math.floor(axis.max.toFixed());
      let minLabel = minValue;
      let maxLabel = maxValue;
      if (currentWell) {
        const depthData = currentWell.depth;
        if (depthData) {
          minLabel = depthData[minValue] ?? 0;
          maxLabel = depthData[maxValue] ?? depthData.length;
        }
        this.props.openEditLithologyModal(minValue, maxValue, 'aiPrediction');
      }
    };

    const { customEventType, currentWell } = this.props;
    // When option to edit lithology is selected
    // we need to select only the area get the depth and show editLithologyModal
    if (store.get('openEditLithology')) {
      handleSelectArea(this.chart, afterSelect, this.state.selectedId)(event);

    } else {
      displayZoomBtn();
      propagateZoom(event);
    }
  };

  createRealTimeLithoChart = (data, series) => {
    const { id } = this.props;

    let defaultPlaceholderData = [];

    if (Object.keys(data).length) {
      defaultPlaceholderData = maxCurveLithology(data);
    }

    // This map will generate each serie that we are passing data via props
    // NOTE: This will generate data just relyng from the serie that we are receiveing
    // const series = Object.keys(data).map(
    //   (key, index) => generateSerie(key, data[key], index + 1)
    // );
    series.push(defaultSeries(defaultPlaceholderData));
    const config = createDefaultConfig(this.props, series);
    this.chart = Highcharts.chart(id, {
      ...config,
      chart: {
        ...config.chart,
        events: {
          selection: this.selectAreas
        },
      }
    });

    document.addEventListener('sincronize-selected', event => {
      const {
        detail: { min, max }
      } = event;
      const { depth } = this.props;
      let minValue = depth[min];
      let maxValue = depth[max];

      this.chart.xAxis[0].setExtremes(minValue, maxValue, true, true);
      this.chart.redraw();
    });

    syncronizeTooltip(`#${this.props.id}`);
  }

  render() {
    const { id, currentWell, title, depth, curves, onSelectCurves, selectedCurve, filterBy } = this.props;

    const currentlithoData = Array.isArray(currentWell?.ml?.[selectedCurve]) ? 
        (currentWell?.ml?.[selectedCurve][0]?.data || []) : 
        (currentWell?.ml?.[selectedCurve]?.data || []);

    return (
      <ChartContainer>
        <ChartHeader 
          lithoData={currentlithoData} 
          title={title} 
          depth={depth} 
          openCurveChangeModal={this.props.openConfigCurveModal}
          filterBy={filterBy}
        />
        <div
          id={id}
          style={{
            marginLeft: '10px',
            height: 'calc(100% - 128px)'
          }}
          data-cy="chart-real-time-lithology"
        />
      </ChartContainer>
    );
  }
}

RealTimeLithology.defaultProps = {
  width: 200,
  type: 'area',
  bgColor: '#fff',
  inverted: true,
  animation: false,
  customProps: {},
  id: 'real-time-lithology',
  title: <span>AI Lithology <br /><span style={{ marginLeft: 5 }}>Prediction</span></span>
};

export default ChartWithHooks(RealTimeLithology);
