import './style.scss';

import React, {
  memo,
  useEffect,
  useRef,
  useState
} from 'react';

import ChartContainer from 'components/Shared/ChartContainer';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import {
  applyDefaultZoomToChart,
  toggleCurves
} from 'lib/charts';
import store from 'store';
import {
  defaultCrossHair,
  displayZoomBtn,
  formattingMin,
  getToolTipValues,
  propagateZoom,
  syncronizeTooltip
} from 'utils';

import ChartHeader from './ChartHeader';
import {
  createDefaultConfig,
  getDefaultSeries
} from './config';

Exporting(Highcharts);

const TOGGLE_TOTAL_GAS = 'toggle-total-gas-chart';
const TOGGLE_TOTAL_CARBON = 'toggle-total-carbon-chart';

const TOTAL_GAS_EVENT = 'totalGas';
const TOTAL_CARBON_EVENT = 'totalCarbon';

const SYNCHRONIZED_EVENT = 'sincronize-selected';

const TotalGas = ({
  id, totalGasData, totalCarbon, selectedWell, currentWell
}) => {
  const zoomRefId = useRef(null);
  const [chart, setChart] = useState(null);
  const [rangeValues, setRangeValues] = useState([]);
  const [positionsLeft, setPositionsLeft] = useState([]);
  const [activeWell, setCurrentWell] = useState();

  const normalizedCarbonData = totalCarbon.map(item => item[0]);
  const series = useState({
    0: true,
    1: true,
    2: true,
    3: true
  });

  const positioningTheLabels = (chartInstance) => {
    const { childNodes } = chartInstance.axes[1].labelGroup.element;

    const labels = [...childNodes].map(element => {
      if (element.childNodes.length === 2) {
        return element.childNodes[0].innerHTML;
      }
      return element.innerHTML;
    }).filter(x => x);
    const newPositionsLeft = [...childNodes].map(element => `calc(${element.getAttribute('x')}px - 10px)`);

    setRangeValues(labels);
    setPositionsLeft(newPositionsLeft);
  };


  useEffect(() => {
    if (activeWell !== selectedWell) {
      setCurrentWell(selectedWell);
    }
  }, [selectedWell]);

  useEffect(() => {
    const scales = store.get('configScales');
    const totalGasConfig = createDefaultConfig(
      scales,
      displayZoomBtn,
      propagateZoom,
      getToolTipValues,
      getDefaultSeries,
      defaultCrossHair,
      totalGasData,
      normalizedCarbonData,
    );
    const chartInstance = Highcharts.chart(id, totalGasConfig);
    if (chartInstance) {
      setChart(chartInstance);
      positioningTheLabels(chartInstance);
      zoomRefId.current = applyDefaultZoomToChart(chartInstance);

      if (currentWell && currentWell.depth && currentWell.depth.length && chart && chart.xAxis && chart.xAxis[0]) {
        chart.xAxis[0].setExtremes(currentWell.depth.length - 200, currentWell.depth.length);
      }

      syncronizeTooltip(`#${id}`);
    }
  }, []);


  useEffect(() => {
    if (activeWell !== selectedWell) {
      setCurrentWell(selectedWell);
    }
  }, [selectedWell]);

  useEffect(() => {
    if (
      activeWell !== selectedWell && chart &&
      (totalCarbon && totalCarbon.length
        || totalGasData && totalGasData.length)
    ) {
      if (chart && chart.update) {
        chart.update({
          series: getDefaultSeries(
            totalGasData,
            normalizedCarbonData
          )
        });
        chart.redraw();
        positioningTheLabels(chart);
      }

      if (currentWell && currentWell.depth && currentWell.depth.length && chart && chart.xAxis && chart.xAxis[0]) {
        chart.xAxis[0].setExtremes(currentWell.depth.length - 200, currentWell.depth.length);
      }
    }
  }, [normalizedCarbonData, currentWell, chart, totalGasData, totalCarbon]);

  useEffect(() => {
    const setSeries = series[1];
    const genericToggle = ({ detail: { index } }) =>
      toggleCurves(chart, setSeries, index);

    const updateSetings = (chartInstance, indexSerie, positioningTheLabels) => ({ detail }) => {
      if (chartInstance.yAxis && chartInstance.yAxis[indexSerie]) {
        chartInstance.yAxis[indexSerie].update(
          {
            min: formattingMin(detail.min),
            max: detail.max
          }
        );
        chartInstance.redraw();
        positioningTheLabels(chartInstance);
      }
      if (chartInstance.series && chartInstance.series[indexSerie]) {
        const serie = chartInstance.series[indexSerie];
        serie.update({
          color: detail.color,
          lineColor: detail.color
        });
        serie.redraw();
      }
    };

    const synchronizeSelectedDepth = event => {
      const {
        detail: { min, max }
      } = event;
      chart.xAxis[0].setExtremes(min, max, false, false);
      chart.redraw();
    };

    if (chart) {
      document.addEventListener(TOGGLE_TOTAL_GAS, genericToggle);
      document.addEventListener(TOGGLE_TOTAL_CARBON, genericToggle);

      document.addEventListener(SYNCHRONIZED_EVENT, synchronizeSelectedDepth);

      // TODO add change scales of total carbon and c1Normalized
      // document.addEventListener(
      //   TOTAL_GAS_EVENT, 
      //   updateSetings(chart, 0, positioningTheLabels)
      // );
      document.addEventListener(
        TOTAL_GAS_EVENT,
        updateSetings(chart, 0, positioningTheLabels)
      );
      document.addEventListener(
        TOTAL_GAS_EVENT,
        updateSetings(chart, 1, positioningTheLabels)
      );
      document.addEventListener(
        TOTAL_CARBON_EVENT,
        updateSetings(chart, 2, positioningTheLabels)
      );

      return () => {
        document.removeEventListener(TOGGLE_TOTAL_GAS, genericToggle);
        document.removeEventListener(TOGGLE_TOTAL_CARBON, genericToggle);

        document.removeEventListener(SYNCHRONIZED_EVENT, synchronizeSelectedDepth);

        document.removeEventListener(TOTAL_GAS_EVENT, updateSetings);
        document.removeEventListener(TOTAL_CARBON_EVENT, updateSetings);

        clearTimeout(zoomRefId);
      }
    }
  }, [chart]);

  return (
    <ChartContainer
      className="total-gas-container"
    >
      <ChartHeader
        rangeValues={rangeValues}
        positionsLeft={positionsLeft}
      />
      <div
        id={id}
        style={{
          height: 'calc(100% - 130px)'
        }}
        data-cy="chart-total-gas"
      />
    </ChartContainer>
  );
};

TotalGas.defaultProps = {
  id: 'total-gas',
  parentContainer: '.charts-container'
};

export default TotalGas;