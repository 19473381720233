import { Checkbox } from 'antd';
import styled from 'styled-components'

export const StyledCheckbox = styled(Checkbox)`
	& > .ant-chechbox{
		& .ant-checkbox-inner {
			background-color: transparent;
			border: 1px solid #595959;
		}
	}   
`;
