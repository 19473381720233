import React from "react";
import { Typography, Spin, Icon } from 'antd'
import { SmileOutlined, LoadingOutlined } from '@ant-design/icons';

import { StyledSection, HydraulicsPressure, ChartBox, PressureGridItems } from "../styles";

import PiePressureChart from "../../../components/Hydraulics/Charts/PiePressureChart";
import PieChartLegend from "../../../components/Hydraulics/Charts/PiePressureChart/PieChartLegend";
import Indicator from '../../../components/Hydraulics/Indicator'
import useHydraulicsContext from "../useHydraulics";
import { pressureConverter } from "../../../components/Hydraulics/SettingsFormModal/SettingsForm/UnitConverterFormula";

import { handleData } from "../hydraulicsUtils";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />; 

export const PressureLoss = () => {
    const { isLoading, model, unitsConfigDetails } = useHydraulicsContext();

    const colors = ['#351E66', '#5427AD', '#7D4ED9', '#B499E8', '#8C4E0C', '#B36106', '#E0B219', '#E9E56D'];

    function handleLegendData(data) {

        const keys = Object.keys(data).slice(0, -1)
        const newData = []

        keys.map((name) => {
            if (!Array.isArray(data[name])) {
                newData.push({ name, value: (data[name] / data['Total Pressure Losses']) * 100 })
            }
        })
        return newData

    }

    return (
        <StyledSection>
            <Typography.Title level={3} style={{ color: '#fff', padding: '20px' }}>Pressure Losses</Typography.Title>
            <HydraulicsPressure>
                <ChartBox>
                    {!isLoading ?
                        <>
                            <PiePressureChart data={model} />
                        </>
                        :
                        <div style={{ display: 'flex', padding: '20px', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
                            <Spin indicator={antIcon} />
                        </div>
                    }
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
                        {handleLegendData(model).map(({ name, value }, index) => <PieChartLegend key={index} title={name} value={value ? Number(value) : "N/A"} color={colors[index]} />)}
                    </div>
                </ChartBox>
                <PressureGridItems>
                    {handleData(model).map((indicator) =>  <Indicator key={indicator.field} title={indicator.field} value={pressureConverter(Number(indicator.value),'psi', unitsConfigDetails.pressure_unit).toFixed(2)} unit={unitsConfigDetails.pressure_unit} />)}
                </PressureGridItems>
            </HydraulicsPressure>
        </StyledSection>
    )
}