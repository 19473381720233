import React, {
  memo,
  useEffect,
  useRef,
  useState
} from 'react';

import ChartContainer from 'components/Shared/ChartContainer';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import {
  applyDefaultZoomToChart,
  toggleCurves
} from 'lib/charts';
import { useSelector } from 'react-redux';
import store from 'store';
import {
  defaultCrossHair,
  displayZoomBtn,
  formattingMin,
  getToolTipValues,
  propagateZoom,
  syncronizeTooltip
} from 'utils';

import ChartHeader from './ChartHeader';
import {
  createDefaultConfig,
  getDefaultSeries
} from './config';

Exporting(Highcharts);

// const TOGGLE_MSE_CURVE = 'toggle-mse-curve';
const TOGGLE_WOB_CURVE = 'toggle-wob-curve';
const TOGGLE_ROP_CURVE = 'toggle-rop-curve';

// const MSE_EVENT = 'mse';
const WOB_EVENT = 'wob';
const ROP_EVENT = 'rop';

const SYNCHRONIZED_EVENT = 'sincronize-selected';

const Mse = ({
  id,
  wob,
  rop,
  selectedWell,
  depth
}) => {
  const zoomRefId = useRef(null);
  const [chart, setChart] = useState();
  const wells = useSelector(state => state.wells);
  const { currentWell } = wells;
  const [activeWell, setCurrentWell] = useState(selectedWell);
  const series = useState({
    0: true,
    1: true,
    2: true,
    3: true
  });

  useEffect(() => {
    if (currentWell) {
      const depth = currentWell?.index_curve?.data || [];
      const scales = store.get('configScales');
      const mseConfig = createDefaultConfig(
        scales,
        displayZoomBtn,
        propagateZoom,
        getToolTipValues,
        getDefaultSeries,
        defaultCrossHair,
        [], //mse,
        wob,
        rop,
        depth
      );
      const maxDepth = depth.length - 1;
      const chartInstance = Highcharts.chart(id, mseConfig);

      if (chartInstance) {
        setChart(() => chartInstance);
        zoomRefId.current = applyDefaultZoomToChart(chartInstance, maxDepth);
        syncronizeTooltip(`#${id}`);
      }
    }
  }, [wells && currentWell]);

  useEffect(() => {
    if (activeWell !== selectedWell) {
      setCurrentWell(selectedWell);
    }
  }, [selectedWell]);


  useEffect(() => {
    if (
      activeWell !== selectedWell && chart
      || wob && wob.length
      || rop && rop.length
    ) {
      if (chart && chart.series) {
        chart.series[1].update({
          data: wob,
        });
        chart.series[2].update({
          data: rop,
        });
        chart.redraw();
      }

      if (currentWell && currentWell?.index_curve?.data && currentWell.index_curve?.data?.length && chart && chart.xAxis && chart.xAxis[0]) {
        chart.xAxis[0].setExtremes(currentWell.index_curve?.data?.length - 200, currentWell.index_curve?.data?.length);
      }
    } 
  }, [wob, rop, currentWell, chart]);

  useEffect(() => {
    let timeoutId = undefined;
    if (chart && chart.update) {
      if (selectedWell && chart.axes != undefined) {
        if (currentWell && currentWell?.index_curve && chart) {
          timeoutId = setTimeout(() => {
            const depth = currentWell.index_curve?.data || [];
            if (
              depth && depth.length
              && (chart
                && chart.xAxis !== undefined
              )
            ) {
              chart.xAxis[0].setExtremes(depth.length - 200 || 0, depth.length);
            }
          }, 200);

          if (selectedWell && chart.axes != undefined && currentWell) {
            chart.update({
              series: getDefaultSeries([], wob, rop)
            });

            chart.redraw();
          }
        }
      }
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    }
  }, [
    // currentWell && currentWell.mse,
    selectedWell,
    wob, 
    depth, 
    rop,
    chart,
  ]);

  useEffect(() => {
    const setSeries = series[1];
    const genericToggle = ({ detail: { index } }) =>
      toggleCurves(chart, setSeries, index);

    const updateSetings = (chartInstance, indexSerie) => ({ detail }) => {
      if (chartInstance.yAxis && chartInstance.yAxis[indexSerie]) {
        chartInstance.yAxis[indexSerie].update(
          {
            min: formattingMin(detail.min),
            max: detail.max
          },
          true
        );
      }
      if (chartInstance.series && chartInstance.series[indexSerie]) {
        const serie = chartInstance.series[indexSerie];
        serie.update({
          color: detail.color,
          lineColor: detail.color
        });
        serie.redraw();
      }
    };

    const synchronizeSelectedDepth = event => {
      const {
        detail: { min, max }
      } = event;

      const { depth } = this.props;

      chart.xAxis[0].setExtremes(min || depth.length - 200, max || depth.length);
      chart.redraw();
    };

    if (chart) {
      // document.addEventListener(TOGGLE_MSE_CURVE, genericToggle);
      document.addEventListener(TOGGLE_WOB_CURVE, genericToggle);
      document.addEventListener(TOGGLE_ROP_CURVE, genericToggle);

      document.addEventListener(SYNCHRONIZED_EVENT, synchronizeSelectedDepth);

      // document.addEventListener(MSE_EVENT, updateSetings(chart, 1));
      document.addEventListener(WOB_EVENT, updateSetings(chart, 1));
      document.addEventListener(ROP_EVENT, updateSetings(chart, 2));
    }

    return () => {
      // document.removeEventListener(TOGGLE_MSE_CURVE, genericToggle);
      document.removeEventListener(TOGGLE_WOB_CURVE, genericToggle);
      document.removeEventListener(TOGGLE_ROP_CURVE, genericToggle);

      document.removeEventListener(
        SYNCHRONIZED_EVENT,
        synchronizeSelectedDepth
      );

      // document.removeEventListener(MSE_EVENT, updateSetings);
      document.removeEventListener(WOB_EVENT, updateSetings);
      document.removeEventListener(ROP_EVENT, updateSetings);

      clearTimeout(zoomRefId);
    };
  }, [series, chart]);

  return (
    <ChartContainer className="total-gas-container">
      <ChartHeader />
      <div
        id={id}
        style={{
          height: 'calc(100% - 132px)'
        }}
        data-cy="chart-mse"
      />
    </ChartContainer>
  );
};

Mse.defaultProps = {
  id: 'mse',
  parentContainer: '.charts-container'
};

export default Mse;
