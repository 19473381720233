import styled from 'styled-components'
import { Select } from 'antd'

export const StyledSelect = styled(Select)`
    border: none;
        
    & .ant-select-selection{
        border: none;
        border-radius: 5px;
        background-color: ${({ theme }) => theme.colors.dark['950']};
        color: ${({ theme }) => theme.colors.support.white};
        padding: 0.35rem;
        .ant-select-arrow{
            i svg{
                color: #B4B1C2;
            }
        }
        .ant-select-selection__choice{
            border: none;
            border-radius: 5px;
            background-color: ${({ theme }) => theme.colors.dark['800']};
            color: ${({ theme }) => theme.colors.support.white};
            .ant-select-selection__choice__remove{
                color: ${({ theme }) => theme.colors.support.white};
            }
        }
    }
`;