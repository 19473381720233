import React, { Component } from 'react';

import ChartContainer from 'components/Shared/ChartContainer';
import Highcharts from 'highcharts';
import {
  lithoScoreFormatter,
  maxCurveLithology,
  syncronizeTooltip
} from 'utils';

import ChartHeader from './ChartHeader';
import {
  createDefaultConfig,
  defaultSeries,
  generateSerie
} from './config';

import { ChartWithHooks } from '../ChartWithHooks';

class LithologyScore extends Component {
  constructor(props) {
    super(props);
    this.chart = null;

    this.state = {
      data: [],
      lithologyScore: undefined,
    };
  }

  componentDidMount() {
    this.createRealTimeLithoChart([], []);
  }

  shouldComponentUpdate(nextProps) {
    const { selectedWell, currentWell } = nextProps;
    if (
      currentWell && currentWell.index_curve?.data?.length &&
      selectedWell !== this.props.selectedWell ||
      nextProps.currentWell.lithologyScore && !this.state.lithologyScore ||
      nextProps.currentWell?.ml !== this.props.currentWell?.ml ||
      nextProps.curves !== this.props.curves || nextProps.selectedCurve !== this.props.selectedCurve
    ) {
      this.setState({ lithologyScore: this.props.selectedCurve?.data });
      const depthValues = currentWell && currentWell.index_curve?.data ? currentWell.index_curve?.data : [];

      const dataFormatted = nextProps.selectedCurve?.data || {};
      const series = Object.keys(dataFormatted).map((key, index) => {
        return generateSerie(key, dataFormatted[key], index + 1);
      });
      const { depth } = this.props;

      this.chart.destroy();
      this.createRealTimeLithoChart(dataFormatted, series);

      this.zoomId = setTimeout(() => {
        this.chart.xAxis[0].setExtremes(depth.length - 200, depth.length);
      }, 200);
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    this.zoomId && clearTimeout(this.zoomId);
  }

  createRealTimeLithoChart = (data, series) => {
    const { id } = this.props;

    let defaultPlaceholderData = [];

    if (Object.keys(data).length) {
      defaultPlaceholderData = maxCurveLithology(data);
    }

    // This map will generate each serie that we are passing data via props
    // NOTE: This will generate data just relyng from the serie that we are receiveing
    // const series = Object.keys(data).map(
    //   (key, index) => generateSerie(key, data[key], index + 1)
    // );
    series.push(defaultSeries(defaultPlaceholderData));
    this.chart = Highcharts.chart(id, createDefaultConfig(this.props, series));

    document.addEventListener('sincronize-selected', event => {
      const {
        detail: { min, max }
      } = event;

      const { depth } = this.props;
      let minValue = depth[min];
      let maxValue = depth[max];

      this.chart.xAxis[0].setExtremes(minValue, maxValue, true, true);
      this.chart.redraw();
    });

    syncronizeTooltip(`#${this.props.id}`);
  }

  render() {
    const { id, currentWell, title, depth, curves, onSelectCurves, selectedCurve, filterBy } = this.props;

    return (
      <ChartContainer>
        <ChartHeader 
          title="Lith. Scores (%)" 
          lithoData={selectedCurve?.data || []}
          openCurveChangeModal={this.props.openConfigCurveModal}
          title={title} 
          depth={depth} 
          filterBy={filterBy}
        />
        <div
          id={id}
          style={{
            marginLeft: '10px',
            height: 'calc(100% - 128px)'
          }}
          data-cy="chart-real-time-lithology"
        />
      </ChartContainer>
    );
  }
}

LithologyScore.defaultProps = {
  width: 300,
  type: 'area',
  bgColor: '#fff',
  inverted: true,
  animation: false,
  customProps: {},
  id: 'litho-scores',
  title: 'Lith. Scores (%)'
};

export default ChartWithHooks(LithologyScore);
