import styled from 'styled-components'
import { Select } from 'antd'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: rgb(29, 28, 28);
`

export const SelectStyled = styled(Select)`

  width: 100%;
  &.ant-select{
   & .ant-select-selection__rendered{
      height: 40px;
      line-height: 3!important;
    }
    .ant-select-selection{
      height: 40px;
      background-color: #272727;
      line-height: 30px;
      border: none;
      color: #fff;
      .ant-select-arrow{
        color: #fff;
      }
    }
  }
`
