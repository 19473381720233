import React, { useState, useRef, useEffect } from 'react'
import { Box } from '..'

import Input from '../InputRAW'
import Image from '../Image'
import Options from './Options/Options'


const Combobox = ({ options, onSelect, mode, initialValue, width, onChange, ...props }) => {
	const [isOpen, setIsOpen] = useState(false)
	const [value, setValue] = useState()
	const [isMultiple, setIsMultiple] = useState(false);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const inputRef = useRef(null)




	const handleSelected = (id) => {
		const selectedItem = options.find((item) => item.id === id);
		setIsMultiple(mode == 'multiple' ? true : false)
		if (isMultiple) {
			const newSelectedOptions = selectedOptions.includes(selectedItem)
				? selectedOptions.filter((selected) => selected !== selectedItem)
				: [...selectedOptions, selectedItem];

			setSelectedOptions(newSelectedOptions);
			onSelect(newSelectedOptions);
			console.log('combobox (selectedOptions) =>', selectedOptions)
			console.log('combobox (newSelectedOptions)=>', newSelectedOptions)
		} else {
			onSelect(selectedItem);
			setValue(selectedItem.label);
		}

		setIsOpen(false);
	};

	const handleChange = (e) => {
		const { value } = e.target

		setValue(value)
		onChange(value)
		setIsOpen(true)
	}

	useEffect(() => {
		setValue('')
	}, [props.value])

	const renderOptionsStateIcon = () => (
		<Image
			onClick={!props.disabled ? () => setIsOpen(!isOpen) : null}
			name={isOpen ? 'arrowUp' : 'arrowDown'}
			width={13}
			height={13}
			alt="arrow-down"
		/>
	)

	return (
		<Box flex="1" position="relative">
			<Input ref={inputRef} width={width} rightElement={renderOptionsStateIcon} value={value} onChange={handleChange} {...props} />
			{options.length ? (
				<Options data={options} isOpen={isOpen} onSelect={handleSelected} />
			) : null}
		</Box>
	)
}

export default Combobox
