import React, { useEffect } from 'react'
import { Button, Checkbox, Row, Col } from 'antd'
import ToggleButton from 'react-toggle-button'
import { useDispatch } from 'react-redux';
import { updateChartsVisibility } from '../../../actions/wells';

const CheckboxGroup = Checkbox.Group;

const chartsList = ['Mse', 'Gamma Ray', 'ML Porosity', 'Cuttings', 'Interpreted Lithology', 
    'Real Time Lithology', 'Lithology Scores', 'Chromatography']

const CustomizeCharts = () => {
    const dispatch = useDispatch();
    const [checkedValues, setCheckedValues] = React.useState(chartsList);
    const onChange = newCheckedValues => {
        const addedValue = newCheckedValues.find(value => !checkedValues.includes(value));
        const removedValue = checkedValues.find(value => !newCheckedValues.includes(value));

        if (addedValue) {
            dispatch(updateChartsVisibility({ type: addedValue.toLowerCase().replace(/\s/g, ''), status: true }));
        } else if (removedValue) {
            dispatch(updateChartsVisibility({ type: removedValue.toLowerCase().replace(/\s/g, ''), status: false }));
        }

        setCheckedValues(newCheckedValues);
    }

    return (
        <div style={{ marginLeft: '1rem' }}>
            <h3>Customize charts visualization</h3>
            <p style={{ marginTop: '1.5rem', fontSize: '15px' }}>Select which charts you want to display in the dashboard</p>
            <div style={{ marginTop: '1rem' }}>
                <Row>
                <CheckboxGroup 
                    className='custom-checkbox' 
                    onChange={onChange} 
                    value={checkedValues}
                >
                    {chartsList.map((chart, index) => (
                        <Col span={8}>
                            <Checkbox style={{ fontSize: '15px', marginTop: '0.5rem' }}key={index} value={chart}>{chart}</Checkbox>
                        </Col>
                    ))}
                </CheckboxGroup>
                </Row>
            </div>
        </div>
    )
}

export default CustomizeCharts;