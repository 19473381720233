import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Text from '../../Text';

import useGeoCopilotContext from "../../../hooks/useGeocopilot";
import { Button } from 'antd';

const copyToClipboard = async (text) => {
  navigator.clipboard.writeText(text)
};

const rockImages = require.context('../../../images/patterns', true, /\.png$/);
const rockImagesPatterns = rockImages.keys()
    .reduce((images, path) => {
      images[path.replace(".png", '').replace(/[./]/g, '')] = rockImages(path)
      return images
    }, {})

const BoxStyled = styled.div`
  display: flex;
  background: #323232; 
  flex-direction:column; 
  padding: 20px;
  border-radius: 10px;
  margin-top: 10px;
`

function GeoCopilotResults({ children, ...props }) {
  const { interpretations, response } = useGeoCopilotContext();
  const [resultsDetails, setResultsDetails] = useState([]);

  useEffect(() => {
    if (interpretations) {
      setResultsDetails(interpretations);
    }
  }, [interpretations]);

  console.log(resultsDetails);

  return (
    <>
      {resultsDetails.length ? resultsDetails.map((item, index) => (<>
        <BoxStyled>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
            <Text variant={'subtitle'} style={{ fontWeight: 600 }}>
              Top: {item.top} Base: {item.base} <br />Lithology:
              <span role="img" aria-label={rockImagesPatterns[item.lithology]}>
                <img src={rockImagesPatterns[item.lithology]}
                  key={item.lithology}
                  alt={item.lithology}
                  style={{ width: '20px', height: '20px', margin: '4px' }}
                />
              </span>
              {item.lithology.replaceAll('rock_', '').toUpperCase()}
            </Text>
            <Button
              style={{ backgroundColor: 'rgb(48, 48, 48)', opacity: 1, color: '#fff' }}
              onClick={async () => { await copyToClipboard(item.explanation) }}
            >
              Copy
            </Button>
          </div>
            <div style={{ display: 'flex', flexDirection: 'column' }} key={index}>
              <span>{item.explanation}</span>
            </div>
          </BoxStyled>
          </>)) :
          <div style={{ marginTop: '5px' }}>
            <Text variant={'subtitle'}>No data available...</Text>
          </div>
      }
    </>);
}

export default GeoCopilotResults;

