export const getProductionVersion = (versions) => {
    versions.reduce((acc, version) => {
        Object.keys(version) && Object.keys(version).find((versionKey) => {
            if (version[versionKey].current_stage === 'Production') {
                acc.push(version[versionKey]);
            }
        });
        return acc;
    }, []);

}

export const getDateCreated = (metrics, models) => {
    metrics.map((metricKey, index) => {
        if (index === 0) {
            return models && models.metrics && models.metrics[metricKey] && models.metrics[metricKey];
        }
    });
}