import React, {
    useEffect,
    useState
} from 'react';
import {
    useSelector
} from 'react-redux';
import useMachineLearningContext from '../../../hooks/useML';

export function useDisplayCurves(filterBy){
    const currentWell = useSelector(state => state.wells.currentWell);
    const { setMlCurves } = useMachineLearningContext();
    const [curves, setCurves] = useState(currentWell?.ml || []);
    const [currentCurve, setCurrentCurve] = useState(filterBy);

    useEffect(() => {
        if (currentWell && currentWell.ml) {
            const filteredCurves = [];
            setCurrentCurve(filterBy);
            Object.keys(currentWell?.ml)
            .forEach(curve => {
                if( curve === filterBy ){
                    if (Array.isArray(currentWell?.ml?.[curve])){
                        currentWell?.ml?.[curve].forEach((value, index) => {
                            filteredCurves[value.mnemonic] = value;
                        });
                    }
                    else {       
                        filteredCurves.push(currentWell?.ml?.[curve]);
                    }
                }
            });
            setCurves(filteredCurves);
        }
    }, [currentWell, filterBy]);

    const selectCurve = (curve) => {
        setCurrentCurve(curve);
        setMlCurves(prevState => {return {...prevState, [filterBy]: curves[curve]}});
    }

    return {currentCurve, curves, selectCurve}
};

export default useDisplayCurves;

