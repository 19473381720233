import map from 'lodash.map';
import store from 'store';
import GlobalCutOff from 'utils/GlobalCutOff';
import { removeAllZones } from 'utils/Zones';
import mockWellData from 'reducers/well_data_optional.json';
import mockMetadataOptionals from 'reducers/well_metadata_mock_optionals.json';

import { getFormattedWell } from 'utils/getFormattedWell';
import { getWellSiteScales } from 'utils/getWellSiteScales';

import {
  createWell,
  deleteWell,
  fetchInWellSite,
  fetchInWellSites,
  fetchWellSite,
  normalizeWellBoreToStore,
  fetchBucketFile,
  getFileToParse,
  postBucket,
  getSignedUrl,
  getWellSites,
  updateBucketWellFile
} from '../api/wells';

import {
  isWellexAPIRunning,
  fetchInWellWatcher,
  fetchInWellWatcherSite,
  fetchInWellWatcherSites
} from '../api/wellwatcher';
import { getWellData, listWellsMetadata } from '../api/import-data';

import * as actions from '../constants/wells';

export const startCalculateGeopressure = () => ({
  type: actions.START_CALCULATE_GEOPRESSURE
});

export const stopCalculateGeopressure = () => ({
  type: actions.STOP_CALCULATE_GEOPRESSURE
});

export const filterWell = id => ({
  type: actions.FILTER_WELL,
  id,
});

export const receiveWell = well => ({
  type: actions.RECEIVE_WELL,
  well,
});

export const updateChartsVisibility = chart => ({
  type: actions.UPDATE_CHARTS_VISIBILITY,
  chart,
});


export const receiveMetadata = metadata => ({
  type: actions.RECEIVE_METADATA,
  metadata,
});

export const receiveInWell = metadata => ({
  type: actions.RECEIVE_IN_WELL,
  metadata,
});

export const receiveInWellData = data => ({
  type: actions.RECEIVE_IN_WELL_DATA,
  data,
});

export const receiveRealtimeLithology = data => ({
  type: actions.RECEIVE_REALTIME_LITHOLOGY,
  data,
});

export const receiveInterpretedLithology = data => ({
  type: actions.RECEIVE_INTERPRETED_LITHOLOGY,
  data,
});

export const receiveLithology = data => ({
  type: actions.RECEIVE_LITHOLOGY,
  data,
});

export const receiveMLPorosity = data => ({
  type: actions.RECEIVE_ML_POROSITY,
  data,
});

export const receiveAiGrPrediction = data => ({
  type: actions.RECEIVE_AI_GAMMA_RAY,
  data,
});

export const receiveLithologyScore = data => ({
  type: actions.RECEIVE_LITHOLOGY_SCORE,
  data,
});

export const receiveWells = (wells) => ({
  type: actions.RECEIVE_WELLS,
  wells,
});

export const removeCurrentWell = (wellId) => ({
  type: actions.DELETE_WELL,
  wellId,
});

export const registerDepthData = data => ({
  type: actions.REGISTER_DEPTH_DATA,
  data,
});

export const updateWell = well => ({
  type: actions.UPDATE_WELL,
  well,
});

export const receiveSelectedDepthIndex = index => ({
  type: actions.RECEIVE_SELECTED_INDEX,
  index,
});

export const receiveTrendLinesValue = trendLines => ({
  type: actions.RECEIVE_TRENDLINES_VALUE,
  trendLines,
});

// To populate dropdowns
export const listWells = () => {
  const token = store.get('token');
  return async (dispatch) => {
    try {
      const { data: { content: wells } } = await listWellsMetadata(token);
      dispatch(receiveWells(wells))
      return wells;
    } catch (e) {
      console.log('Error listWells', e);
    }
  };
};



// --------------- In Wells -----------------------
export const listInWells = (afterLoad) => {
  return async (dispatch) => {
    try {
      const { data } = await fetchInWellWatcherSites(store.get('token'));
      if (data && data.length) {
        afterLoad && afterLoad();
        dispatch(receiveWells(data));
      } else {
        dispatch(receiveWells([]))
        return [];
      }
    } catch (e) {
      console.log('Error listWells', e);
    }
  };
};

export const listInWell = (source, uid, uidWell, token, afterSuccess) => {
  return async (dispatch, getState) => {
    const isApiRunning = await isWellexAPIRunning();
    try {
      let response = null;
      if (isApiRunning.message === 'api is runnig!') {
        response = await fetchInWellWatcherSite(source, uid, uidWell, token);
      } else {
        response = await fetchInWellSite(uid, uidWell, token);
      }
      console.log("wells/actions ~ response => ", response)
      if (response) {
        const currentWellData = getState().wells.items.find(w => w.id === uidWell);
        afterSuccess && afterSuccess();

        dispatch(receiveWell(response.data));
        GlobalCutOff.registerDefaultData(currentWellData);

        map((response && response.zones || []), (zone) => {
          removeAllZones(zone.id);
        });
        return response;
      } else {
        dispatch(receiveWell([]))
        afterSuccess && afterSuccess();
        return [];
      }
    } catch (e) {
      console.log('Error list well', e);
    }
  };
};


// --------------- Wellsites-----------------------

// export const createWellSite = (well, token, email, afterSuccess) => {
//   const normalizedWellToStore = normalizeWellBoreToStore(well);
//   return async (dispatch) => {
//     try {
//       const { data: response } = await createWell(normalizedWellToStore, token, email);
//       await dispatch(fetchWellSites());
//       await dispatch(listWell(response.data.pathFile, token));
//       afterSuccess && afterSuccess(response.data.wellId);
//     } catch (e) {
//       console.log('error create well', e);
//     }
//   };
// };

export const updateWellStorage = (conflictedWell, wellId, rawData, calculatedData, token, afterSuccess) => {

  const { rawFileName, calculatedFileName } = conflictedWell;
  return async (dispatch) => {
    try {
      const rawDataSignedURL = await getSignedUrl(
        'wellex-well-storage',
        'data',
        rawFileName,
        'raw',
        'json',
        token
      );

      const calculatedSignedURL = await getSignedUrl(
        'wellex-well-storage',
        'data',
        calculatedFileName,
        'calculated',
        'json',
        token
      );

      const rawResponse = await updateBucketWellFile(
        rawDataSignedURL.data.signed_url,
        JSON.stringify(rawData)
      );

      const calculatedResponse = await updateBucketWellFile(
        calculatedSignedURL.data.signed_url,
        JSON.stringify(calculatedData)
      );

      const rawResponseData = rawResponse ? JSON.parse(rawResponse) : {};
      const calculatedResponseData = calculatedResponse ? JSON.parse(calculatedResponse) : {};

      const wellData = {
        ...rawResponseData,
        ...calculatedResponseData
      };
      dispatch(receiveWell({ wellId, ...rawData, ...calculatedData, wellData, ...conflictedWell }));
      afterSuccess && afterSuccess(wellId);
      // dispatch(listWell(wellId, store.get('token')));
      // dispatch(fetchZones(wellId, store.get('automud-token')));
    } catch (e) {
      console.log('error create well', e);
    }
  };
};

export const createBucket = (wellName, rawData, calculatedData, token, afterSuccess) => {
  const payload = {
    name: wellName,
    description: '-',
    provider: 'AUTOMUD'
  };

  return async (dispatch) => {
    try {
      const buketResponse = await postBucket(payload, token);
      const {
        file_name,
        file_extension,
        bucket_name,
        id
      } = buketResponse.data;

      const rawDataSignedURL = await getSignedUrl(
        bucket_name,
        'data',
        file_name,
        'raw',
        file_extension,
        token
      );

      const calculatedSignedURL = await getSignedUrl(
        bucket_name,
        'data',
        file_name,
        'calculated',
        file_extension,
        token
      );

      const rawResponse = await updateBucketWellFile(
        rawDataSignedURL.data.signed_url,
        JSON.stringify(rawData)
      );

      const calculatedResponse = await updateBucketWellFile(
        calculatedSignedURL.data.signed_url,
        JSON.stringify(calculatedData)
      );

      const rawResponseData = rawResponse ? JSON.parse(rawResponse) : {};
      const calculatedResponseData = calculatedResponse ? JSON.parse(calculatedResponse) : {};

      const wellData = {
        ...rawResponseData,
        ...calculatedResponseData
      };

      dispatch(receiveWell({ wellId: id, ...wellData, wellName, nameWell: wellName }));
      afterSuccess && afterSuccess(id);
      // afterSuccess && afterSuccess(response.data.wellId);
    } catch (e) {
      console.log('error create well', e);
    }
  }
};


export const fetchWellSites = () => {
  return async (dispatch) => {
    try {
      // TODO should use the current username when use keycloack adapter
      const { data: response } = await getWellSites(store.get('token'));
      dispatch(receiveWells(response))
      return response;
    } catch (e) {
      console.log('Error listWells', e);
    }
  };
};

export const listWell = (wellId, token, currentWell, afterSuccess) => {
  return async (dispatch, getState) => {
    try {
      const { data: { content: wellData } } = await getWellData(wellId);

      const formattedWell = getFormattedWell(wellData);
      const scales = getWellSiteScales(formattedWell);
      const wellSiteWithScales = {
        ...formattedWell,
        isActive: currentWell?.is_active,
        project_uid: currentWell?.project_uid,
        source_info: currentWell?.source_info,
        scales,
      };

      dispatch(receiveWell(wellSiteWithScales));

      if (afterSuccess) {
        afterSuccess();
      }
    } catch (e) {
      console.log(e)
    }
    // try {
    //   // wells files
    //   const { data: { files } } = await fetchWellSite(wellId, token);

    //   // --------------- fetch calculated data ----------------
    //   const calculatedFileName = files && files[0] ? files[0].file_name : '';
    //   if (!files.length) {
    //     alert('There are no data for this well');
    //     return;
    //   }

    //   const responseCalculatedData = await fetchBucketFile(calculatedFileName, token);
    //   const calculatedData = await getFileToParse(
    //     responseCalculatedData.data.signed_url,
    //     token
    //   );

    //   // --------------- fetch rawData ----------------
    //   const rawDataFileName = files && files[1] ? files[1].file_name : '';
    //   const responseRawData = await fetchBucketFile(rawDataFileName, token);
    //   const rawData = await getFileToParse(
    //     responseRawData.data.signed_url
    //   );

    //   const well = {
    //     ...rawData,
    //     ...calculatedData,
    //     wellId,
    //     rawFileName: rawDataFileName,
    //     calculatedFileName,
    //     rawData,
    //     calculatedData
    //   };

    //   store.set('selectedWellInfo', {
    //     uidWell: well.uidWell,
    //     uid: well.uid,
    //     uidWellbore: well.uidWellbore,
    //     wellId: well.wellId,
    //     wellName: well.wellName,
    //   });

    //   dispatch(receiveWell(well));

    //   GlobalCutOff.registerDefaultData(well);
    //   return well;
    //   // map(response.zones, (zone) => {
    //   //   removeAllZones(zone.id);
    //   // });
    // } catch (e) {
    //   console.log('Error list well', e);
    // }
  };
};

// export const listWells = () => {
//   return async (dispatch) => {
//     try {
//       const { data: response } = await getWellSites(store.get('automud-token'));
//       dispatch(receiveWells(response))
//       return response;
//     } catch (e) {
//       console.log('Error listWells', e);
//     }
//   };
// };

export const removeWell = (token, wellId) => {
  return async (dispatch) => {
    try {
      dispatch(removeCurrentWell(wellId));
      await deleteWell(token, wellId);
      // dispatch(listWells());
    } catch (e) {
      console.log('error remove well', e);
    }
  };
};
