import React from 'react'

import './style.scss'

import Modal from 'react-modal';

import { modalStyleMachineLearning } from '../../utils';
import ModalModels from './ModelsModal';
import Tabs from '../Tabs';
import Box from '../Box';
import Image from '../Image';

const ExperimentsModal = ({ isOpen, onClose, openModalWellPhases }) => {
  const tabs = ['Models', 'Experiments']

  return (
    <Modal
      isOpen={isOpen}
      style={modalStyleMachineLearning}
      onRequestClose={onClose}
    >
      <Box display="flex" flexDirection="column" padding="2rem 4rem" className="experiment-container" backgroundColor="rgb(29, 28, 28)">
        <Box display='flex' justifyContent="end">
          <Image alt="close" width={12} height={12} name="close" onClick={onClose} />
        </Box>
        <ModalModels onClose={onClose} openModalWellPhases={openModalWellPhases} />
      </Box>
    </Modal>
  );
};

export default ExperimentsModal
