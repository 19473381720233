export const getFormattedWell = (well) => {
  const formattedWell = {
    ...well,
    ethene_sensor: well?.ethene_sensor,
    filtered: {},
    zone_sets: well?.zone_sets,
    index_curve: well?.log_sets?.RAW?.index_curve,
    cutoff: well?.modules_parameters?.CUTOFF?.settings?.cutoffs || []
  };

  Object.keys(well?.log_sets || {}).forEach(key => {
    const logSetData = well.log_sets[key]?.data ?? [];
    const logSetKey = key == 'RAW' ? key : key.toLowerCase()

    if (logSetKey !== 'cutoff') {
      const logSetObject = logSetData.reduce((acc, row) => {
        if( logSetKey === 'RAW' && row.mnemonic == formattedWell?.index_curve?.mnemonic) formattedWell.index_curve = row;

        if (acc[row.type] === undefined ) {
          acc[row.type] = row;
        } else if (!Array.isArray(acc[row.type])) {
          acc[row.type] = [acc[row.type], row];
        } else {
          acc[row.type].push(row);
        }
        return acc;
      }, {});
      formattedWell[logSetKey] = logSetObject;
    }

  });

  return formattedWell;
};
