import React from 'react'
import styled from 'styled-components'
import { Image } from '../../../components'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const Title = styled.h4`
  color: white;
`

const Header = ({ onClose }) => {
  return(
    <Wrapper>
      <Title>New model</Title>
      <Image alt="close" width={12} height={12} name="close" onClick={onClose} />
    </Wrapper>
  )

}

export default Header
