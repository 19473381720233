import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as wellActions from 'actions/wells';
import * as zoneActions from 'actions/zone';

import Login from './LoginPage';

const Root = () => {
  return <div><Login /></div>;
};

const mapStateToProps = ({ zone, wells, wellphases }, state) => ({
  zone,
  wells,
  wellphases,
  state
});

const mapDispatchToProps = dispatch => ({
  zoneAction: bindActionCreators(zoneActions, dispatch),
  wellAction: bindActionCreators(wellActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Root);
