export const defaultConfigAxis = {
  yMin: 0,
  yMax: 100,
  xMin: 6000,
  xMax: 7000
};


function getRandColor(darkness) {
  
  let rgb = [Math.random() * 256, Math.random() * 256, Math.random() * 256];
  
  let mix = [darkness * 51, darkness * 51, darkness * 51]; //51 => 255/5
  let mixedrgb = [rgb[0] + mix[0], rgb[1] + mix[1], rgb[2] + mix[2]].map(function (x) {
      return Math.round(x / 2.0)
  })

  return "rgb(" + mixedrgb.join(",") + ")";
}

export const MLMetricsChart = (data, xAxis, yAxis) => ({
  chart: {
    type: 'scatter',
    height: 425,
    width: 921,
    marginBottom: 90,
    marginTop: 30
  },
  title: {
    text: `${xAxis} vs ${yAxis}`,
  },
  xAxis: {
    title: {
      text: xAxis,
    },
  },
  yAxis: {
    title: {
      text: yAxis,
    },
  },
  series: [
    {
      name: `${xAxis} vs ${yAxis}`,
      data: data,
      color: getRandColor(5)
    }
  ],
  legend: {
    align: 'right',
    layout: 'vertical',
    floating: true,
    symbolWidth: 40
  },
  tooltip: {
    enabled: false
  },
  credits: {
    enabled: false
  }
});

