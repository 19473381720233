/* eslint-disable */
import React, {
  memo,
  useEffect,
  useState
} from 'react';

import ChartContainer from 'components/Shared/ChartContainer';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import { handleSelectArea } from 'utils';

import ChartHeader from './ChartHeader';
import { getConfig } from './config';
import RenderChart from './RenderChart';

Exporting(Highcharts);

const createSelectionId = id => `selection${id}`;

const defaultSelectionColor = 'rgba(51, 92, 173, 0.25)';

const createPlotSlection = (min, max, id, color = defaultSelectionColor) => ({
  from: min,
  to: max,
  color,
  id
});

const minus = y => x => x - y;

const getMinSelection = minus(200);

const createChart = (
  handleSelect,
  config,
  id,
  afterSelect,
  selectedId
) => {
  const chart = new Highcharts.Chart(id, {
    ...config,
    chart: {
      ...config.chart,
      events: {
        selection: event => handleSelect(
          chart,
          afterSelect,
          selectedId
        )(event)
      }
    }
  });
  return chart;
};

const TotalDepth = ({
  afterSelect, id, data, onAfter,
  unit, selectedWell, zoomType
}) => {
  const [state, setState] = useState({
    selectedId: 0,
    data: [],
    selectedWell: null,
    unit: ''
  });
  const [chart, setChart] = useState(null);

  const config = getConfig(data, zoomType);

  const createSeriesChart = () => createChart(
    handleSelectArea,
    config,
    id,
    afterSelect,
    state.selectedId
  );

  useEffect(() => {
    const chartInstance = createSeriesChart();
    setChart(chartInstance);
    return () => {
      chart && chart.destroy();
    };
  }, []);

  useEffect(() => {
    setState({
      ...state,
      unit
    });
  }, [unit]);

  useEffect(() => {
    const chartInstance = createSeriesChart();

    setChart(chartInstance);
    setState({
      ...state,
      data,
      selectedWell
    });

    const end = data.length;

    const min = getMinSelection(end);
    const max = end;

    const [xAxis] = chartInstance.xAxis;
    const [firstSerie] = xAxis.series;

    const id = createSelectionId(state.selectedId);
    const plotSelection = createPlotSlection(min, max, id);
    xAxis.addPlotBand(plotSelection);

    if (onAfter) {
      onAfter(firstSerie.data);
    }
  }, [data.length]);

  return (
    <ChartContainer>
      <ChartHeader unit={unit} />
      <RenderChart id={id} />
    </ChartContainer>
  );
};

TotalDepth.defaultProps = {
  id: 'total-depth',
  zoomType: 'x',
  withZoom: false,
  parentContainer: '.charts-wrapper',
  data: []
};

export default memo(TotalDepth);

