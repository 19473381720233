import React, { useEffect, useState, createContext, useContext } from 'react';
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import store from 'store';
import get from 'lodash.get';
import { GeoCopilotLithoDataFormatter, lithoDataFormatter } from '../utils';
import { receiveRealtimeLithology } from "../actions/wells";

export const GEOCOPILOT_API_BASE_URL = `https://geocopilot-oofp55ubjq-uc.a.run.app/geocopilot`

const initialState = {
  isLoading: false,
  setIsLoading: () => undefined,
  geoCopilotParameters: {
    "well_name": '',
    "top": 0,
    "base": 0,
    "parameters": [],
    "reasoning": "When ROP is low, it is Shale",
    "use_cuttings_description": false,
  },
  interpretations: [
    {
      "top": 0,
      "base": 10,
      "lithology": "rock_shale",
      "explanation": "Shale because ROP was low"
    },
    {
      "top": 11,
      "base": 21,
      "lithology": "rock_sandstone",
      "explanation": "Sandstone because ROP was high"
    }
  ]
}

export const GeoCopilotContext = createContext(initialState)

export const GeoCopilotProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [wellInfo, setWellInfo] = useState();
  const [payload, setPayload] = useState();
  const [response, setResponse] = useState();
  const [interpretations, setInterpretations] = useState();
  const [curvesParameters, setCurvesParameters] = useState();
  const [geoCopilotParameters, setGeoCopilotParameters] = useState(initialState.geoCopilotParameters);
  const wells = useSelector(state => state.wells) || {};
  const currentWell = get(wells, 'currentWell', null);

  const dispatch = useDispatch();

  const isValidCurve = (curveValues) => {
    return (
      Array.isArray(curveValues) &&
      curveValues.every((value) => value === null || typeof value === 'number') &&
      curveValues.length >= currentWell.depth.length
    );
  };

  const accessToken = store.get('automud-token');

  useEffect(() => {
      const selectedWellInfo = store.get('selectedWellInfo');
      if (selectedWellInfo && !wellInfo) {
          setWellInfo(selectedWellInfo);
      }
  }, [store.get('selectedWellInfo')])

  const sendGeocopilotParams = async (payload) => {
      
    if (accessToken && accessToken != null) {
      const url = `${GEOCOPILOT_API_BASE_URL}/lithologies`
      const config = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'access-token': accessToken,
          'Access-Control-Allow-Origin': '*'
        },
        url: url,
        data: payload,
      };

      axios(config)
        .then(function (response) {
          const data = response.data
          console.log(response)
          setResponse({ type: 'success', message: response.message })
          setInterpretations(data.interpretations)
        })
        .catch(function (error) {
          setResponse({ type: 'error', message: error.message })
          console.error(response.message)
        }).finally(() => {
          setIsLoading(false);
        })
      }
  }

    function mockSendGeocopilotParams(data) {
      console.log(data)
      setInterpretations(initialState.interpretations)
    }

    function handleGeoCopilotResponse(data) {
      console.log(data)
      const dataFormated = GeoCopilotLithoDataFormatter(data);
      dispatch(receiveRealtimeLithology(dataFormated));
    }

    useEffect(() => {
      if (currentWell) {
        const depthKey = 'depth'
        // find object which are arrays and not null
        const curves = Object.keys(currentWell).reduce((acc, curveKey) => {
            if (Array.isArray(currentWell[curveKey]) && currentWell[curveKey].length) {
                if (isValidCurve(currentWell[curveKey])) {
                    acc.push(curveKey);
                }
            }
            return acc;
        }, []);
          // this is hardcoded for now
        const curvesToExclude = [
            'ic4Normal', 'ic5Normal', 'c1Byc2', 'ic4BynC4', 'ic5BynC5', 'wetnessRatioData',
            'balanceRatioData', 'characterRatioData', 'c1Composition', 'c2Composition',
            'c3Composition', 'nc4Composition', 'nc5Composition', 'slowFactor', 'gammaRay',
            'totalCarbonOnly', 'totalGas'
        ]
        // if the curve is not in the curvesToExclude array, then we can add it to the curvesNames
        const curvesToInclude = curves.filter((curve) => !curvesToExclude.includes(curve));
        curvesToInclude.push('')
        setCurvesParameters(curvesToInclude);
      }
    }, [currentWell]);

    useEffect(() => {
        if (payload) {
          //sendGeocopilotParams(payload);
          console.log('geoCopilot Payload to send =>', payload)
        }
    }, [payload])

    useEffect(() => {
        if (interpretations) {
          //sendGeocopilotParams(payload);
          handleGeoCopilotResponse(interpretations)
        }
    }, [interpretations])

    return (
      <GeoCopilotContext.Provider value={{
        isLoading,
        setIsLoading,
        payload,
        setPayload,
        wellInfo,
        setWellInfo,
        interpretations,
        curvesParameters,
        initialState,
        sendGeocopilotParams,
        mockSendGeocopilotParams,
        geoCopilotParameters,
        setGeoCopilotParameters,
        response
      }}>
          {children}
      </GeoCopilotContext.Provider>
    )
}

const useGeoCopilotContext = () => {
  const context = useContext(GeoCopilotContext)

    if (context === undefined) {
      throw new Error('useGeoCopilot must be used within a GeoCopilotProvider')
    }
  return context
}

export default useGeoCopilotContext;
