/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import { getEthenenAndDepth, findOnIndexDB } from '../../../utils';
import ChartContainer from '../../Shared/ChartContainer';
import RenderChart from './RenderChart';
import { MLMetricsChart, defaultConfigAxis } from './chart';
import { Select } from 'antd';
import './style.scss';

const { Option } = Select;

Exporting(Highcharts);

const colors = Highcharts.getOptions().colors.map(color =>
  Highcharts.color(color).setOpacity(0.5).get()
);


const createChart = (
  config,
  id
) => {
  const chart = new Highcharts.chart(id, {
    ...config,
    chart: {
      ...config.chart
    }
  });
  return chart;
};

const SelectDataMetric = ({ metrics, onXAxisChange, onYAxisChange }) => {
  const [selectedXAxis, setSelectedXAxis] = useState();
  const [selectedYAxis, setSelectedYAxis] = useState();

  const handleXAxisChange = value => {
    setSelectedXAxis(value);
    onXAxisChange(value);
  };

  const handleYAxisChange = value => {
    setSelectedYAxis(value);
    onYAxisChange(value);
  };

  return (
    <div style={{ display: 'flex', width: '100%', gap: '8px' }}>
      <div>
        <label>Select X Axis:</label>
        <Select
          placeholder="Select X Axis"
          style={{ width: '100%', minWidth: '200px' }}
          value={selectedXAxis}
          onChange={handleXAxisChange}
        >
          {metrics.map(metric => (
            <Option key={metric}>{metric}</Option>
          ))}
        </Select>
      </div>
      <div>
        <label>Select Y Axis:</label>
        <Select
          placeholder="Select Y Axis"
          style={{ width: '100%', minWidth: '200px' }}
          value={selectedYAxis}
          onChange={handleYAxisChange}
        >
          {metrics.map(metric => (
            <Option key={metric}>{metric}</Option>
          ))}
        </Select>
      </div>
    </div>
  );
};


const MachineLearningMetrics = ({ experimentData }) => {
  const [scattersConfig, setConfig] = useState();
  const [chart, setChart] = useState(null);
  const [currentChart, setCurrentChart] = useState();
  const [xAxis, setXAxis] = useState('');
  const [yAxis, setYAxis] = useState('');

  const getDataMetricsML = (data) => {
    const metricsArray = data.map((item) => item.metrics);

    const newDataArray = {}

    metricsArray.forEach(obj => {
      Object.keys(obj).forEach(key => {
        let parsedKey = key.replaceAll('_'," ").toUpperCase();

        if (newDataArray[parsedKey]) {
          newDataArray[parsedKey].push(obj[key]);
        } else {
          newDataArray[parsedKey] = [obj[key]];
        }
      });
    });

    return newDataArray;
  }

  const handleXAxisChange = selectedXAxis => {
    setXAxis(selectedXAxis);
  };

  const handleYAxisChange = selectedYAxis => {
    setYAxis(selectedYAxis);
  };

  const newDataArray = getDataMetricsML(experimentData)

  const data = newDataArray[xAxis] && newDataArray[yAxis] ? newDataArray[xAxis].map((x, index) => [x, newDataArray[yAxis][index]]) : [];

  useEffect(() => {

    (async () => {

      const config = MLMetricsChart(data, xAxis, yAxis);
      const chartInstance = createChart(config, 'ml-metrics');
      setChart(chartInstance);
    })();

  }, [xAxis, yAxis]);


  return (
    <div className="scatters-ml-metrics">
      <div className="scatter-header-ml-metrics">
        <div className="scatter-info-ml-metrics">
          <h3 className="scatter-title-ml-metrics">
            Comparison Metrics
          </h3>
          <SelectDataMetric metrics={Object.keys(newDataArray)}
            onXAxisChange={handleXAxisChange}
            onYAxisChange={handleYAxisChange} />
          {scattersConfig && (
            <h3 className="scatters-ml-metrics-range">
              {`Min: ${scattersConfig.minLabel} - Max: ${scattersConfig.maxLabel}`}
            </h3>
          )}
        </div>
      </div>
      <div className="ml-metrics-container">
        <ChartContainer>
          <RenderChart id="ml-metrics" />
        </ChartContainer>
      </div>
    </div>
  );
}

export default MachineLearningMetrics;
