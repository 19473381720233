import Axios from 'axios';
import { WellMetadata, ResponseBase } from './types/import-data-types';
import store from 'store';

const API_URL = 'https://wellex-wells-oofp55ubjq-uc.a.run.app';

const resource = 'wellex-wells/v1';

export function parseLasFile(data: Blob, token: string): ResponseBase<WellMetadata> {
  return Axios.post(
    `${API_URL}/${resource}/data-import/las`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
}

export function parseWellDataIntegration(data: Blob, token: string): ResponseBase<WellMetadata> {
  return Axios.post(
    `${API_URL}/${resource}/data-import/wellex-data-integrations`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
}

export function parseAndSaveLithologyData(data: Blob, token: string): ResponseBase<WellMetadata> {
  return Axios.post(
    `${API_URL}/${resource}/data-import/lithology`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
}

export function saveParsedData(data: WellMetadata, token: string) {
  return Axios.post(
    `${API_URL}/${resource}/wells`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
}

export function listWellsMetadata(token: string) {
  return Axios.get(
    `${API_URL}/${resource}/wells`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
}

export function getWellData(wellUid: string) {
  return Axios.get(
    `${API_URL}/${resource}/wells/${wellUid}/data`,
    {
      headers: {
        Authorization: `Bearer ${store.get('token')}`
      }
    }
  )
}

export function updateWellData(data: any, wellUid: string) {
  return Axios.put(
    `${API_URL}/${resource}/wells/${wellUid}/raw`,
    data,
    {
      headers: {
        Authorization: `Bearer ${store.get('token')}`
      }
    }
  )
}
