import React from "react"
import ErrorPopover from "../ErrorPopover"
import { Wrapper, StyledInput, StyledTextArea, StyledLabel } from "./styles"
import { useController } from "react-hook-form"

const Input = ({ id, label, name, placeholder, control, errors, width, ...props }) => {
  const { field } = useController({ name, control })

  return (<>
    {errors ?
        <ErrorPopover
            content={errors ? <span style={{ color: '#fff', margin: '2px' }}>{errors.message.message || errors.message}</span> : null}
            style={{ backgroundColor: '#333', width: 200 }}
        >
          <Wrapper>
            <StyledLabel htmlFor={id}>{label}</StyledLabel>
              {props.textarea ?
                <StyledTextArea id={id}
                  value={field.value}
                  onChange={field.onChange}
                  placeholder={placeholder}
                  rows={props.rows}
                  onBlur={field.onBlur}
                  onFocus={props.onFocus}
                  aria-invalid={errors ? "true" : "false"}
                  {...props}
                /> :
                <StyledInput id={id}
                  value={field.value}
                  onChange={field.onChange}
                  placeholder={placeholder}
                  onBlur={field.onBlur}
                  onFocus={props.onFocus}
                  aria-invalid={errors ? "true" : "false"}
                  {...props}
            />}
          </Wrapper>
          </ErrorPopover> :
          <Wrapper>
            <StyledLabel htmlFor={id}>{label}</StyledLabel>
            {props.textarea ?
              <StyledTextArea id={id}
                value={field.value}
                onChange={field.onChange}
                placeholder={placeholder}
                rows={props.rows}
                onBlur={field.onBlur}
                onFocus={props.onFocus}
                aria-invalid={errors ? "true" : "false"}
                {...props}
              /> :
              <StyledInput id={id}
                value={field.value}
                onChange={field.onChange}
                placeholder={placeholder}
                onBlur={field.onBlur}
                onFocus={props.onFocus}
                aria-invalid={errors ? "true" : "false"}
                {...props}
              />}
          </Wrapper>}
    </>)
}

export default Input
