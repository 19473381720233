import { Button } from 'antd';
import React, { useState, useEffect } from 'react';
import ToggleButton from 'react-toggle-button';
import store from 'store';

const ConfigTriggerButton = ({openModal, style, filterBy, title}) => {
    const InitialValue = store.get('openConfigCurve');
    const [isActivated, setActive] = useState(false);

    const toggleActive = () => {
        const currentValue = !isActivated;
        setActive(currentValue);
        store.set('openConfigCurve', currentValue);
        openModal(filterBy, title);
    };

    return (
        <div style={{...style, display: 'flex', color: '#fff' }} >
            <Button type="dashed" shape="circle" icon="setting" onClick={toggleActive} />
        </div>
    );
};

export default ConfigTriggerButton;
