import React, { useState, useEffect } from 'react'

import useExperiments, { ExperimentProvider } from '../../hooks/useExperiments';
import store from 'store';
import { trainModel } from "api/mlaas";

import { successNotification, errorNotification } from '../../utils';

import { Container, Content, GlobalStyle } from './styles'
import { Button, Tabs, Box } from '../../components'
import Experiment from './Experiment'
import Training from './Training'
import Header from './Header'
import { sendBuildDataset } from '../../api/mlaas';

import Overlay from 'components/Overlay';

const tabs = [
  'Model Settings',
  'Training Settings'
]

const Models = ({ closeModal }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { trainingData, trainColumns, dataset, curves, hyperparameterTuning, buildDataset, setDataset, mountSchema } = useExperiments()
  const [step, setStep] = useState(0);
  const token = store.get('token')

  const sendData = async () => {
    setIsLoading(true)
    try {
      const response = await trainModel(trainingData, token)

      successNotification(response.data.message)
    } catch (err) {
      if (err instanceof Error) {
        errorNotification(err.message)
      }
    }
    setIsLoading(false)
    closeModal();
  }

  const handleAction = async () => {
    if (step === 0) {

      if (trainingData.name) {
        buildDataset(trainColumns)
        setStep(1)
      } else {
        errorNotification('Please, select a name for the model')
      }

      // } else {
      //   errorNotification('Please, select also the depth curve')
      // }
    } else {
      setIsLoading(true)
      if (dataset?.uid) {
        try {
          sendData();
        } catch (err) {
          console.log(err);
          errorNotification('Error while training model');
        }
        return;
      }
      const { data, status } = await sendBuildDataset(mountSchema, token)
      setDataset(data.dataset)
      if (status == 200) {
        successNotification('Dataset created successfully');
        setIsLoading(false);
      }
    }
  }

  const validateAction = () => {
    if (isLoading) return true

    if (step === 0) {
      return curves && !curves.length
    }

    return hyperparameterTuning ? !hyperparameterTuning.length : false
  }

  const renderButtonText = () => {
    if (isLoading) return 'Loading...'
    if (step === (tabs.length - 1)) {
      if (dataset?.uid) {
        return 'Train'
      }
      return 'Build Dataset'
    }

    return 'Next'
  }

  return (
    <Container className='models-container'>
      {dataset && <Overlay show={isLoading} />}
      <Content>
        <Header onClose={closeModal} />
        <Tabs
          className="tabs-container"
          selectedIndex={step}
          tabs={tabs}
        >
          <Experiment />
          <Training />
        </Tabs>

        <Box display="flex" mt="0.5rem" justifyContent="flex-end">
          <Button onClick={closeModal} mr="1rem" variant="link">Cancel</Button>
          <Button onClick={handleAction} disabled={validateAction()}>{renderButtonText()}</Button>
        </Box>
      </Content>
    </Container>
  )
}

const ModelsWithProvider = (props) => (
  <ExperimentProvider>
    <GlobalStyle />
    <Models {...props} />
  </ExperimentProvider>
)

export default ModelsWithProvider
