import * as yup from "yup";

export const InitiaLithologyEditingParameters = {
    top: 0,
    bottom: 0,
    selected_lithology: null,
}
const PERCENTAGE = 100

export const lithologyTypes = ['interpreted', 'aiPrediction'];

export const EditParametersSchema = yup.object({

    top: yup.number()
        .required({ message: 'this field is required!' }),
    bottom: yup.number()
        .positive({ message: 'must be greater than 0!' })
        .when('top', (top, schema) => {
            return schema.test({
                test: bottom => !!top && bottom > top,
                message: `the value of R100 must be higher than TOP!`
            })
        })
        .required({ message: 'this field is required!' }),
    'select_lithology': yup.string().nullable()
}).required()

function fillArrayBetweenRange(startIndex, endIndex, value) {
    let array = new Array(endIndex + 1).fill(0); 
    for (let i = startIndex; i <= endIndex; i++) {
        array[i] = value; 
    }
    return array;
}

function updateLithology(lithologyKeys, lithology, min, max, percentage) {
    return lithologyKeys.reduce((acc, value) => {
        let currentLithology = lithology[value];
        acc[value] = currentLithology.map((litho, index) => {
            if (index > min && index < max) {
                return percentage;
            }
            return litho;
        });
        return acc;
    }, {});
}

function updateSingleLithology(currentLithology, min, max, percentage) {
    return currentLithology.map((litho, index) => {
        if (index > min && index < max) {
            return percentage;
        }
        return litho;
    });
}

export function clearAndReplaceData(lithology, data, totalLength, min, max) {
    const lithologyKeys = Object.keys(lithology) ?? [];
    let newLithology = { ...lithology };
    if (lithologyKeys.includes(data.selected_lithology)) {
        lithologyKeys.forEach((value) => {
            if (value === data.selected_lithology) {
                newLithology[value] = updateSingleLithology(lithology?.[value],min, max, PERCENTAGE)
            } else {
                newLithology[value] = updateSingleLithology(lithology?.[value], min, max, 0)
            }
        });
    } else {
        newLithology = updateLithology(lithologyKeys, lithology, min, max, 0)
        newLithology[data.selected_lithology] = fillArrayBetweenRange(min, max, PERCENTAGE)
    }
    return newLithology;
}


