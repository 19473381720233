export function validateFormSubmit(wells, settingsDetails) {
  const inputSchema = Object.values(settingsDetails);
  console.log('well', wells);

  if (wells && wells.currentWell) {
    const data = inputSchema.reduce((acc, curveName) => {
      const curveExists =
        wells?.currentWell?.RAW && wells?.currentWell?.RAW?.[curveName].data;
      const isValidCurve = curveExists && inputSchema.includes(curveName);

      if (isValidCurve) {
        try {
          acc[curveName] = wells?.currentWell?.RAW?.[curveName].data || [];
        } catch (error) {
          console.log('Error when trying to map curve:', error);
        }
      }
      console.log('acc', acc);
      return acc;
    }, {});
    console.log('data', data);
    return data;
  }
}

export const formattedDataToHaveSameLength = (
  data,
  settingsDetails,
  isValidCurve
) => {
  const inputSchema = Object.values(settingsDetails);
  const curvesKeys = Object.keys(settingsDetails);
  const depthLength = data && data['depth']?.length ? data['depth']?.length : 0;

  let result = {};

  curvesKeys.map(curveName => {
    if (inputSchema.includes(settingsDetails[curveName])) {
      if (data[settingsDetails[curveName]]) {
        if (data[settingsDetails[curveName]].length === 0) {
          result[curveName] = Array.from({ length: depthLength }, () => 0);
        }
        if (isValidCurve(data[settingsDetails[curveName]], depthLength)) {
          result[curveName] = data[settingsDetails[curveName]].slice(
            0,
            depthLength
          );
        }
      }
      // else {
      //     // the actual value is inside the first array, second element
      //     result[curveName] = data[settingsDetails[curveName]].map((array) => array[1][0]).slice(0, depthLength);
      // }
    }
  });

  return result;
};

export const validateSchema = (allData, schema) => {
  const dataKeys = Object.keys(allData);

  const isSchemaValid = schema.every(key => dataKeys.includes(key));
  const areValuesValid = schema.every(
    key => allData[key] !== null && allData[key] !== undefined
  );
  return isSchemaValid && areValuesValid;
};

export const validateLength = (allData, schema) => {
  // validate if all arrays inside allData have the same length
  const allDataKeys = Object.keys(allData);
  let isValid = true;
  schema.forEach(key => {
    try {
      if (allData[key] && allData.depth) {
        // if lengths are different or if there is a missing key
        if (
          allData[key].length !== allData.depth.length ||
          !allDataKeys.includes(key)
        ) {
          isValid = false;
        }
      }
    } catch (error) {
      console.log('error validating length', error);
      isValid = false;
    }
  });
  return isValid;
};

export const addMatchingSchema = (initialData, data) => {
  let contLogSet = 0;
  let contMnemonic = 0;

  Object.keys(data).forEach(key => {
    const [type, index, logset] = key.split('_');
    const numericIndex = parseInt(index, 10);
    
    if (type === 'logset' && initialData && initialData[index] && data && data[key]) {
        if(!initialData[index].log_set_name_matching_schema) {
          initialData[index].log_set_name_matching_schema = data[key];
          contLogSet++;
        } else {
          initialData[contLogSet+numericIndex].log_set_name_matching_schema = data[key];
        }
    } else if (type === 'mnemonic' && initialData && initialData[index] && data && data[key]) {
        if(!initialData[index].curve_mnemonic_matching_schema) {
          initialData[index].curve_mnemonic_matching_schema = data[key];
          contMnemonic++;
        } else {
          initialData[contMnemonic+numericIndex].curve_mnemonic_matching_schema = data[key];
        }
    }
});

    return initialData;
}
