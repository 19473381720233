import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { StyledDrawer } from './styles';
import SendIcon from '../../assets/send.png';
import GeoCopilotForm from './GeoCopilotForm';
import { GeoCopilotProvider } from '../../hooks/useGeocopilot';
import GeoCopilotResults from './GeoCopilotResults';
import Text from '../Text';


function GeoCopilotDrawer({ visible, OnClose, currentWell, ...props }) {
    const [flag, setFlag] = useState(visible)
    const [seeResults, setSeeResults] = useState(false)

    useEffect(() => {
        if (visible) {
          setFlag(true);
        } else {
          setTimeout(() => {
            setFlag(false);
          }, 500);
        }
    }, [visible]);

    const handleAskGeoCopilot = () => {
      console.log("Ask GeoCopilot button clicked");
      setSeeResults(!seeResults);
    };

  return (
    <>
      <GeoCopilotProvider>
        {flag && (
          <StyledDrawer
            title="GeoCopilot"
            width={520}
            placement={'right'}
            onClose={OnClose}
            visible={visible}
          >
            {!seeResults ?
                <GeoCopilotForm
                  onCancel={OnClose}
                  currentWell={currentWell}
                  minDepth={props.minDepth}
                  maxDepth={props.maxDepth}
                  setSeeResults={setSeeResults}
                />
                :
                  <>
                    <div style={{ display: 'flex', marginTop: '10px', width: '100%', gap: '16px' }}>
                        <Button
                          type="primary"
                          style={{ background: '#1E97C2', color: '#1D1C22' }}
                          onClick={handleAskGeoCopilot}
                        >
                          <img width={16} style={{ marginRight: 6, top: 6, position: 'absolute', left: 10 }} src={SendIcon} />
                          <span style={{ marginLeft: 15 }}>Ask GeoCopilot</span>
                        </Button>
                    </div>
                    <div style={{ marginTop: '10px' }}>
                      <Text variant={'title'}>Results</Text>
                    </div>
                    {seeResults && <GeoCopilotResults />}
                  </>}
                </StyledDrawer>
              )}
          </GeoCopilotProvider>
      </>);
}

export default GeoCopilotDrawer;

