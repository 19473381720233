import React from 'react'
import { StyledButton } from './styles'

import Text from '../Text'
import { Box, Image } from '..'

const SelectedItem = ({ children, onRemove }) => (
  <StyledButton>
    <span><Text variant="paragraph">{children}</Text></span>
    <Image name="close" width={8} height={8} onClick={onRemove} />
  </StyledButton>
)

const SelectedItems = ({ title, data, onRemove }) => {
  if (!data.length) return null

  return (
    <>
      {title ? <Text variant="paragraph">{title}</Text> : null}
      <Box display="flex" flexWrap="wrap" flexGrow="1" >
        {data.map(({ id, field }) => (
          <SelectedItem key={id} onRemove={() => onRemove({ id, field })}>{field}</SelectedItem>
        ))}
      </Box>
    </>
  )
}

export default SelectedItems
