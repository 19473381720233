import React, { useState } from "react"
import { StatusBadgeGreen, StatusBadgeRed, StatusBadgeGray, GridContent, Content, MetricsItem } from './Styles'
import { Box, Text, Button } from "../../components"

const ExperimentCard = ({ status, start_time, end_time, description = '', register_info, metrics, run_id, onAddModelClick }) => {
  const registryStatus = register_info && register_info.registry_status ? register_info.registry_status : 'N/A'
  const modelName = register_info && register_info.model_name ? register_info.model_name : 'N/A';
  const parsedMetrics = Object.keys(metrics).map((key) => ({ field: key.replaceAll("score__", ""), value: metrics[key] })).filter(({ field }) => !!field)
  const timeData = { status, start_time, end_time }
  const timeContent = ['status', 'start_time', 'end_time']
    .map((field = 'N/A') => ({ field: field.replace('_', ' '), value: timeData[field] || field }))

  const renderTime = () => {
    if (!timeContent.length) return null

    return (
      <Box
        className="date-container"
        display="flex"
        mt="1.5rem"
        justifyContent="start"
      >
        {timeContent.map(({ field, value }) => (
          <Box className="start" display="flex" flexDirection="column" mr="1.5rem">
            <Text variant="paragraph">{field}</Text>
            <Text variant="subtitle">{value}</Text>
          </Box>
        ))}
      </Box>
    )
  }

  const renderMetrics = () => {
    if (!parsedMetrics.length) return null

    return (
      <Box mt="1.5rem">
        <Text variant="subtitle">Evalution Metrics</Text>
        <GridContent>
          {parsedMetrics.filter((item) => !item.field.includes("Importance")).map(({ field, value }) => (

            <MetricsItem >
              <Text variant="paragraph">{field}</Text>
              <Text variant="title">{value}</Text>
            </MetricsItem>
          ))}
        </GridContent>
        <Text variant="subtitle">Importance Metrics</Text>
        <GridContent>
          {parsedMetrics.filter((item) => item.field.includes("Importance")).map(({ field, value }) => (

            <MetricsItem >
              <Text variant="paragraph">{field.replaceAll("Importance", "")}</Text>
              <Text variant="title">{value}</Text>
            </MetricsItem>
          ))}
        </GridContent>
      </Box>
    )
  }

  const isRegistryActive = registryStatus.match(/Active/gi)
  const isRegistryActiveRed = registryStatus.match(/Not registered/gi)
  const isRegistryActiveGray = registryStatus.match(/Archived/gi)

  return (
    <Content display="flex" flexDirection="column" p="2.5rem" mt="1rem">
      <Box display="flex" justifyContent="space-between">
        <Box display="flex">
          <Text variant="title">Registry Status</Text>
          <Box p="0 0.5rem">
            {status === 'FINISHED' && isRegistryActive &&
              <StatusBadgeGreen>{registryStatus}</StatusBadgeGreen>}
            {status === 'FINISHED' && isRegistryActiveRed &&
              <StatusBadgeRed>{registryStatus}</StatusBadgeRed>}
            {status === 'FINISHED' && isRegistryActiveGray &&
              <StatusBadgeGray>{registryStatus}</StatusBadgeGray>}
          </Box>
        </Box>
        <Box display="flex" justifyContent="end">
          {status === 'FINISHED' && !isRegistryActive &&
            <Button variant='outlined' onClick={() => onAddModelClick(run_id)}>+ Add Model</Button>}
        </Box>
      </Box >
      <Box display="flex" flexDirection="column" mt="1.5rem">
        <Text variant="subtitle">Description</Text>
        <Box mt="0.5rem">
          <Text variant="paragraph">{description}</Text>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" mt="1.5rem">
        <Text variant="paragraph">Model</Text>
        <Text variant="subtitle">{modelName}</Text>
      </Box>
      {renderTime()}
      {renderMetrics()}
    </Content >
  )
}

export default ExperimentCard
