import get from 'lodash.get';

import * as ACTIONS from '../constants/wells';
import { createReducer } from './createReducer';

const initialState = {
  items: [],
  currentWell: null,
  selectedWell: -1,
  depthData: [],
  wellOptions: [],
  selectedDepthIndex: null,
  beforeWell: null,
  trendLines: null,
  calculatingGeopressure: false,
  chartsView: {
    mse: true,
    gr: true,
    porosity: true,
    cuttings: true,
    interpreted: true,
    realtime: true,
    scores: true,
    chromatography: true
  }
};

const generateWellOption = (wellName, wellId) => ({
  label: wellName || '',
  value: wellId
});

function filterWell(state, action) {
  const { wellId } = action;
  const filteredWell = state.items.find(well => {
    if (well.wellId === wellId || well.wellInfo && well.wellInfo.wellId === wellId) {
      return true;
    }
    return false;
  });
  return {
    ...state,
    selectedWell: get(filteredWell, 'wellId', -1),
    currentWell: filteredWell || null,
    wellOptions: filteredWell ? state.wellOptions : [],
    depthData: filteredWell ? state.depthData : [],
    beforeWell: filteredWell || null
  };
}

function receiveWell(state, action) {
  let { well } = action;

  const currentWell = state.items.find(w => w.uid === well.well_uid);
  const { well_uid: wellUid } = well;

  let wellOptions = state.wellOptions;

  let items = state.items;
  if (currentWell) {
    items = items.map(w => {
      if (w.uid === wellUid) {
        return { ...well, ...w };
      }
      return w;
    });
  } else {
    // when crating well that well options is not listed we need to push the new value into the wells list
    items = [...items, well];
    wellOptions = items.map(wellValue => {
      const wellName = get(wellValue, 'name', get(state.metadata, 'name', wellValue.uid));
      const wellOption = generateWellOption(wellName, wellValue.uid);
      return { ...wellOption, wellId: wellValue.uid };
    });
  }

  return {
    ...state,
    items,
    selectedDepthIndex: 0,
    wellOptions,
    currentWell: { ...currentWell, ...well, statistics: [] },
    selectedWell: wellUid,
    currentWellKey: wellUid,
    beforeWell: { ...currentWell, ...well, statistics: [] },
  };
}

function receiveWells(state, action) {
  const { wells } = action;
  const wellOptions = wells.map(well => {
    const wellName = get(well, 'name', get(state.metadata, 'name', well.uid));
    const wellOption = generateWellOption(wellName, well.uid);
    return { ...wellOption, wellId: well.wellId };
  });
  const formattedOptions = wells.map(w => ({ ...w, wellId: w.uid, wellName: w.name }));
  return {
    ...state,
    items: formattedOptions,
    wellOptions,
    wellsWithPathFile: formattedOptions
  };
}

function receiveInWell(state, action) {
  const InWellMetadata = { ...action.metadata }

  return {
    ...state,
    wellDataIntegration: { ...state.wellDataIntegration, well_metadata: InWellMetadata }
  };
}

function receiveInWellData(state, action) {
  const InWellData = { ...action.data }

  return {
    ...state,
    wellDataIntegration: { ...state.wellDataIntegration, well_data: InWellData }
  };
}

function receiveMetadata(state, action) {
  const lat_long_coordinates = {
    degrees_lat: '',
    minutes_lat: '',
    seconds_lat: '',
    degrees_long: '',
    minutes_long: '',
    seconds_long: '',
    reference: ''
  };

  const utm_coordinates = {
    x_value: '',
    y_value: '',
    zone: '',
    reference_zone: ''
  };

  const top = action.metadata?.log_sets?.[0]?.start_depth;
  const base = action.metadata?.log_sets?.[0]?.stop_depth;
  // TODO remove this mock
  const well_phases = [
    { 'top': top, 'base': base, 'diameter': 8.5 },
  ];

  const normalizeMetadata = {
    ...action.metadata,
    // lat_long_coordinates: undefined,
    lat_long_coordinates: lat_long_coordinates,
    utm_coordinates: utm_coordinates,
    well_phases: well_phases
  };

  return {
    ...state,
    metadata: normalizeMetadata
  };
}

function deleteWell(state, action) {
  const { wellId } = action;
  const items = state.items.filter(well => well.wellId !== wellId);
  const wellOptions = state.wellOptions.filter(well => well.value !== wellId);
  return {
    ...state,
    selectedWell: -1,
    currentWellKey: null,
    depthData: [],
    currentWell: null
  };
}

function receiveDepthData(state, action) {
  return {
    ...state,
    depthData: action.data
  };
}

function updateWell(state, action) {
  const { well } = action;
  const items = state.items.map(item => item.id === state.selectedWell ? { ...item, ...well } : item);
  const currentWell = items.find(currentWell => currentWell.id === well.id);

  const wellOptions = state.wellOptions.map(item => item.value === well.id
    ? generateWellOption(currentWell.name, currentWell.id) : item
  );

  console.log(`updateWell `, {
    currentWell: well,
    items,
    newOptions: state.wellOptions,
    wellOptions,
  });
  return {
    ...state,
    items,
    currentWell: well,
    beforeWell: well,
    wellOptions
  };
}

function receiveSelectedDepthIndex(state, action) {
  return {
    ...state,
    selectedDepthIndex: action.index
  };
}

function receiveTrendLinesValue(state, action) {
  return {
    ...state,
    trendLines: action.trendLines
  };
}

function startCalculateGeopressure(state, action) {
  return {
    ...state,
    calculatingGeopressure: true
  };
}

function stopCalculateGeopressure(state, action) {
  return {
    ...state,
    calculatingGeopressure: false
  };
}

function receiveRealtimeLithology(state, action) {
  return {
    ...state,
    currentWell: { ...state.currentWell, ml: { ...state.currentWell?.ml, realtimeLithology: action.data } },
    beforeWell: { ...state.beforeWell, ml: { ...state.beforeWell?.ml, realtimeLithology: action.data } }
  };
}

function receiveInterpretedLithology(state, action) {

  const lithologyData = state.currentWell?.lithology?.lithology?.data;
  let interpretedLithology = {};
  if (lithologyData) {
    interpretedLithology = { ...lithologyData, ...action.data };
  }

  return {
    ...state,
    currentWell: { ...state.currentWell, interpretedLithology },
    beforeWell: { ...state.beforeWell, interpretedLithology }
  };
}

function receiveMLPorosity(state, action) {
  return {
    ...state,
    currentWell: { ...state.currentWell, mlPorosity: action.data },
    beforeWell: { ...state.beforeWell, mlPorosity: action.data }
  };
}

function receiveLithology(state, action) {
  return {
    ...state,
    currentWell: { ...state.currentWell, lithology: action.data },
    beforeWell: { ...state.beforeWell, lithology: action.data }
  };
}

function receiveLithologyScore(state, action) {
  return {
    ...state,
    currentWell: { ...state.currentWell, lithologyScore: action.data },
    beforeWell: { ...state.beforeWell, lithologyScore: action.data }
  };
}

function receiveAiGrPrediction(state, action) {
  return {
    ...state,
    currentWell: { ...state.currentWell, aiGammaRay: action.data },
    beforeWell: { ...state.currentWell, aiGammaRay: action.data }
  };
}

function resetBeforeWell(state, action) {
  return {
    ...state,
    beforeWell: undefined,
  };
}

function updateChartsVisibility(state, action) {
  const chartKeys = {
    mse: 'mse',
    gammaray: 'gr',
    mlporosity: 'porosity',
    cuttings: 'cuttings',
    interpretedlithology: 'interpreted',
    realtimelithology: 'realtime',
    lithologyscores: 'scores',
    chromatography: 'chromatography'
  };

  const chartKey = chartKeys[action.chart?.type];
  return chartKey ? {
    ...state,
    chartsView: {
      ...state.chartsView,
      [chartKey]: action.chart?.status
    }
  } : state;

}

export default createReducer(initialState, {
  [ACTIONS.RESET_BEFORE_WELL]: resetBeforeWell,
  [ACTIONS.FILTER_WELL]: filterWell,
  [ACTIONS.RECEIVE_WELL]: receiveWell,
  [ACTIONS.RECEIVE_METADATA]: receiveMetadata,
  [ACTIONS.RECEIVE_IN_WELL]: receiveInWell,
  [ACTIONS.RECEIVE_IN_WELL_DATA]: receiveInWellData,
  [ACTIONS.RECEIVE_WELLS]: receiveWells,
  [ACTIONS.DELETE_WELL]: deleteWell,
  [ACTIONS.REGISTER_DEPTH_DATA]: receiveDepthData,
  [ACTIONS.UPDATE_WELL]: updateWell,
  [ACTIONS.RECEIVE_SELECTED_INDEX]: receiveSelectedDepthIndex,
  [ACTIONS.RECEIVE_TRENDLINES_VALUE]: receiveTrendLinesValue,
  [ACTIONS.START_CALCULATE_GEOPRESSURE]: startCalculateGeopressure,
  [ACTIONS.STOP_CALCULATE_GEOPRESSURE]: stopCalculateGeopressure,
  [ACTIONS.RECEIVE_REALTIME_LITHOLOGY]: receiveRealtimeLithology,
  [ACTIONS.RECEIVE_INTERPRETED_LITHOLOGY]: receiveInterpretedLithology,
  [ACTIONS.RECEIVE_LITHOLOGY_SCORE]: receiveLithologyScore,
  [ACTIONS.RECEIVE_LITHOLOGY]: receiveLithology,
  [ACTIONS.RECEIVE_AI_GAMMA_RAY]: receiveAiGrPrediction,
  [ACTIONS.RECEIVE_ML_POROSITY]: receiveMLPorosity,
  [ACTIONS.UPDATE_CHARTS_VISIBILITY]: updateChartsVisibility
});


