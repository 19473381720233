export const FILTER_WELL = 'FILTER_WELL';
export const RECEIVE_WELL = 'RECEIVE_WELL';
export const RECEIVE_METADATA = 'RECEIVE_METADATA';
export const RECEIVE_IN_WELL = 'RECEIVE_IN_WELL';
export const RECEIVE_IN_WELL_DATA = 'RECEIVE_IN_WELL_DATA';
export const RECEIVE_WELLS = 'RECEIVE_WELLS';
export const DELETE_WELL = 'DELETE_WELL';
export const REGISTER_DEPTH_DATA = 'REGISTER_DEPTH_DATA';
export const UPDATE_WELL = 'UPDATE_WELL';
export const RECEIVE_SELECTED_INDEX = 'RECEIVE_SELECTED_INDEX';
export const RECEIVE_TRENDLINES_VALUE = 'RECEIVE_TRENDLINES_VALUE';
export const RECEIVE_LITHOLOGY = 'RECEIVE_LITHOLOGY';
export const RECEIVE_REALTIME_LITHOLOGY = 'RECEIVE_REALTIME_LITHOLOGY';
export const RECEIVE_INTERPRETED_LITHOLOGY = 'RECEIVE_INTERPRETED_LITHOLOGY';
export const RECEIVE_LITHOLOGY_SCORE = 'RECEIVE_LITHOLOGY_SCORE';
export const RECEIVE_ML_POROSITY = 'RECEIVE_ML_POROSITY';
export const RESET_BEFORE_WELL = 'RESET_BEFORE_WELL';

export const START_CALCULATE_GEOPRESSURE = 'START_CALCULATE_GEOPRESSURE';
export const STOP_CALCULATE_GEOPRESSURE = 'STOP_CALCULATE_GEOPRESSURE';

export const RECEIVE_AI_GAMMA_RAY = 'RECEIVE_AI_GAMMA_RAY';

export const UPDATE_CHARTS_VISIBILITY = 'UPDATE_CHARTS_VISIBILITY';