import React, {useEffect, useRef, useState} from 'react';

export function useDragger(id){

    const coords = useRef({
        startX: 0,
        startY: 0,
        lastX: 0,
        lastY: 0
    })

    const isClicked = useRef(false);
    const [containerWidth, setContainerWidth] = useState(null);

    useEffect(() => {
        const draggableElement = document.getElementById(id);

        if (!draggableElement) throw new Error('Element not found');

        const container = draggableElement.parentElement;

        if(!container) throw new Error('Parent Element not found! the target element must have a parent element!');

        const containerRect = container.getBoundingClientRect();

        setContainerWidth(containerRect.width);

        const onMouseDown = (e) => {
            isClicked.current = true;
            coords.current.startX = e.clientX;
            coords.current.startY = e.clientY;
        }

        const onMouseUp = (e) => {
            isClicked.current = false;
            coords.current.lastX = draggableElement.offsetLeft;
            coords.current.lastY = draggableElement.offsetTop;
        }

        const onMouseMove = (e) => {
            if (!isClicked.current) return;
           
            const nextX = e.clientX - coords.current.startX + coords.current.lastX;
            const nextY = e.clientY - coords.current.startY + coords.current.lastY;

            const maxX = containerRect.width - draggableElement.offsetWidth;
            const maxY = containerRect.height - draggableElement.offsetHeight;

            const nextXClamped = Math.max(0, Math.min(maxX, nextX));
            const nextYClamped = Math.max(0, Math.min(maxY, nextY));

            draggableElement.style.top = `${nextYClamped}px`;
            draggableElement.style.left = `${nextXClamped}px`;
        }

        draggableElement.addEventListener('mousedown', onMouseDown);
        draggableElement.addEventListener('mouseup', onMouseUp);
        container.addEventListener('mousemove', onMouseMove);
        container.addEventListener('mouseleave', onMouseUp);

        const resizeObserver = new ResizeObserver(entries => {
            // entries is an array of observed elements
            for (let entry of entries) {
                if (entry.target === container) {
                    // Update state with the new width
                    setContainerWidth(entry.contentRect.width);
                    console.log(entry.contentRect.width)
                }
            }
        });

        if (container) {
            resizeObserver.observe(container);
        }

        const cleanup = () => {
            draggableElement.removeEventListener('mousedown', onMouseDown);
            draggableElement.removeEventListener('mouseup', onMouseUp);
            container.removeEventListener('mousemove', onMouseMove);
            container.removeEventListener('mouseleave', onMouseUp);
            if (container) {
                resizeObserver.unobserve(container);
            }
        }

        return cleanup;
    }, [id, isClicked])
}

   