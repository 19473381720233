import {
    receiveAiGrPrediction,
    receiveLithologyScore,
    receiveRealtimeLithology,
    receiveMLPorosity,
} from 'actions/wells';
import { predict, deleteModel } from '../../../../api/models';
import { successNotification, errorNotification, realTimeLithoDataFormatter } from 'utils';

export async function SendPredictPayload(tags, modelName, formatPayload, displayDrift, displaySuccessModel, dispatch, setLoading){
    try {
        const curveType = tags ? tags.curve_type : null;
        if (curveType == 'gamma_ray') {
            const gammaRayResponse = await predict(modelName, formatPayload);
            dispatch(receiveAiGrPrediction(gammaRayResponse.data.results.predictions));
            displayDrift(gammaRayResponse.data.drift_message);
            displaySuccessModel();

        } else if (curveType == 'interpreted_lithology') {
            const responseRealtimeLithology = await predict(modelName, formatPayload);
            const realtimeLithology = realTimeLithoDataFormatter(responseRealtimeLithology.data.results.predictions)
            const lithologyScores = realTimeLithoDataFormatter(responseRealtimeLithology.data.results.probabilities)
            dispatch(receiveRealtimeLithology(realtimeLithology));
            dispatch(receiveLithologyScore(responseRealtimeLithology.data.results.probabilities));
            displayDrift(responseRealtimeLithology.data.drift_message);
            displaySuccessModel();
        } else if (curveType == 'porosity') {
            const mlPorosityResponse = await predict(modelName, formatPayload);
            const porosityPredictionData = mlPorosityResponse && mlPorosityResponse.data && mlPorosityResponse.data.results
                && mlPorosityResponse.data.results.predictions ? mlPorosityResponse.data.results.predictions : [];
            const formattedPorosity = porosityPredictionData.map(v => v * 100);
            dispatch(receiveMLPorosity(formattedPorosity));
            displaySuccessModel();
        }
    } catch (e) {
        console.log('error listing predictLithology', e);
        errorNotification('Error while running prediction. Please contact support.');
    } finally {
        setLoading(false);
    }
}

export const deleteCurrentModel = async (modelUid) => {
    try {
        const response = await deleteModel(modelUid);
        // after response
        if (response.status === 200) { 
            successNotification('Model deleted successfully.');
        }
    }
    catch (e) {
        console.error('error deleting model', e);
        errorNotification('Error while deleting model. Please contact support.');
    }
};