import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import useDisplayCurves  from '../hooks/useDisplayCurves';
import useMachineLearningContext from "../../../hooks/useML";

export function ChartWithHooks(Component) {

    return function (props) {
        const wells = useSelector(state => state.wells);
        const [currentWell, setCurrentWell] = useState({});
        const { currentCurve, curves, selectCurve } = useDisplayCurves(props.filterBy);
        const { mlCurves } = useMachineLearningContext();

        useEffect(() => {
            setCurrentWell(wells.currentWell);
        }, [wells, mlCurves])

        return (<Component selectedCurve={mlCurves?.[props.filterBy]} curves={curves} onSelectCurves={selectCurve} {...props} />)
    }

}