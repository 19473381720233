import React, { useState, useEffect } from 'react';
import ToggleButton from 'react-toggle-button';
import store from 'store';

const EditLithologyButton = () => {
    const initialValue = store.get('openEditLithology');
    const [isActivated, setActive] = useState(false);

    const toggleActive = () => {
        const currentValue = !isActivated;
        setActive(currentValue);
        store.set('openEditLithology', currentValue);
    };

    return (
        <div style={{ display: 'flex', color: '#fff' }}>
            <span style={{ marginRight: 5, marginLeft: 5 }}>Edit Lithology</span>
            <ToggleButton
                colors={{
                    activeThumb: {
                        base: 'rgb(255,255,255)'
                    },
                    inactiveThumb: {
                        base: 'rgb(255,255,255)'
                    },
                    active: {
                        base: '#65c970',
                        hover: '#65c970'
                    },
                    inactive: {
                        base: 'rgb(65,66,68)',
                        hover: 'rgb(95,96,98)'
                    }
                }}
                value={isActivated || initialValue}
                onToggle={toggleActive}
            />
        </div>
    );
};

export default EditLithologyButton;
