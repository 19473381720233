import React, { useState } from 'react';
import { fetchModel } from 'api';
import { Modal, Row, Col, Menu, Icon, Divider } from 'antd';
import './style.scss';
import CustomizeCharts from './CustomizeCharts.js';

const GeneralSettings = ({ isOpen, handleCancel }) => {
    const [option, setOption] = useState('customize-charts');

    const handleClick = e => setOption(e.key);
    
    return (
        <Modal
            visible={isOpen}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
            onCancel={handleCancel}
            className="settings-modal"
            maskClosable={true}
        >
            {<div className='settings-container'>
                <div className='general-settings'>
                    <div style={{ padding: '24px' }}>
                    <h2>General Settings</h2>
                    <Menu
                        onClick={handleClick}
                    >
                        <Menu.Item key='customize-charts'>
                            <Icon type="bar-chart" />
                            Charts Visualization
                        </Menu.Item>
                        {/*<Menu.Item key='test'>Test</Menu.Item>*/}
                    </Menu>
                    </div>
                </div>
                <div style={{padding: '24px', height: '100%' }}>
                    {option === 'customize-charts' && <CustomizeCharts />}
                    {/*{option === 'test' && <h3>Test</h3>}*/}
                </div>
            </div>}
        </Modal>
    )
}

export default GeneralSettings;