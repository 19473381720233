import React, {
  memo,
  useEffect,
  useState
} from 'react';

import { useSelector } from 'react-redux';
import store from 'store';
import {
  nFormatter,
  toggleMLPorosity
} from 'utils';
import { DEFAULT_SCALES } from 'utils/constants';

const updatedHeader = {
  scales: store.get('configScales') || { ...DEFAULT_SCALES },
  mlPorosity: 0,
};

const initialHeader = {
  mlPorosity: 0,
};

const ML_POROSITY = 'mlporosity';

const ChartHeader = () => {
  const wells = useSelector(state => state.wells);

  // --- Intital State
  const [headerState, setHeaderState] = useState({
    ...initialHeader,
    scales: store.get('configScales') || { ...DEFAULT_SCALES }
  });

  // --- Toggle Tracks
  const [tracks, setToggle] = useState({
    isOpen: false,
    aiGrPrediction: true,
  });

  const tooggleCallbacks = {
    mlPorosity: toggleMLPorosity,
  };

  const toggleTrackCurrentTrack = event => {
    const {
      dataset: { name, index }
    } = event.target;
    const trackName = tracks[name];
    const callback = tooggleCallbacks[name];
    if (callback) {
      callback(parseInt(index))(event);
      setToggle({
        ...tracks,
        isOpen: !tracks.isOpen,
        [name]: !trackName
      });
    }
  };

  const dispatchModal = typeOfTitle => event => {
    event.persist();
    event.stopPropagation();
    if (event.target && event.target.dataset.cmd === 'toggle') {
      toggleTrackCurrentTrack(event);
      return;
    } else if (event.target && event.target.dataset.cmd === 'openConfig') {
      const openModal = new CustomEvent('openConfigModal', {
        detail: {
          type: typeOfTitle
        }
      });
      document.dispatchEvent(openModal);
      return;
    }
  };

  useEffect(() => {
    if (wells.currentWell && wells.selectedDepthIndex >= 0) {
      const index = wells.selectedDepthIndex || 0;

      const mlPorosity = wells.currentWell
        && wells.currentWell.mlPorosity  
        ? wells.currentWell.mlPorosity[index] : 0;

      setHeaderState({
        ...headerState,
        mlPorosity: mlPorosity ? mlPorosity.toFixed(2) : mlPorosity,
      });
    }
  }, [wells]);

  useEffect(() => {
    // Scales listener
    const mlPorosityConfigListener = ({ detail }) => {
      updatedHeader.scales.mlPorosity = detail;
      headerState.scales.mlPorosity = detail;

      setHeaderState({
        ...headerState,
        ...updatedHeader
      });
    };
    document.addEventListener(ML_POROSITY, mlPorosityConfigListener);

    // ---- Remove listeners
    return () => {
      document.removeEventListener(ML_POROSITY, mlPorosityConfigListener);
    };
  }, []);

  const { mlPorosity, scales } = headerState;

  const defaultColor = scales && scales.mlPorosity && scales.mlPorosity.color ? scales.mlPorosity.color : '#272BFF';
  return (
    <div
      style={{
        display: 'flex',
        height: '132px',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        position: 'relative'
      }}
    >
      <div
        className="total-gas-header"
        data-name="title"
        data-cmd="openConfig"
        style={{
          height: 40,
          justifyContent: 'flex-end',
          color: defaultColor
        }}
        onClick={dispatchModal(ML_POROSITY)}
        data-cy="open-config-total-carbon"
      >
        <div
          className="total-gas-header__title"
          data-name="mlPorosity"
          data-cmd="openConfig"
          style={{
            color: defaultColor
          }}
        >
          {mlPorosity ? `ML Porosity ${mlPorosity}` : 'ML Porosity'}
        </div>
        <label className="total-gas-header__checkbox container-checkbox">
          <input
            data-cmd="toggle"
            type="checkbox"
            checked={tracks.mlPorosity}
            data-name="aiGr"
            data-index="1"
            data-cy="checkbox-total-carbon"
          />
          <span
            className="checkmark"
            style={{ backgroundColor: defaultColor }}
          />
        </label>
        <div
          className="total-gas-header__values"
          style={{
            borderColor: defaultColor
          }}
        >
          <span data-cy="scales-total-carbon-min">
            {nFormatter(scales && scales.mlPorosity && scales.mlPorosity.min ? scales.mlPorosity.min : 0)}
          </span>
          <span data-cy="scales-total-carbon-max">
            {nFormatter(scales && scales.mlPorosity && scales.mlPorosity.max ? scales.mlPorosity.max : 100)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default memo(ChartHeader);
