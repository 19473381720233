import React, { Component } from 'react';

import ChartContainer from 'components/Shared/ChartContainer';
import Highcharts from 'highcharts';
import {
  maxCurveLithology,
  realTimeLithoDataFormatter,
  syncronizeTooltip,
  handleSelectArea,
  displayZoomBtn,
  propagateZoom
} from 'utils';
import store from 'store';

import ChartHeader from './ChartHeader';
import {
  createDefaultConfig,
  defaultSeries,
  generateSerie
} from './config';

class matchedLithologyData extends Component {
  constructor(props) {
    super(props);
    this.chart = null;

    this.state = {
      data: [],
      matchedLithologyData: {},
    };
  }

  componentDidMount() {
    this.createRealTimeLithoChart([], []);
  }

  shouldComponentUpdate(nextProps) {
    const { selectedWell, currentWell } = nextProps;
    if (
      currentWell && currentWell?.index_curve?.data && currentWell.index_curve?.data?.length &&
      selectedWell !== this.props.selectedWell ||
      nextProps.matchedLithologyData !== this.props.matchedLithologyData ||
      nextProps.lithoAutomudData !== this.props.lithoAutomudData
    ) {
      this.setState({ matchedLithologyData: currentWell?.lithology?.lithology?.data ?? {} });

      const currentDepth = this.props.currentWell?.index_curve?.data || [];
      const data = currentWell.interpretedLithology ? currentWell.interpretedLithology : (currentWell?.lithology?.lithology?.data || {});

      const series = Object.keys(data).map((key, index) => (
        generateSerie(key, data[key], index + 1)));

      const { depth } = this.props;

      this.chart.destroy();
      this.createRealTimeLithoChart(data, series);
      this.zoomId = setTimeout(() => {
        this.chart.xAxis[0].setExtremes(depth.length - 200, depth.length);
      }, 200);
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    this.zoomId && clearTimeout(this.zoomId);
  }

  selectAreas = event => {
    event.preventDefault();
    const axis = event.xAxis[0];

    const afterSelect = () => {
      const minValue = Math.floor(axis.min.toFixed());
      const maxValue = Math.floor(axis.max.toFixed());
      let minLabel = minValue;
      let maxLabel = maxValue;
      if (currentWell) {
        const depthData = currentWell.index_curve?.data;
        minLabel = depthData[minValue];
        maxLabel = depthData[maxValue];
        this.props.openEditLithologyModal(minValue, maxValue, 'interpreted');
      }
    };

    const { customEventType, currentWell } = this.props;
    // When option to edit lithology is selected
    // we need to select only the area get the depth and show editLithologyModal

    if (store.get('openEditLithology')) {
      handleSelectArea(this.chart, afterSelect, this.state.selectedId)(event);

    } else {
      displayZoomBtn();
      propagateZoom(event);
    }
  };

  createRealTimeLithoChart = (data, series) => {
    const { id } = this.props;

    let defaultPlaceholderData = [];

    if (Object.keys(data).length) {
      defaultPlaceholderData = maxCurveLithology(data);
    }

    // This map will generate each serie that we are passing data via props
    // NOTE: This will generate data just relyng from the serie that we are receiveing
    // const series = Object.keys(data).map(
    //   (key, index) => generateSerie(key, data[key], index + 1)
    // );
    series.push(defaultSeries(defaultPlaceholderData));
    const config = createDefaultConfig(this.props, series);
    this.chart = Highcharts.chart(id, {
      ...config,
      chart: {
        ...config.chart,
        events: {
          selection: this.selectAreas
        },
      }
    });

    document.addEventListener('sincronize-selected', event => {
      const {
        detail: { min, max }
      } = event;
      const { depth } = this.props;
      let minValue = depth[min];
      let maxValue = depth[max];

      this.chart.xAxis[0].setExtremes(minValue, maxValue, true, true);
      this.chart.redraw();
    });

    syncronizeTooltip(`#${this.props.id}`);
  }

  render() {
    const { id, currentWell, title, depth } = this.props;
    return (
      <ChartContainer>
        <ChartHeader lithoData={currentWell.matchedLithologyData || []} title={title} depth={depth} />
        <div
          id={id}
          style={{
            marginLeft: '10px',
            height: 'calc(100% - 128px)'
          }}
          data-cy="chart-interpreted-lithology"
        />
      </ChartContainer>
    );
  }
}

matchedLithologyData.defaultProps = {
  width: 200,
  type: 'area',
  bgColor: '#fff',
  inverted: true,
  animation: false,
  customProps: {},
  id: 'interpreted-lithology',
  title: <span>Interpreted Lithology</span>
};

export default matchedLithologyData;
