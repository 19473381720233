import React, { useEffect, useState, createContext, useContext } from 'react';
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import store from 'store';
import get from 'lodash.get';
import { object } from 'yup';

const initialState = {
    gamma_ray: {},
    lithology: {},
    lithology_prob: {}
};

export const MachineLearningContext = createContext(initialState);

export const MachineLearningProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [mlCurves, setMlCurves] = useState(initialState);
    const wells = useSelector(state => state.wells) || {};
    const currentWell = get(wells, 'currentWell', null);

    const dispatch = useDispatch();

    const accessToken = store.get('token');

    useEffect(() => {
        if (currentWell && currentWell.ml) {
            Object.keys(mlCurves).forEach(curve => {
                if (currentWell?.ml?.[curve]) {
                    if (Array.isArray(currentWell.ml[curve])) {
                        setMlCurves(prevState => ({ ...prevState, [curve]: currentWell.ml[curve][0] }));
                    } else {
                        setMlCurves(prevState => ({ ...prevState, [curve]: currentWell.ml[curve] }));
                    }
                }
            })
        }
    }, [currentWell]);


    return (
        <MachineLearningContext.Provider value={{
            isLoading,
            setIsLoading,
            mlCurves,
            setMlCurves
        }}>
            {children}
        </MachineLearningContext.Provider>
    );
};

const useMachineLearningContext = () => {
    const context = useContext(MachineLearningContext);

    if (context === undefined) {
        throw new Error('useGeoCopilot must be used within a MachineLearningProvider')
    }
    return context
};

export default useMachineLearningContext;
