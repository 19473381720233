import React, { useContext, useEffect, useState } from "react";
import Fieldset from "../../Hydraulics/Fieldset";
import List from "../../Hydraulics/List";
import InputNumber from "../../Hydraulics/InputNumber";
import { realTimeLithoDataFormatter } from "utils";
import { rocks } from 'utils/constants';
import { getCuttingsLithology } from 'utils/lithology';

import { Button, Icon, Switch, Tooltip } from "antd";

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import {
    InitiaLithologyEditingParameters,
    EditParametersSchema,
    lithologyTypes,
    clearAndReplaceData
} from "./schema";
import { useDispatch } from "react-redux";
import { receiveInterpretedLithology, receiveRealtimeLithology } from "../../../actions/wells";

const rockImages = require.context('../../../images/patterns', true, /\.png$/);
const rockImagesPatterns = rockImages.keys()
    .reduce((images, path) => {
        images[path.replace(".png", '').replace(/[./]/g, '')] = rockImages(path)
        return images
    }, {})

function EditLithologyForm({ onCancel, currentWell, ...props }) {
    const [currentLithology, setCurrentLithology] = useState({})
    const dispatch = useDispatch();

    const methods = useForm({
        defaultValues: {
            ...InitiaLithologyEditingParameters,
            top: currentWell.index_curve?.data[props.minDepth],
            bottom: currentWell.index_curve?.data[props.maxDepth],
        },
        resolver: yupResolver(EditParametersSchema),
    });

    const { handleSubmit, setValue, getValues, control, formState: { errors, isSubmitSuccessful } } = methods;

    useEffect(() => {
        if (props?.lithologyType == lithologyTypes?.[0] && currentWell?.lithology) {
            const savedLithology = currentWell?.interpretedLithology ? currentWell?.interpretedLithology : currentWell.lithology.lithology.data
            setCurrentLithology(savedLithology)
        }
        if (props?.lithologyType == lithologyTypes?.[1] && currentWell?.ml) {
            const savedLithology = currentWell?.ml?.realtimeLithology ? currentWell?.ml?.realtimeLithology : currentWell.ml?.[props.filterProp].data
            setCurrentLithology(savedLithology)
        }
    }, [currentWell, props.lithologyType])

    function updateParameters(data) {
        handleEditedData(data)
        onCancel()
    }

    function handleEditedData(data) {

        try {
            if (currentWell) {
                const totalLength = currentWell.RAW.depth.length;
                if (props.lithologyType == lithologyTypes[0]) {
                    const newLithology = clearAndReplaceData(currentLithology, data, totalLength, props.minDepth, props.maxDepth)
                    dispatch(receiveInterpretedLithology(newLithology))
                }
                if (props.lithologyType == lithologyTypes[1]) {
                    const newLithology = clearAndReplaceData(currentLithology, data, totalLength, props.minDepth, props.maxDepth)
                    dispatch(receiveRealtimeLithology(newLithology))
                }

            }
        } catch (e) {
            console.error(e)
        }
    }


    return (

        <>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(updateParameters)}>
                    <Fieldset>
                        <div style={{ display: 'flex', width: '100%', gap: '8px' }}>
                            <InputNumber
                                label={'TOP'}
                                name={'top'}
                                unit={'m'}
                                style={{ width: '100%' }}
                                control={control}
                                errors={errors.top}
                            />
                            <InputNumber
                                label={'BOTTOM'}
                                name={'bottom'}
                                unit={'m'}
                                style={{ width: '100%' }}
                                control={control}
                                errors={errors.bottom}
                            />
                        </div>
                    </Fieldset>
                    <Fieldset>
                        <div style={{ display: 'flex', alignItems: 'flex-end', width: '100%', gap: '8px' }}>
                            <List
                                label={'Select Lithology'}
                                name={'selected_lithology'}
                                control={control}
                                options={rocks}
                                errors={errors.selected_lithology} >
                                {rocks && rocks.map((value) =>
                                    <Option key={value} value={value}>
                                        <span role="img" aria-label={rockImagesPatterns[value]}>
                                            <img src={rockImagesPatterns[value]}
                                                key={value}
                                                alt={value}
                                                style={{ width: '20px', height: '20px', margin: '4px' }}
                                            />
                                        </span>
                                        {value.replaceAll('_', " ").toUpperCase()}
                                    </Option>)}
                            </List>
                        </div>
                    </Fieldset>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                        <Button
                            type="link"
                            htmlType={'button'}
                            onClick={onCancel}
                            style={{ color: 'red' }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            htmlType={'submit'}
                        >Save</Button>
                    </div>
                </form>
            </FormProvider>
        </>
    )
}
export default EditLithologyForm;