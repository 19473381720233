import React, {
    useEffect,
    useState,
    useRef,
    createContext,
    useContext
  } from 'react';
  import {
    // useDispatch,
    useSelector
  } from 'react-redux';
  
  const initialState = {};
  
  import get from 'lodash.get';
  
  export const ModelsContext = createContext(initialState);
  
  export const ModelsProvider = ({ children, modelName }) => {
    const wells = useSelector(state => state.wells) || {};
    const currentWell = get(wells, 'currentWell', null);
    const models = useSelector(state => state.models) || {};
    const [selectedModel, setSelectedModel] = useState(null);
    const [curveMapping, setCurveMapping] = useState({});
    const [curvesNames, setCurvesNames] = useState([]);

    const outputCurveKey = Object.keys(models?.items[modelName]?.schema_definition?.output_curve || {})[0];
    const outputCurve = models?.items[modelName]?.schema_definition?.output_curve?.[outputCurveKey]?.mnemonic;
  
    const isValidCurve = (curveValues = [], depthLength = 0) => {
      const isValid = curveValues.every(
        value => value !== null && typeof value === 'number'
      );
  
      return (
        Array.isArray(curveValues) && isValid && curveValues.length >= depthLength
      );
    };
  
    const fillNullValues = (curveValues = []) => {
      return curveValues.map(value => (value === null ? 0 : value));
    };
  
    const initialCurveMapping = inputSchema => {
      if (!inputSchema || !currentWell) {
        return {};
      }
      return inputSchema.reduce((acc, curveName) => {
        const normalizeCurveName = curveName.replaceAll('LOG #1: ', '');
        const curveValues = currentWell?.RAW?.[normalizeCurveName]?.data;
        console.log('curveValues', curveValues);
        if (isValidCurve(curveValues, currentWell?.RAW?.depth?.data?.length)) {
          acc[normalizeCurveName] = normalizeCurveName;
        } else {
          acc[normalizeCurveName] = null;
        }
        return acc;
      }, {});
    };
  
    useEffect(() => {
      //  if curveMapping is not empty, then we can set the initial curve mapping
      if (
        models &&
        currentWell &&
        modelName &&
        models.items[modelName] &&
        models.items[modelName].input_schema
      ) {
        setCurveMapping(
          initialCurveMapping(models.items[modelName].input_schema)
        );
      }
    }, [models, currentWell, modelName]);
  
    useEffect(() => {
      if (currentWell) {
        const depthKey = 'depth';
        // find object which are arrays and not null
        const curves = Object.keys(currentWell.RAW)?.reduce((acc, curveKey) => {
          if (
            Array.isArray(currentWell.RAW?.[curveKey].data) &&
            currentWell.RAW?.[curveKey].data.length
          ) {
            if (
              isValidCurve(
                currentWell.RAW?.[curveKey].data,
                currentWell.index_curve?.data.length
              )
            ) {
              acc.push(curveKey);
            }
          }
          return acc;
        }, []);
  
        // this is hardcoded for now
        const curvesToExclude = [
          'ic4Normal',
          'ic5Normal',
          'c1Byc2',
          'ic4BynC4',
          'ic5BynC5',
          'wetnessRatioData',
          'balanceRatioData',
          'characterRatioData',
          'c1Composition',
          'c2Composition',
          'c3Composition',
          'nc4Composition',
          'nc5Composition',
          'slowFactor',
          'gammaRay',
          'totalCarbonOnly',
          'totalGas',
          'well_name'
        ];
        // if the curve is not in the curvesToExclude array, then we can add it to the curvesNames
        const curvesToInclude = curves.filter(
          curve => !curvesToExclude.includes(curve)
        );
        setCurvesNames(curvesToInclude);
      }
    }, [currentWell]);
  
    return (
      <ModelsContext.Provider
        value={{
          curveMapping,
          setCurveMapping,
          initialCurveMapping,
          selectedModel,
          setSelectedModel,
          curvesNames,
          setCurvesNames,
          isValidCurve,
          outputCurve
        }}
      >
        {children}
      </ModelsContext.Provider>
    );
  };
  
  export const useModelsContext = () => {
    const context = useContext(ModelsContext);
    if (context === undefined) {
      throw new Error('useModels must be used within a ModelsProvider');
    }
    return context;
  };
  
  export default useModelsContext;
  