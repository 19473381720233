import React, { useState, useEffect } from 'react';

import {
    Button,
} from 'antd';
import classes from 'classnames';
import Modal from 'react-modal';

import removeIcon from '../../assets/close.svg';
import { modalStyleEditLithology } from '../../utils/index';
import EditLithologyForm from './EditLithologyForm';

const Title = ({ title, className, classTitle }) => (
    <h3 className={classes(
        'title-row',
        {
            [className]: !!className,
            [classTitle]: !!classTitle
        }
    )}
    >
        {title}
    </h3>
);

Title.defaultProps = {
    classTitle: 'modal-scale__title'
};

const EditLithologyModal = ({
    isOpen, onCloseModal, lithologyType, currentWell, loading,
    minDepth, maxDepth, filterProp
}) => {
    const [selectedWell, setSelectedWell] = useState(undefined);

    const onSelectWell = (selectedRows) => {
        setSelectedWell(selectedRows);
    };

    useEffect(() => {
        return () => {
            onSelectWell(undefined);
        };
    }, []);

    return (
        <>
            <Modal
                isOpen={isOpen}
                style={{
                    ...modalStyleEditLithology,
                    overlay: {
                        zIndex: 600,
                        backgroundColor: 'rgba(0, 0, 0, 0.65)'
                    }
                }}
                onRequestClose={() => {
                    setSelectedWell(undefined);
                    onCloseModal();
                }}
            >
                <div style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
                    <img
                        onClick={onCloseModal}
                        src={removeIcon}
                        className="modal-scale__close"
                        data-cy="close-modal"
                        alt="close modal"
                    />
                </div>
                <div style={{ padding: 20 }}>
                    <EditLithologyForm minDepth={minDepth}
                        maxDepth={maxDepth}
                        onCancel={onCloseModal}
                        currentWell={currentWell}
                        lithologyType={lithologyType}
                        filterProp={filterProp}
                    />
                </div>
            </Modal>
        </>
    );
};

export default EditLithologyModal;
