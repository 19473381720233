import React from "react";
import { Wrapper, StyledSelect, StyledLabel } from "./styles";
import { Popover } from "antd";
import { useController } from "react-hook-form"
import "./list.scss"

const List = ({ id, label, control, defaultValue, name, options, errors, children, ...props }) => {
    const { field } = useController({ name, control })
    return (
        <>
          
          {errors ?
            <Popover
              content={<span style={{ color: '#fff', margin: '2px' }}>{errors.message.message || errors.message}</span>}
              style={{ width: 200, background: 'red' }}
            >
              <Wrapper>
                <StyledLabel htmlFor={id}>{label}</StyledLabel>
                <StyledSelect defaultValue={defaultValue} value={field.value} onChange={field.onChange} onBlur={field.onBlur} aria-invalid={errors ? "true" : "false"}  {...props} >
                  {children}
                </StyledSelect>
              </Wrapper>
            </Popover> :
            <>
              <Wrapper>
                <StyledLabel htmlFor={id}>{label}</StyledLabel>
                <StyledSelect defaultValue={defaultValue} value={field.value} onChange={field.onChange} onBlur={field.onBlur} aria-invalid={errors ? "true" : "false"}  {...props} >
                  {children}
                </StyledSelect>
              </Wrapper>
          </>
        }
      </>
    )
}
export default List;
